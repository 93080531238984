var host = "127.0.0.1";

function openPopUpFingerPrint(nuDocUsuario, coTipoTrx, coCanalBf, tiDocCliente, nuDocCliente, idTxnBf) {    
    var puerto = 2225;
    var url = "https://" + host + ":" + puerto + "/biomatchwsi/autenticar";
    // var url = "http://" + host + ":" + puerto + "/biomatchwsi/autenticar";


    console.log(puerto);
    console.log(url);

    // Definir los datos a enviar
    var data = {
        nuDocUsuario: nuDocUsuario,
        coTipoTrx: coTipoTrx,
        coCanalBf: coCanalBf,
        tiDocCliente: tiDocCliente,
        nuDocCliente: nuDocCliente,
        idTxnBf: idTxnBf
    };
    console.log(data);

    var result = testAjax(url, data);

    return result;
}

var testAjax = function(url, data) {
    var result = '';
    console.log("usando ajax",url);
    console.log("data",data);
    
    var queryString = '';
    console.log("queryString",queryString);
    if (data && typeof data === 'object' && !Array.isArray(data)) {
        queryString = new URLSearchParams(data).toString();
        console.log("queryString:", queryString);
    }
    console.log("queryString2",queryString);

    $.ajax({
        url: url + '?' + queryString,
        async: false,
        type: "GET",
        dataType: "json",
        success: function(data) {
            result = vcSuccess(data);
        },
        error: function(xhr, status, error) {
            console.error('Error en la solicitud AJAX:', error);
        }
    });

    return result;
};

function vcSuccess(response) {
    return response;
}
