import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { CustomerAccountService } from 'app/services/customer-account.service';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { forkJoin, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-desembolso-modal-bandeja',
  templateUrl: './datos-desembolso-modal-bandeja.component.html',
  styleUrls: ['./datos-desembolso-modal-bandeja.component.scss']
})
export class DatosDesembolsoModalBandejaComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';

  loading: boolean = false;
  currentAccounts: any = [];
  elementsReadOnly: any = [];
  account: string = '';
  listBanks: any = [];
  typeBank: string = '';
  accountNumberInterbank: string = '';
  isErrorAccount: boolean = false;
  isErrorTypeBank: boolean = false;
  isErrorAccountNumberInterbank: boolean = false;
  disbursementCode: any = null;
  subModeDisbursement: string = '';
  customerCode: number = 0;

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  application: any = {};

  constructor(
    private router: Router,
    private customerAccountService: CustomerAccountService,
    private alertService: AlertService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private customerService: CustomerService) 
  {
    
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.BANKS_TRANSFERS
    ]);

    await this.getApplication(this.applicationId);
    this.orquestationCurrentAccounts();
  }

  mostrarAbonoCuenta: boolean = false;
  mostrarAbonoCuentaInterbancaria: boolean = false;
  botonActivo: string = '';
  tipoCuentaSeleccionado: string = '';
  max: number = 20;

  close() {
    this.onClose.emit(); 
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  mostrarAbonoEnCuenta() {
    this.tipoCuentaSeleccionado = '';
    this.typeBank = '';
    this.mostrarAbonoCuenta = true;
    this.mostrarAbonoCuentaInterbancaria = false;
    this.botonActivo = 'abono'; // Actualiza el estado del botón activo
    this.isErrorTypeBank = false;
    this.clearValuesDisbursementMode();
    this.disbursementCode = null;
  }

  mostrarAbonoEnCuentaInterbancaria() {
    this.tipoCuentaSeleccionado = '';
    this.mostrarAbonoCuenta = false;
    this.mostrarAbonoCuentaInterbancaria = true;
    this.botonActivo = 'interbancario'; // Actualiza el estado del botón activo
    this.isErrorTypeBank = true;
    this.isErrorAccountNumberInterbank = true;
    this.isErrorAccount = false;
    this.clearValuesDisbursementMode();
    this.disbursementCode = AppConstants.DisbursementCode.ImmediateInterbankTransfer;
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async retriveCurrentAccounts(model: any) {
    this.loading = true;
    await lastValueFrom(this.customerAccountService.postRetrieveCurrentAccounts(model)).then((response: any) => {
      if(response.accounts != null) {
        this.currentAccounts = response.accounts.map((element: any) => ({ label: `${element.accountNumber} ${element.descriptionProduct}`, value: element.accountNumber }));
      }      
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        this.alertService.showMessage("", AppConstants.MessageAlert.CUSTOMER_ACCOUNTS_ERROR_RETRIEVE, MessageSeverity.error);
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    this.listBanks = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.BANKS_TRANSFERS && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: `${element.parameterDetCode001} - ${element.parameterDetCode002}`, value: element.parameterDetCode001, bankName: element.parameterDetCode002 }));
  }

  validateDisbursementMode() {   
    if(this.botonActivo === '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_TYPE_ERROR_REQUIRED, MessageSeverity.error);
      return;
    } else {
      if(this.botonActivo === 'abono') { 
        if(this.tipoCuentaSeleccionado === '') {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_TYPE_ERROR_REQUIRED, MessageSeverity.error);
          return;
        }
        else {
          if(this.tipoCuentaSeleccionado === 'existente') {
            // this.isErrorAccount = true;
            if(this.account === '') {
              this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_CURRENT_ACOUNT_REQUIRED, MessageSeverity.error);
              return;
            }          
          } else {
            this.account = '';
          }
        }
  
      } else if(this.botonActivo = 'interbancario') {
        // this.isErrorTypeBank = true;
        if(this.typeBank === '') {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_REQUIRED, MessageSeverity.error);
          return;
        }
        if(this.accountNumberInterbank.length < this.max) {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_CCI_REQUIRED, MessageSeverity.error);
          return;
        }
        if(this.accountNumberInterbank.substring(0, 3) != this.typeBank) {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_CCI_ERROR, MessageSeverity.error);
          return;
        }
      }
    }

    let model = {
      applicationId: this.applicationId,
      type: this.disbursementCode,
      currentAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ExistingAccount ? this.account : null,
      interbankAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.accountNumberInterbank : null,
      destinationBankName: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.listBanks.find((f: any) => f.value === this.typeBank).bankName : null
    };

    this.addDisbursementMode(model);
  }

  clearValuesDisbursementMode() {
    this.tipoCuentaSeleccionado == '';
    this.typeBank == '';
    this.account = '';
    this.accountNumberInterbank = '';
    this.subModeDisbursement = '';
  }

  changeDisbusemenmtMode(type: string) {
    this.tipoCuentaSeleccionado = type;

    if(this.tipoCuentaSeleccionado === 'existente') {
      this.disbursementCode = AppConstants.DisbursementCode.ExistingAccount;
      this.isErrorAccount = true;
      this.isErrorTypeBank = false;
    } else {
      this.disbursementCode = AppConstants.DisbursementCode.NewAccount;
      this.isErrorAccount = false;
      this.isErrorTypeBank = false;
    }
  }

  resetErrorState(inputType: string) {
    if (inputType === 'accountNumberInterbank') {
      this.isErrorAccountNumberInterbank = this.accountNumberInterbank.length === 0;  
    }
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    if(model.disbursementType != null) {

      switch(model.disbursementType) {
        case AppConstants.DisbursementCode.WithoutDisbursement:
          break;
        case AppConstants.DisbursementCode.NewAccount:
          this.mostrarAbonoEnCuenta();
          this.changeDisbusemenmtMode('nueva');
          this.subModeDisbursement = 'nueva';
          break;
        case AppConstants.DisbursementCode.ExistingAccount:
          this.mostrarAbonoEnCuenta();
          this.changeDisbusemenmtMode('existente');
          this.subModeDisbursement = 'existente';
          this.account = (model.currentAccountNumber != null && model.currentAccountNumber != '') ? model.currentAccountNumber : '';
          this.isErrorAccount = (model.currentAccountNumber != null && model.currentAccountNumber != '') ? false : true;
          break;
        case AppConstants.DisbursementCode.ImmediateInterbankTransfer:
          this.mostrarAbonoEnCuentaInterbancaria();
          this.accountNumberInterbank = (model.interbankAccountNumber != null && model.interbankAccountNumber != '') ? model.interbankAccountNumber : '';
          
          if(model.interbankAccountNumber != null && model.interbankAccountNumber != '' && model.interbankAccountNumber.length >= 3) {
            this.typeBank = model.interbankAccountNumber.substring(0,3);
            this.isErrorAccountNumberInterbank = false;
            this.isErrorTypeBank = false;
          }
          break;
        default:
      }
    }
  }

  addDisbursementMode(model: any) {
    this.loading = true;
    this.applicationService.postRegisterDisbursementMode(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.DISBURSEMENT_MODE_TITLE, AppConstants.MessageAlert.DISBURSEMENT_MODE_SUCCESS, MessageSeverity.success);
        this.close();
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.DISBURSEMENT_MODE_TITLE, AppConstants.MessageAlert.DISBURSEMENT_MODE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  save()
  {
    let model = {
      applicationId: this.applicationId,
      type: this.disbursementCode,
      currentAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ExistingAccount ? this.account : null,
      interbankAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.accountNumberInterbank : null,
      destinationBankName: this.typeBank === '' ? null : this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.listBanks.find((f: any) => f.value === this.typeBank).bankName : null
    };

    this.validateDisbursementMode();
  }

  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }

  orquestationCurrentAccounts() {
    this.loading = true;
    const modelCustomer = {
      documentType: this.application.documentType,
      documentNumber: this.application.documentNumber
    };

    const sources: any = [];
    sources.push(this.customerService.postRetrieveId(modelCustomer));
    forkJoin(sources)
    .subscribe({
      next: (response: any) => {
        for(let index = 0; index < response.length; index++) {
          const element = response[index];
          this.customerCode = element.ibsCode;
        }
        this.loading = false;
      },
      error: (e) => {
        console.error(e)
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CUSTOMER_RETRIEVE_TITLE, e.error.message, MessageSeverity.error);
      },
      complete: async () => {
        this.loading = false;
        if(this.customerCode != 0) {
          let model = {
            ibsCode: this.customerCode,
            producType: "AHOR",
            ShowBalance: false,
            currency: "SOL"
          };
          await this.retriveCurrentAccounts(model);
        }
      }
    });    
  }

}
