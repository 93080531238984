<app-header [headerText]="'Final'" headerImageSrc="assets/check.svg"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> {{ applicationId }}</span>
  </div>
</div>


<div class="contenedor-informacion">
  <div class="container-imagen">
    <img src="assets/img_finalizacion.svg" alt="Descripción de la imagen">
  </div>

  <div class="informacion-producto">
    <p class="titulo-finalizacion">{{finalMessage}}</p>
    <p class="texto-finalizacion">
      CLIENTE: <span class="opacidad">{{application?.name}} {{application?.middleName}} {{application?.lastname}} {{application?.secondLastname}}</span>
    </p>
    <p class="texto-finalizacion">
      DNI: <span class="opacidad">{{application?.documentNumber}}</span>
    </p>

    <p class="texto-finalizacion">Los documentos contractuales han sido enviados al correo del cliente.</p>

    <ul class="informacion-detalle">
      <li><strong>Producto:  </strong>{{applicationSavingAccount?.subProductCode}} - {{applicationSavingAccount?.productDescription}}</li>
      <li><strong>Número de cuenta:  </strong> <a>{{applicationSavingAccount?.savingAccountNumber}}</a></li>
      <li><strong>Cod. de Cuenta Interbancaria (CCI):  </strong> <a>{{applicationSavingAccount?.savingAccountNumberCci}}</a></li>
      <li><strong>Moneda:  </strong>{{applicationSavingAccount?.currencyCode}} - {{applicationSavingAccount?.currencyDescription}}</li>
      <li><strong>Fecha de apertura:  </strong>{{applicationSavingAccount?.createdDateTime}}</li>
    </ul>

    <app-custom-button text="Regresar" (action)="goHome()"></app-custom-button>
  </div>
</div>

<app-footer></app-footer>

<loading *ngIf="loading"></loading>