
<app-header [headerText]="textoCabecera"  [showImage]="false"></app-header>

<div class="layout-container">
  <div  class="formulario-container">
      <div class="form-row">
        <div class="form-group">
          <label>Detalle 1</label>
          <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
        </div>

        <div class="form-group">
          <label>Detalle 2</label>
          <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
        </div>
        
        <div class="form-group">
          <label>Detalle 3</label>
          <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
        </div>

        <div class="form-group">
          <label>Detalle 4</label>
          <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
        </div>

        <div class="form-group">
          <label>Detalle 5</label>
          <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
        </div>
      </div>
</div>  

<div  class="formulario-container">
  <div class="form-row">
    <div class="form-group">
      <label>Detalle 6</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>

    <div class="form-group">
      <label>Detalle 7</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>
    
    <div class="form-group">
      <label>Detalle 8</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>

    <div class="form-group">
      <label>Detalle 9</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>

    <div class="form-group">
      <label>Detalle 10</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>
  </div>
</div>  

<div  class="formulario-container">
  <div class="form-row">
    <div class="form-group">
      <label>Detalle1</label>
      <app-custom-input-flex   placeholderText="" [width]="'200px'"></app-custom-input-flex>
    </div>

  </div>
</div>  
<div class="add-detalle">
    <app-custom-button iconUrl="assets/img/add_empresa.svg"  
    text="AGREGAR DETALLE"  (action)="addDetails()" ></app-custom-button>
</div>

<div class="contenedor-informacion">
  <div class="informacion-producto">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="tusDatos" actionButtonText="EDITAR" ></app-custom-table-paginator>
  </div>
</div>

<div class="footer-buttons">
  <a href="#" class="link-atras" >Cancelar</a>
    <app-custom-button
    text="CONFIRMAR"  ></app-custom-button>
</div>

</div>
<app-footer></app-footer>



