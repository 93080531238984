import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BandejaSolicitudesProductoComponent } from './bandeja-solicitudes-producto.component';
import { BandejaSolicitudesProductoRoutingModule } from './bandeja-solicitudes-producto.routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, ModalResumenComponent, CustomInputPasswordComponent, CustomDatepickerComponent, 
  CustomButtonLinkComponent} from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { DatosGeneralesModalBandejaComponent } from './components/datos-generales-modal-bandeja/datos-generales-modal-bandeja.component';
import { CustomTextAreaComponent } from 'app/components/custom-text-area';
import { DatosDireccionModalBandejaComponent } from './components/datos-direccion-modal-bandeja/datos-direccion-modal-bandeja.component';
import { DatosOtrosModalBandejaComponent } from './components/datos-otros-modal-bandeja/datos-otros-modal-bandeja.component';
import { DatosDesembolsoModalBandejaComponent } from './components/datos-desembolso-modal-bandeja/datos-desembolso-modal-bandeja.component';
import { DatosAdjuntosModalBandejaComponent } from './components/datos-adjuntos-modal-bandeja/datos-adjuntos-modal-bandeja.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CustomButtonBorderComponent } from 'app/components/custom-button-border';
@NgModule({
  declarations: [BandejaSolicitudesProductoComponent,DatosGeneralesModalBandejaComponent,DatosDireccionModalBandejaComponent,
  DatosOtrosModalBandejaComponent,DatosDesembolsoModalBandejaComponent,DatosAdjuntosModalBandejaComponent],
  imports: [
    CommonModule,
    BandejaSolicitudesProductoRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    ModalResumenComponent,
    CustomTextAreaComponent,
    CustomInputPasswordComponent,
    CustomDatepickerComponent,
    CustomButtonLinkComponent,
    SharedModule,
    MatFormFieldModule,MatSelectModule, FormsModule, ReactiveFormsModule,
    CustomButtonBorderComponent
  ]
})
export class BandejaSolicitudesProductoModule { }
