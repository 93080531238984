import { Component, Input, EventEmitter, Output, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {
  @Input() placeholderText: string = '';
  @Input() type: string = 'text';
  @Input() maxlength: number = 100;
  @Input() label: string = '';
  @Input() width: string = '100%';  // Valor por defecto cambiado a 100%
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() borderColor: string = '';
  @Input() validateNumber: boolean = false;
  @Input() validateEmail: boolean = false;
  @Input() maxLength: number | null = null;
  @Input() validatemaxlength: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() minlength: number = 0;

  @ViewChild('myInput') myInput!: ElementRef<HTMLInputElement>;

  // Agregar HostBinding para aplicar el estilo de width directamente en el host element
  @HostBinding('style.width') get styleWidth() {
    return this.width;
  }

  onValueChange(value: string): void {
    if(this.type === 'tel') {
      if(this.validatemaxlength) {
        if(this.value.length === this.maxlength){
          this.isError = false; 
        }
        else {
          this.isError = true; 
        }
      }
      else {
        if (this.minlength !== null && value.length < this.minlength) {
          this.isError = true;
        }
        else {
          this.isError = !(this.value.length > 0);
        }
      }
    }
    else {
      this.isError = false; // Reiniciar el estado de error

      if (this.validateNumber && !/^\d+$/.test(value)) {
        this.isError = true;
      } else if (this.validateEmail) {
        if(this.value.length > 0 && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
          this.isError = true;          
        } else {
          this.isError = false;
        }
        
      } else if (this.maxLength !== null && value.length > this.maxLength) {
        this.isError = true;
      } else if (this.minlength !== null && value.length < this.minlength) {
        this.isError = true;
      }
    }

    this.valueChange.emit(value); // Emitir el valor incluso si hay un error, para permitir correcciones
  }

  focusInput() {
    if (this.myInput && this.myInput.nativeElement) {
      this.myInput.nativeElement.focus();
    }
  }
}
