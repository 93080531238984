<app-header [headerText]="' Visualizando producto'" headerImageSrc="assets/envio.svg"></app-header>
<div class="contenedor-informacion">

  <div class="informacion-producto">
    <div class="container-izquierdo">
      <div class="lado-izquierdo">
        <span class="punto"></span>
        <span class="texto-producto">Producto</span>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="caja-redondeada-sombreada">
      <div class="contenido-caja">
        <div class="contenido-caja-container">
          <div class="seccion-imagen">
            <img src="{{customerApplication.productNamImage}}" alt="Descripción de la imagen">
            <div class="detalle-titulo">
              <span class="titulo-producto"> {{customerApplication.productName}}</span>
            </div>
          </div>
          <div class="container-detalles">
            <div class="seccion-detalles">
              <div class="detalle detalle-productos">
                <p><span class="titulo">Producto:</span> {{applicationSavingAccount?.subProductCode}} - {{applicationSavingAccount?.productDescription}}</p>
                <p><span class="titulo">Número de cuenta:</span> <a> {{applicationSavingAccount?.savingAccountNumber}}</a></p>           
                <p><span class="titulo">Cod. de Cuenta Interbancaria (CCI):</span> <a> {{applicationSavingAccount?.savingAccountNumberCci}}</a></p>
                <p><span class="titulo">Moneda:</span> {{applicationSavingAccount?.currencyCode}} - {{applicationSavingAccount?.currencyDescription}}</p>
                <p><span class="titulo">Fecha de apertura:</span> {{applicationSavingAccount?.createdDateTime}}</p>
              </div>
            </div> 
          </div>

          
        </div>

      </div>
    </div>
    
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos Personales</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Nombre:</span> {{ customer?.name }} {{ customer?.middleName}} {{ customer?.lastname }} {{ customer?.secondLastname}}</p>
          <p><span class="titulo">DNI:</span> {{ customer?.documentNumber }}</p>
        </div>
        <div class="inf-detalle">
            <p><span class="titulo">Celular:</span> {{ customer?.personalCellPhoneNumber }}</p>
            <p><span class="titulo">Correo:</span> {{ customer?.emailAddress }}</p>
        </div>          
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModal(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModal(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos de Dirección</p>
        </div>
        <div class="inf-detalle">
          <p> {{ customer?.customerStreetName }} {{ customer?.customerStreetNumber }}</p>    
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalDireccion(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalDireccion(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Tipo de Cuenta</p>
        </div>
        <div class="inf-detalle">
            <p>{{ applicationSavingAccount?.subProductCode }} - {{applicationSavingAccount?.productDescription}}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalDesembolso(); $event.preventDefault()"></app-custom-button-color>
        </div>          
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalDesembolso(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Archivos Adjuntos</p>
        </div>
        <div *ngFor="let file of filesList" class="inf-detalle">
          <p>{{ file.name }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton" *ngIf="!onlyView">
          <app-custom-button-color text="EDITAR" (click)="openModalAdjuntos(); $event.preventDefault()"></app-custom-button-color>
        </div>
        <div class="detalle-boton" *ngIf="onlyView">
          <img src="assets/ojoColor.svg" class="img-ojo">
          <a href="#" class="link-editar" (click)="openModalAdjuntos(); $event.preventDefault()">VER</a>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-comentario aprobar-solicitud">
    <div class="contenedor-confirmacion-comentario">
        <p class="titulo-confirmacion">
            <span class="icon-comment"></span> Historial de Comentarios
        </p>
        <div class="comments-section">
            <div class="comment" *ngFor="let comment of comments">
                <div class="comment-header">
                    <span class="date">{{ comment?.date }}</span>
                    <span class="user">Por {{ comment?.user }}</span>
                </div>
                <div class="comment-body">
                    <p class="title">{{ comment?.reason }}</p>
                    <p class="status">{{ comment?.message }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="!onlyView" class="datos-solicitud" style="margin-top: 2rem;">
          <app-custom-text-area [(value)]="commentSeller"></app-custom-text-area>
        </div>
        <div *ngIf="!onlyView" class="modal-footer" style="margin-top: 2rem;">
          <app-custom-button text="AGREGAR COMENTARIO" (action)="sendCommentHandler()"></app-custom-button>
        </div> 
    </div>
  </div>


  <div class="informacion-producto aprobar-solicitud" *ngIf="approvingRole && (customerApplication.stateCode === 9 || customerApplication.stateCode === 4)">
    <div class="contenedor-confirmacion">
      <p class="titulo-confirmacion">¿Desea aprobar la solicitud?</p>
      <div class="contenedor-confirmacion-boton">
        <div class="contenedor-aceptado">
          <app-custom-button-color 
            [text]="'Aceptado'" 
            [iconUrl]="acceptedStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="acceptedStyles.backgroundColor"
            [textColor]="acceptedStyles.textColor"
            [isActive]="acceptedStyles.isActive"
            (action)="onAcceptedClicked()">
          </app-custom-button-color>
        
          <app-custom-button-color 
            [text]="'Observado'" 
            [iconUrl]="observedStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="observedStyles.backgroundColor"
            [textColor]="observedStyles.textColor"
            [isActive]="observedStyles.isActive"
            (action)="onObservedClicked()">
          </app-custom-button-color>
        </div>
      
        <div class="contenedor-aceptado">
          <app-custom-button-color 
            [text]="'Rechazado'" 
            [iconUrl]="rechazadoStyles.iconUrl"
            [buttonWidth]="'140px'"
            [backgroundColor]="rechazadoStyles.backgroundColor"
            [textColor]="rechazadoStyles.textColor"
            [isActive]="rechazadoStyles.isActive"
            (action)="onRechazadoClicked()">
          </app-custom-button-color>
        </div>
      </div>

      <div class="datos-solicitud">
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="filterReasonList"
            [(selectedValue)]="reasonSelect"
            (selectedValueChange)="changeReason()">
        </app-custom-inpunt-select>
        <app-custom-text-area [(value)]="comment"></app-custom-text-area>
      </div>
     
      <p class="titulo-confirmacion">Ingresa datos de Usuario</p>
      <div class="contenedor-supervisor">
        <div class="form-group">
          <label>Usuario</label>
          <app-custom-input [(value)]="username"></app-custom-input>
        </div>
        <div class="form-group">
          <label>Contraseña</label>
          <app-custom-input-password [(value)]="password"></app-custom-input-password>
        </div>
      </div>

      <div class="contenedor-boton-confirmacion">
        <a href="#" class="link-editar" (click)="returnTray(); $event.preventDefault()">CANCELAR</a>
        <app-custom-button text="Enviar" (action)="updateStateApplication()"></app-custom-button>
      </div>
    
    </div>
  </div>

  <div *ngIf="isObservado" class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="CANCELAR" (action)="canelHandler()"></app-custom-button-color>
    <app-custom-button text="ENVIAR" (action)="sendHandler()"></app-custom-button>
  </div>

  <div *ngIf="supervisorRole && customerApplication.stateCode === 3" class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="CANCELAR" (action)="canelHandler()"></app-custom-button-color>
    <app-custom-button text="ENVIAR A APROBACION" (action)="sendApprovalHandler()"></app-custom-button>
  </div>

 <div class="informacion-producto">
    <div class="contenedor-resumen">

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">ESTADO</span>
        <p>{{ customerApplication.stateName }}</p>
      </div>
    </div>

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">FECHA DE CREACIÓN</span>
        <p>{{ customerApplication.createdDateTimeFormat }}</p>
      </div>
    </div>

    <div class="resumen-estado">
      <img src="assets/calendario.svg" alt="">
      <div class="texto-resumen">
        <span class="titulo">FECHA ÚLTIMA MODIFICACIÓN</span>
        <p>{{ customerApplication.updatedDateTimeFormat }}</p>
      </div>
    </div>

    
    </div>
  </div>
</div>
<app-footer></app-footer>

<app-info-modal *ngIf="showModal" [titulo]="'Datos Personales'" [width]="modalWidth" [height]="modalHeight"  (onClose)="handleClose()">
    <app-datos-generales-modal-pasivo [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-generales-modal-pasivo>
</app-info-modal>
<app-info-modal *ngIf="showModalDireccion" [titulo]="'Datos de Dirección'" [width]="modalWidthDireccion" [height]="modalHeightDireccion" (onClose)="handleClose()">
    <app-datos-direccion-modal-pasivo [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-direccion-modal-pasivo>
</app-info-modal>
<app-info-modal *ngIf="showModalDesembolso" [titulo]="'Modo de Desembolso'" [width]="modalWidthDesembolso" [height]="modalHeightDesembolso" (onClose)="handleClose()">
    <app-datos-tipo-cuenta-modal-pasivo [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkActionSavingAccount($event)"></app-datos-tipo-cuenta-modal-pasivo>
</app-info-modal>

<app-datos-adjuntos-modal-pasivo *ngIf="showModalAdjuntos" [onlyView]="onlyView" (onClose)="handleClose()" (linkAction)="handleLinkAttachmentAction($event)"></app-datos-adjuntos-modal-pasivo>

<app-modal-resumen *ngIf="showModalResumen" [width]="450" [height]="430" (linkAction)="accionPersonalizada()"
           [titleText]="tituloModal" [body]="updateApplication" (onClose)="handleCloseAlta()">
</app-modal-resumen>

<loading *ngIf="loading"></loading>