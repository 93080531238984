<app-header [headerText]="'Resumen'"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <div class="container-info-producto">
      <div class="lado-izquierdo">
        <span class="punto"></span>
        <span class="texto-producto">Producto</span>
      </div>
      <!-- <app-custom-button-color text="EDITAR"></app-custom-button-color> -->
    </div>
  </div>



  <div class="informacion-producto">
    <div class="caja-redondeada-sombreada">
      <div class="contenido-caja">
        <div class="contenido-caja-container">
          <div class="seccion-imagen">
            <img src="assets/alcancia.svg" alt="Descripción de la imagen">
            <div class="detalle-titulo">
              <span class="titulo-producto">APERTURA DE CUENTA</span>
              <!-- <p class="valor-numerico">{{ applicationSavingAccount?.subProductCode }} - {{applicationSavingAccount?.productDescription}}</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos Personales</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Nombre:</span>{{ customer?.name }} {{ customer?.middleName}} {{ customer?.lastname }} {{ customer?.secondLastname}}</p>
          <p><span class="titulo">{{ client.documentTypeDesciption }}:</span> {{ customer?.documentNumber }}</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Celular:</span> {{ customer?.personalCellPhoneNumber }}</p>
          <p><span class="titulo">Correo:</span> {{ customer?.emailAddress }}</p>
        </div>        
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModal();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>



  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos de Dirección</p>
        </div>
        <div class="inf-detalle">
          <p> {{ customer?.customerStreetName }} {{ customer?.customerStreetNumber }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModalDireccion();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Tipo de Cuenta</p>
        </div>
        <div class="inf-detalle">
          <p>{{ applicationSavingAccount?.subProductCode }} - {{applicationSavingAccount?.productDescription}}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModalDesembolso();"></app-custom-button-color>
        </div>
      </div>
    </div>

  </div>

  <hr>
  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Archivos Adjuntos</p>
        </div>
        <div *ngFor="let file of filesList" class="inf-detalle">
          <p>{{ file.name }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModalAdjuntos();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div  class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="No Acepta" (action)="handleSaveCancellationApplication()"></app-custom-button-color>
    <app-custom-button text="Acepta" (action)="nextStepApplication()"></app-custom-button>
  </div>

</div>
<app-footer></app-footer>

<app-info-modal *ngIf="showModal" [titulo]="'Datos Personales'" [width]="modalWidth" [height]="modalHeight" (onClose)="handleClose()">
  <app-datos-generales-modal (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-generales-modal>
</app-info-modal>

<app-info-modal *ngIf="showModalDireccion" [titulo]="'Datos de Dirección'" [width]="modalWidthDireccion" [height]="modalHeightDireccion" (onClose)="handleClose()">
  <app-datos-direccion-modal (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-direccion-modal>
</app-info-modal>

<app-info-modal *ngIf="showModalDesembolso" [titulo]="'Tipo de Cuenta'" [width]="modalWidthDesembolso" [height]="modalHeightDesembolso" (onClose)="handleClose()">
  <app-datos-desembolso-modal-bandeja (onClose)="handleClose()" (linkAction)="handleLinkActionSavingAccount($event)"></app-datos-desembolso-modal-bandeja>
</app-info-modal>

<app-datos-adjuntos-modal *ngIf="showModalAdjuntos" (onClose)="handleClose()" (linkAction)="handleLinkAttachmentAction($event)"></app-datos-adjuntos-modal>

<!-- <app-info-modal *ngIf="showModalAdjuntos" [titulo]="'Adjuntos'" [width]="modalWidthAdjuntos" [height]="modalHeightAdjuntos" (onClose)="handleClose()">
  <app-datos-adjuntos-modal (onClose)="handleClose()"></app-datos-adjuntos-modal>
</app-info-modal> -->


<loading *ngIf="loading"></loading>