
<app-header [headerText]="'Usuarios'"  [showImage]="false"></app-header>

<div class="layout-container">
  <div class="contenedor-filtros">
    <div class="filtro-inputs">
      <div class="form-group filtro-3">
        <label class="titulo-filtro">Nombre</label>
        <app-custom-input [(value)]="nameUser" [maxlength]="max"></app-custom-input>
      </div>
    </div>
  
    <div class="filtro-botones">
      <app-custom-button-color 
      text="limpiar" (action)="clear()"></app-custom-button-color>
  
      <app-custom-button
      text="buscar" (action)="seach()"></app-custom-button>
      
    </div>
  </div>
</div>

<div class="resultado-busqueda">
  <div class="resultados">
    <label for="" class="label-resultado">Resultado de Búsqueda</label>
    <label for="" class="label-nro-resultado">{{nroResultados}} registros encontrados</label>
  </div>
 <div class="btn-add-usuario">
  <app-custom-button
  text="Agregar Usuario"  iconUrl="assets/img/add_user.svg"  (action)="addUsuario('add')"></app-custom-button>
 </div>
</div>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="tusDatos" actionButtonText="EDITAR" [navigate]="addUsuario.bind(this)"></app-custom-table-paginator>
  </div>
</div>
<app-footer></app-footer>



