import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminEmpresasRoutingModule } from './admin-empresas-routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent } from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { AdminEmpresasComponent } from './admin-empresas.component';


@NgModule({
  declarations: [AdminEmpresasComponent],
  imports: [
    CommonModule,
    AdminEmpresasRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent
  ]
})
export class AdminEmpresasModule { }
