import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pasivos-validacion-correo',
  templateUrl: './pasivos-validacion-correo.component.html',
  styleUrls: ['./pasivos-validacion-correo.component.scss']
})
export class PasivosValidacionCorreoComponent {
  
  constructor(private router: Router) { }


}
