import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomInputComponent, CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ApplicationService } from 'app/services/application.service';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';

@Component({
  selector: 'app-pasivos-home',
  templateUrl: './pasivos-home.component.html',
  styleUrls: ['./pasivos-home.component.scss']
})
export class PasivosHomeComponent implements OnInit {

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';
  canalVenta: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION) ?? '';
  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  salesChannel: string = sessionStorage.getItem(AppConstants.Session.SALESCHANNEL) ?? '';
  salesSubChannel: string = sessionStorage.getItem(AppConstants.Session.SALESSUBCHANNEL) ?? '';
  enterpriseId: string = sessionStorage.getItem(AppConstants.Session.ENTERPRISE) ?? '0';

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  loading: boolean = false;

  isSearchDisabled: boolean = true; 
  selectedTypeDocument = '1';
  mostrarInformacion = false;
  dni = '';
  nombreCliente = '';
  numeroResultados = 0;
  
  tituloModal='';
  subTituloModal='';
  parrafo='';
  textoEnlaceModal='';

  miPlaceholder = "Número documento";
  
  documentTypeDescription: string = '';
  documents: any = [];
  documentForm: string = '';
  typeInputDocument: string = 'text';
  max: number = 8;
  valNumber: boolean = true;
  

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  @ViewChild(CustomInputComponent) customInput!: CustomInputComponent;

  constructor(
    public dialog: MatDialog, 
    private cdr: ChangeDetectorRef,
    private router: Router,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
  ) {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit() {
    await this.getDocuments();
  }

  
  ngAfterViewInit() {
    this.cdr.detectChanges();

  }

  abrirModal(): void {
    this.mostrarInformacion = true;
  }

  cerrarModal() {
    this.documentForm = '';
    this.showModal = false;
    this.customInput.focusInput();
  }

  accionPersonalizada(){
    this.showModal = false;
  }

  redireccionar(producto: any) {
    
    this.router.navigate(['simulador-oferta'], { queryParams: producto });
  }

  openModal(title: string, subtitle: string, description: string, labelButton: string) {
    this.showModal = true;
    this.tituloModal= title;
    this.subTituloModal= subtitle;
    this.parrafo = description;
    this.textoEnlaceModal= labelButton;
  }

  async getDocuments() {
    this.loading = true;
    let model = [AppConstants.ParameterCode.DOCUMENT_TYPE];
    this.parameterService.getParametersList(model).subscribe({
      next: async (response: any) => {
        let parameters = (response.filter((f: any) => f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        parameters.forEach((element: any) => {
          this.documents.push({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 });
        });
        this.typeOperation();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  typeOperation(): void {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if (document === undefined) {
        this.max = 8;
        this.valNumber = true;
    } else {
        this.documentTypeDescription = document.label;
        this.max = document.length;
        this.documentForm = '';
        if (document.label.toUpperCase().includes('C.E')
            || document.label.toUpperCase().includes('CE')
            || document.label.toUpperCase().includes('CARNE')
            || document.label.toUpperCase().includes('EXTRANJERIA')) {
            this.valNumber = false;
            this.typeInputDocument = 'text';
        } else {
            this.valNumber = true;
            this.typeInputDocument = 'tel';
        }
    }
  }

  removeSpacesDocument() {
    this.documentForm = this.documentForm.replace(/ /g, ' ');
  }

  onlyNumber() {
    if (this.valNumber) {
    } else {
      this.documentForm = this.documentForm.replace(/[^a-zA-Z0-9_-]/g, '');
    }
  }

  searchDocument() {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if(this.documentForm.length == document.length) {
      let model = {
        "documentType": this.selectedTypeDocument,
        "documentNumber": this.documentForm,
        "userId": this.usuario,
        "product": AppConstants.Products.Savings
      }
      this.validateApplicationInProgress(model);
    }
  }

  validateApplicationInProgress(model: any) {
    this.loading = true;
    this.applicationService.returnApplication(model).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.callComplianceClient(model);
        } else {
          if (response.body.createdBy === this.usuario) {
            sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.body.applicationId);
            sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.body.instannceId);

            var client = new ClienteSession();
                client.documentTypeDesciption = response.body.documentTypeDescription;
                client.documentType = response.body.documentType;
                client.documentNumber = response.body.documentNumber;
                client.fullName = response.body.fullName;
                client.userCreation = this.usuario;


            var product = new ProductoSession();
                product.image = response.body.product.image;
                product.name = response.body.product.name;

            sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
            sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
            sessionStorage.setItem(AppConstants.Session.SIMULACION, JSON.stringify({ loanAmountFormat: response.body.applicationAmountFormat}));

            this.router.navigateByUrl(response.body.path);
            this.loading = false;
          }
          else {
            this.loading = false;
            this.alertService.showMessage('Error', 'La solicitud '+ response.body.applicationId +' ya esta siendo tomada por el usuario ' + response.body.createdBy, MessageSeverity.error);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error:', error);
        this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, error.error.message, MessageSeverity.error);
      },
      complete: () => {
      }
    });
  }  

  callComplianceClient(model: any) {
    this.loading = true;
    this.customerService.postAdmissionCustomer(model).subscribe({
      next: async (response) => {
        this.loading = false;


        if (response.meta && response.meta.mensajes && response.meta.mensajes.length > 0) {
          let codigoMensaje = response.meta.mensajes[0].codigo;
          if (codigoMensaje === "0") {
            if(response.datos && response.datos.respuestaOperacion && response.datos.respuestaOperacion.flagOperacion == "1") {
              this.saveSession();
              this.registerApplication();
            }
            else {
              let title = response.datos.reglaOriginacion[0].mensaje;
              // let description = response.datos.reglaOriginacion[0].descripcion + ' - ' + response.datos.reglaOriginacion[0].id;
              let description = response.datos.reglaOriginacion[0].descripcion;
              this.openModal(title, description, '', 'CERRAR');
            }

          } else {
            this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
          }
        } else {
          this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
        }
  
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }  

  registerApplication() {

    let model = {
      countryCode: "604",
      enterpriseId: this.addLeadingZeros(+this.enterpriseId),
      documentType: this.selectedTypeDocument,
      documentNumber: this.documentForm,
      productCode: "AHOR",
      channelId: AppConstants.Channels.DesktopPasivosPVA,
      salesChannelId: +this.salesChannel,
      processId: AppConstants.Process.APERTURA_CUENTA_AHORROS,
      taskId: AppConstants.WorkflowTasks.PASIVOS_PRODUCT_EVALUATION,
      taskInstanceRole: this.rolName,
      createdby: this.usuario,
      createdbyFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName,
      salesSubChannelId: this.salesSubChannel === 'null' ? null : this.salesSubChannel,
      searchDate: this.getCurrentDateTimeUTC5()
    };

    this.addRegisterApplication(model);
  }

  saveSession() {
    var client = new ClienteSession();
        client.documentTypeDesciption = this.documentTypeDescription;
        client.documentType = this.selectedTypeDocument;
        client.documentNumber = this.documentForm;
        client.fullName = "";
        client.userCreation = this.usuario;

    var product = new ProductoSession();
        product.image = "assets/alcancia.svg";
        product.name = "APERTURA DE CUENTA";

    sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));
    sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product)); 
  }

  addLeadingZeros(num: number, length: number = 3): string {
    return num.toString().padStart(length, '0');
  }

  addRegisterApplication(model: any) {
    this.loading = true;
    this.applicationService.postRegisterApplicationSavings(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_TITLE, AppConstants.MessageAlert.REGISTER_APPLICATION_SUCCESS, MessageSeverity.success);
        sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.applicationId);
        sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.workflowIntanceId);
        this.router.navigate(['pasivos-datos-personales']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_TITLE, AppConstants.MessageAlert.REGISTER_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  getCurrentDateTimeUTC5(): Date {
    const currentDateUTC = new Date();
    const utcMinus5Offset = 5 * 60 * 60 * 1000;
    const currentDateUTC5 = new Date(currentDateUTC.getTime() - utcMinus5Offset);  
    return currentDateUTC5;
  }

}
