
<div class="layout-container">

  <div  *ngIf="!esMovil" class="formulario-container">
        <label class="titulo-direccion">Dirección Laboral</label>
        <div class="form-row">
          <div class="form-group tipo-via">
            <label>Tipo de Vía</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesTipoVia" [isReadonly]="elementsReadOnly.includes('tipoVia')"
            [(selectedValue)]="tipoVia"  (selectedValueChange)="tipoVia=$event" [isError]="isErrorTipoVia">
            </app-custom-inpunt-select>
          </div>

          <div class="form-group nombre-via">
            <label>Nombre de vía</label>
            <app-custom-input [(value)]="nombreVia" [isError]="isErrorNombreVia" [isReadonly]="elementsReadOnly.includes('nombreVia')"
            (keyup)="resetErrorState('nombreVia')" [minlength]="nombreViaValMin" [maxlength]="nombreViaValMax"></app-custom-input>
          </div>

          <div class="form-group numero-via">
            <label>Número</label>
            <app-custom-input [(value)]="numero" [type]="typeInputNumber" [maxlength]="max" [isError]="isErrorNumero" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('numero')"
              (keyup)="resetErrorState('numero')" [minlength]="numeroValMin" [maxlength]="numeroValMax"></app-custom-input>
          </div>

      
        </div>
        
        <div class="form-row">
          <div class="form-group interior">
            <label>Interior</label>
            <app-custom-input [(value)]="interior" [isError]="isErrorInterior" [isReadonly]="elementsReadOnly.includes('interior')"
              (keyup)="resetErrorState('interior')" [minlength]="interiorValMin" [maxlength]="interiorValMax"></app-custom-input>
          </div>
          <div class="form-group urbanizacion">
            <label>Urbanización</label>
            <app-custom-input [(value)]="urbanizacion" [isError]="isErrorUrbanizacion" [isReadonly]="elementsReadOnly.includes('urbanizacion')"
            (keyup)="resetErrorState('urbanizacion')" [minlength]="urbanizacionValMin" [maxlength]="urbanizacionValMax"></app-custom-input>
          </div>
        </div>

        <div class="form-row">
    
          <div class="form-group referencia">
            <label>Referencia</label>
            <app-custom-input [(value)]="referencia" [isError]="isErrorReferencia" [isReadonly]="elementsReadOnly.includes('referencia')"
            (keyup)="resetErrorState('referencia')" [minlength]="referenciaValMin" [maxlength]="referenciaValMax"></app-custom-input>

          </div>
          <div class="form-group departamento">
            <label>Departamento</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDepartamento" [isReadonly]="elementsReadOnly.includes('departamento')"
            [(selectedValue)]="departamento"  (selectedValueChange)="departamento=$event" [isError]="isErrorDepartamento" (change)="getProvinces($event.target)">
            </app-custom-inpunt-select>
          </div>

        </div>

        <div class="form-row">
    
          <div class="form-group provincia">
            <label>Provincia</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesProvincia" [isReadonly]="elementsReadOnly.includes('provincia')"
            [(selectedValue)]="provincia"  (selectedValueChange)="provincia=$event" [isError]="isErrorProvincia" (change)="getDistricts($event.target)">
            </app-custom-inpunt-select>
          </div>

          <div class="form-group distrito">
            <label>Distrito</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDistrito" [isReadonly]="elementsReadOnly.includes('distrito')"
            [(selectedValue)]="distrito"  (selectedValueChange)="distrito=$event" [isError]="isErrorDistrito">
            </app-custom-inpunt-select>
          </div>
            
        </div>

        <label class="titulo-direccion">Teléfonos y contacto</label>

        <div class="form-row">
          <div class="form-group telefono-residencia">
            <label>Teléfono de Residencia</label>
            <app-custom-input [(value)]="telefonoResidencia" [isError]="isErrorTelefonoResidencia" [isReadonly]="elementsReadOnly.includes('telefonoResidencia')"
            (keyup)="resetErrorState('telefonoResidencia')" ></app-custom-input>
          </div>
          <div class="form-group telefono-trabajo">
            <label>Teléfono de Trabajo</label>
            <app-custom-input [(value)]="telefonoTrabajo" [isError]="isErrorTelefonoTrabajo" [isReadonly]="elementsReadOnly.includes('telefonoTrabajo')"
            (keyup)="resetErrorState('telefonoTrabajo')" ></app-custom-input>
          </div>
          <div class="form-group celular-3">
            <label>Celular Complementario</label>
            <app-custom-input [(value)]="celularAdicional" [isError]="isErrorCelularAdicional" [isReadonly]="elementsReadOnly.includes('celularAdicional')"
            (keyup)="resetErrorState('celularAdicional')" ></app-custom-input>
          </div>
        </div>

        <div class="form-row">
      
          <div class="form-group correo-complementario">
            <label>Correo Complementario</label>
            <app-custom-input [(value)]="correoAdicional" [isError]="isErrorCorreoAdicional" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('correoAdicional')"
            (keyup)="resetErrorState('correoAdicional')"></app-custom-input>
          </div>
    
          <div class="button-add-referencia">
            <app-custom-button-color text="Agregar Referencia" iconUrl="assets/img/agregar_usuario.svg"
            (action)="addReferencia()"
            ></app-custom-button-color>
          </div>
        </div>


        <div *ngFor="let referencia of referencias; let i = index" class="form-row-telefono">
          <div class="form-group-adicional-telefono">
            <div class="referencia-container">
              <label>Referencia {{ referencia.id }}</label>
              <app-custom-input></app-custom-input>
            </div>
            <div class="telefono-referencia">
              <label>Teléfono</label>
              <app-custom-input ></app-custom-input>
            </div>
            <div class="referencia-1">
              <app-custom-button-link text="Borrar" iconUrl="assets/img/cerrar_elimi.svg" (action)="borrarReferencia(referencia.id)"></app-custom-button-link>
            </div>
          </div>

        </div>
        <label class="titulo-direccion">Nivel de Educación</label>

        <div class="form-row">
          <div class="form-group nivel-educacion">
            <label>Nivel de Educación</label>
            <app-custom-inpunt-select [(selectedValue)]="nivelEducacion" [isError]="isErrorNivelEducacion"  placeholder="Seleccionar"  [width]="'284px'"
            [options]="opcionesNivelEducacion" (selectedValueChange)="nivelEducacion=$event"></app-custom-inpunt-select>
          </div>
          <div class="form-group profesion">
            <label>Profesión</label>
            <app-custom-inpunt-select [(selectedValue)]="profesion" [isError]="isErrorProfesion"  placeholder="Seleccionar" [width]="'284px'"
            [options]="opcionesProfesion" (selectedValueChange)="profesion=$event"></app-custom-inpunt-select>
          </div>
          <div class="form-group cargo-ocupacion">
            <label>Cargo Ocupación</label>
            <app-custom-inpunt-select [(selectedValue)]="cargoOcupacion" [isError]="isErrorCargoOcupacion"  placeholder="Seleccionar" [width]="'284px'"
            [options]="opcionesCargoOcupacion" (selectedValueChange)="cargoOcupacion=$event"></app-custom-inpunt-select>
          </div>
        </div>

        <label class="titulo-direccion">Datos de la Empresa</label>

        <div class="form-row">
          <div class="form-group">
            <label>Nombre</label>
            <app-custom-input  [(value)]="nombreEmpresa" [isError]="isErrorNombreEmpresa" 
            (keyup)="resetErrorState('nombreEmpresa')" [minlength]="nombreEmpresaValMin" [maxlength]="nombreEmpresaValMax"></app-custom-input>
          </div>
          <div class="form-group">
            <label>RUC</label>
            <app-custom-input [(value)]="rucEmpresa" [isError]="isErrorRucEmpresa" [type]="typeInputNumber" [maxlength]="maxLengthRUC" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('rucEmpresa')"
            (keyup)="resetErrorState('rucEmpresa')" [minlength]="rucEmpresaValMin" [maxlength]="rucEmpresaValMax">
            </app-custom-input>
          </div>
          <div class="form-group">
            <label>Ingresos Netos</label>
            <app-custom-input [(value)]="ingresosNetos" [isError]="isErrorIngresosNetos" [type]="typeInputNumber" [maxlength]="maxLengthIngresos" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('ingresosNetos')"
            (keyup)="resetErrorState('ingresosNetos')">
            </app-custom-input>
          </div>
        </div>
        
  </div>  
    
    
  <div  *ngIf="esMovil" class="formulario-container">
      <label class="titulo-direccion">Dirección Laboral</label>
      <div class="form-row">
        <div class="form-group tipo-via-movil">
          <label>Tipo de Vía</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesTipoVia" [isReadonly]="elementsReadOnly.includes('tipoVia')"
          [(selectedValue)]="tipoVia"  (selectedValueChange)="tipoVia=$event" [isError]="isErrorTipoVia">
          </app-custom-inpunt-select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group nombre-via-movil">
          <label>Nombre de vía</label>
          <app-custom-input [(value)]="nombreVia" [isError]="isErrorNombreVia" [isReadonly]="elementsReadOnly.includes('nombreVia')"
          (keyup)="resetErrorState('nombreVia')" [minlength]="nombreViaValMin" [maxlength]="nombreViaValMax"></app-custom-input>
        </div>

        <div class="form-group numero-movil">
          <label>Número </label>
          <app-custom-input [(value)]="numero" [type]="typeInputNumber" [maxlength]="max" [isError]="isErrorNumero" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('numero')"
          (keyup)="resetErrorState('numero')" [minlength]="numeroValMin" [maxlength]="numeroValMax"></app-custom-input>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group interior-movil">
          <label>Interior</label>
          <app-custom-input [(value)]="interior" [isError]="isErrorInterior" [isReadonly]="elementsReadOnly.includes('interior')"
          (keyup)="resetErrorState('interior')" [minlength]="interiorValMin" [maxlength]="interiorValMax"></app-custom-input>
        </div>

        <div class="form-group urbanizacion-movil">
          <label>Urbanización</label>
          <app-custom-input [(value)]="urbanizacion" [isError]="isErrorUrbanizacion" [isReadonly]="elementsReadOnly.includes('urbanizacion')"
          (keyup)="resetErrorState('urbanizacion')" [minlength]="urbanizacionValMin" [maxlength]="urbanizacionValMax"></app-custom-input>
        </div> 
      </div>

      <div class="form-row">
          <div class="form-group referencia-movil">
            <label>Referencia</label>
            <app-custom-input [(value)]="referencia" [isError]="isErrorReferencia" [isReadonly]="elementsReadOnly.includes('referencia')"
            (keyup)="resetErrorState('referencia')" [minlength]="referenciaValMin" [maxlength]="referenciaValMax"></app-custom-input>

          </div>
          
      </div>
        
      <div class="form-row">
          <div class="form-group departamento-movil">
            <label>Departamento</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDepartamento" [isReadonly]="elementsReadOnly.includes('departamento')"
            [(selectedValue)]="departamento"  (selectedValueChange)="departamento=$event" [isError]="isErrorDepartamento" (change)="getProvinces($event.target)">
            </app-custom-inpunt-select>
          </div>

          <div class="form-group provincia-movil">
            <label>Provincia</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesProvincia" [isReadonly]="elementsReadOnly.includes('provincia')"
            [(selectedValue)]="provincia"  (selectedValueChange)="provincia=$event" [isError]="isErrorProvincia" (change)="getDistricts($event.target)">
            </app-custom-inpunt-select>
          </div>
          
      </div>

      <div class="form-row">
          <div class="form-group distrito-movil">
            <label>Distrito</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDistrito" [isReadonly]="elementsReadOnly.includes('distrito')"
            [(selectedValue)]="distrito"  (selectedValueChange)="distrito=$event" [isError]="isErrorDistrito">
            </app-custom-inpunt-select>
          </div>
      </div>

      
      <label class="titulo-direccion">Teléfonos y contacto</label>

      <div class="form-row">
        <div class="form-group telefono-residencia">
          <label>Teléfono de Residencia</label>
          <app-custom-input [(value)]="telefonoResidencia" [isError]="isErrorTelefonoResidencia" [isReadonly]="elementsReadOnly.includes('telefonoResidencia')"
          (keyup)="resetErrorState('telefonoResidencia')" ></app-custom-input>
        </div>
        <div class="form-group telefono-trabajo">
          <label>Teléfono de Trabajo</label>
          <app-custom-input [(value)]="telefonoTrabajo" [isError]="isErrorTelefonoTrabajo" [isReadonly]="elementsReadOnly.includes('telefonoTrabajo')"
          (keyup)="resetErrorState('telefonoTrabajo')" ></app-custom-input>
        </div>

      </div>



      <div class="form-row">

        <div class="form-group celular-2-movil">
          <label>Celular Complementario</label>
          <app-custom-input [(value)]="celularAdicional" [isError]="isErrorCelularAdicional" [isReadonly]="elementsReadOnly.includes('celularAdicional')"
          (keyup)="resetErrorState('celularAdicional')" ></app-custom-input>
        </div>
      </div>

      <div class="form-row">

        <div class="form-group correo-complementario-movil">
          <label>Correo Complementario</label>
          <app-custom-input [(value)]="correoAdicional" [isError]="isErrorCorreoAdicional" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('correoAdicional')"
          (keyup)="resetErrorState('correoAdicional')"></app-custom-input>
        </div>

      </div>

      <div class="form-row">
        <div class="button-add-referencia">
          <app-custom-button-color text="Agregar Referencia" iconUrl="assets/img/agregar_usuario.svg"
          (action)="addReferencia()"
          ></app-custom-button-color>
        </div>
      </div>

      <div *ngFor="let referencia of referencias; let i = index" class="form-row-telefono">
        <div class="form-group-adicional-telefono">
          <div class="referencia-container">
            <label>Referencia {{ referencia.id }}</label>
            <app-custom-input></app-custom-input>
          </div>
        </div>

        <div class="form-group-adicional-telefono">
  
          <div class="telefono-referencia">
            <label>Teléfono</label>
            <app-custom-input ></app-custom-input>
          </div>
          <div class="referencia-1">
            <app-custom-button-link text="Borrar" iconUrl="assets/img/cerrar_elimi.svg" (action)="borrarReferencia(referencia.id)"></app-custom-button-link>
          </div>
        </div>

      </div>
      <label class="titulo-direccion">Nivel de Educación</label>

      <div class="form-row">
        <div class="form-group nivel-educacion-movil">
          <label>Nivel de Educación</label>
          <app-custom-inpunt-select [(selectedValue)]="nivelEducacion" [isError]="isErrorNivelEducacion"  placeholder="Seleccionar"  [width]="'284px'"
          [options]="opcionesNivelEducacion" (selectedValueChange)="nivelEducacion=$event"></app-custom-inpunt-select>
        </div>
        <div class="form-group profesion-movil">
          <label>Profesión</label>
          <app-custom-inpunt-select [(selectedValue)]="profesion" [isError]="isErrorProfesion"  placeholder="Seleccionar" [width]="'284px'"
          [options]="opcionesProfesion" (selectedValueChange)="profesion=$event"></app-custom-inpunt-select>
        </div>

      </div>

      <div class="form-row">

        <div class="form-group cargo-ocupacion-movil">
          <label>Cargo Ocupación</label>
          <app-custom-inpunt-select [(selectedValue)]="cargoOcupacion" [isError]="isErrorCargoOcupacion"  placeholder="Seleccionar" [width]="'284px'"
          [options]="opcionesCargoOcupacion" (selectedValueChange)="cargoOcupacion=$event"></app-custom-inpunt-select>
        </div>
      </div>

      

      <label class="titulo-direccion">Datos de la Empresa</label>

      <div class="form-row">
        <div class="form-group nombre-empresa-movil">
          <label>Nombre</label>
          <app-custom-input  [(value)]="nombreEmpresa" [isError]="isErrorNombreEmpresa" 
          (keyup)="resetErrorState('nombreEmpresa')" [minlength]="nombreEmpresaValMin" [maxlength]="nombreEmpresaValMax"></app-custom-input>
        </div>

      </div>

      <div class="form-row">

        <div class="form-group ruc-movil">
          <label>RUC</label>
          <app-custom-input [(value)]="rucEmpresa" [isError]="isErrorRucEmpresa" [type]="typeInputNumber" [maxlength]="maxLengthRUC" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('rucEmpresa')"
          (keyup)="resetErrorState('rucEmpresa')" [minlength]="rucEmpresaValMin" [maxlength]="rucEmpresaValMax">
          </app-custom-input>
        </div>
        <div class="form-group ingresos-netos-movil">
          <label>Ingresos Netos</label>
          <app-custom-input [(value)]="ingresosNetos" [isError]="isErrorIngresosNetos" [type]="typeInputNumber" [maxlength]="maxLengthIngresos" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('ingresosNetos')"
          (keyup)="resetErrorState('ingresosNetos')">
          </app-custom-input>
        </div>
      </div>

      
      
  </div>  

    
</div>

<div class="footer-buttons">
  <app-custom-button-link text="CANCELAR" (action)="close()"></app-custom-button-link>
  <app-custom-button text="ACEPTAR" (action)="validarYCambiarComponente()"></app-custom-button>
</div>
<loading *ngIf="loading"></loading>