import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "account/customer-account";
  private BASE_URL: string = environment.customerAccountApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postRetrieveCurrentAccounts(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/retrieve/current-accounts`, model);
  }
  
}
