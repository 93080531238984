<!-- componente-padre.component.html -->
<app-header [headerText]="'3. Datos Personales'"  headerImageSrc="assets/img/cliente_datos.svg"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <app-datos-generales *ngIf="componenteActivo === 'datosGenerales'" (cambiarComponente)="mostrarComponente('datosDireccion')"></app-datos-generales>
    <app-datos-direccion *ngIf="componenteActivo === 'datosDireccion'" (cambiarComponente)="mostrarComponente('datosDireccionLaboral')"></app-datos-direccion>
    <app-datos-direccion-laboral *ngIf="componenteActivo === 'datosDireccionLaboral'" (cambiarComponente)="mostrarComponente('proteccionDatos')"></app-datos-direccion-laboral>
    <app-proteccion-datos *ngIf="componenteActivo === 'proteccionDatos'" (cambiarComponente)="mostrarComponente('modoDesembolso')"></app-proteccion-datos>
    <app-modo-desembolso *ngIf="componenteActivo === 'modoDesembolso'" ></app-modo-desembolso>

  </div>
</div>
<app-footer></app-footer>


