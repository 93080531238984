import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumenComponent } from './resumen.component';
import { ResumenRoutingModule } from './resumen-routing.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import { CustomButtonComponent, CustomInputComponent, HeaderComponent,CustomInpuntSelectComponent,
  CustomTableSimulatorComponent,FooterComponent,CustomButtonColorComponent,ModalComponent,InfoModalComponent, ModalResumenComponent, CustomDatepickerComponent, CustomButtonLinkComponent, CustomInputLoginComponent } from 'app/components';
import { DatosClienteComponent } from '../datos-personales/components/datos-cliente/datos-cliente.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DatosGeneralesModalComponent } from './components/datos-generales-modal/datos-generales-modal.component';
import { DatosAdjuntosModalComponent } from './components/datos-adjuntos-modal/datos-adjuntos-modal.component';
import { DatosDireccionModalComponent } from './components/datos-direccion-modal/datos-direccion-modal.component';
import { DatosOtrosModalComponent } from './components/datos-otros-modal/datos-otros-modal.component';
import { DatosDesembolsoModalBandejaComponent } from './components/datos-desembolso-modal-bandeja/datos-desembolso-modal-bandeja.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { CustomButtonBorderComponent } from 'app/components/custom-button-border';

@NgModule({
  declarations: [
    ResumenComponent,
    DatosGeneralesModalComponent,DatosAdjuntosModalComponent,DatosDireccionModalComponent,DatosOtrosModalComponent,DatosDesembolsoModalBandejaComponent
  ],
  imports: [
    CommonModule,
    ResumenRoutingModule,
    MatToolbarModule,
    HeaderComponent,
    CustomButtonComponent,
    CustomInputComponent,
    CustomInpuntSelectComponent,
    CustomTableSimulatorComponent,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    FooterComponent,
    ModalResumenComponent,
    ModalComponent,
    InfoModalComponent,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,MatSelectModule, FormsModule, ReactiveFormsModule,
    CustomButtonColorComponent,
    CustomDatepickerComponent,
    CustomButtonLinkComponent,
    CustomInputLoginComponent,
    SharedModule,
    CustomButtonBorderComponent
  ]
})
export class ResumenModule { }
