import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasivosValidacionComponent } from './pasivos-validacion.component';
import { PasivosValidacionComponentRoutingModule } from './pasivos-validacion-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent } from 'app/components';

@NgModule({
  declarations: [
    PasivosValidacionComponent
  ],
  imports: [
    CommonModule,
    PasivosValidacionComponentRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent
  ]
})
export class PasivosValidacionModule { }
