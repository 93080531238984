<div class="contenedor-informacion">

    <div class="informacion-producto container-imagen">
      <img src="assets/img/validacion_correo.svg" alt="Descripción de la imagen">
   
    </div>

     <div *ngIf="validationIncomplete"  class="informacion-producto containter-validacion">
        <div class="container-texto-celular">
            <label class="texto-celular">1. </label> <p class="texto-descripcion"> Correo electrónico</p>
        </div>
        <div class="container-celular">
          <!-- <app-custom-input [(value)]="email"></app-custom-input> -->
          <!-- <app-custom-input [(value)]="email" [isError]="isErrorEmail" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('email')"></app-custom-input> -->
           {{ email }}
        </div>
        <app-custom-button text="Enviar"  (action)="sendOtpEmail()"></app-custom-button>
        <p class="texto-validacion">
          Se enviará un codigo de 6 dígitos para la validación del correo electrónico. 
         </p>
     </div> 

      <div *ngIf="validationInProgress" class="informacion-producto containter-validacion">
        <div class="container-texto-finalizada">
            <p class="texto-validacion"> Esperando validación, ingresar el código de 6 digitos enviado por correo electrónico al cliente.</p>
        </div>
        <div class="container-celular">
          <app-custom-input [(value)]="otpCode" [isError]="isErrorOtpCode" [type]="typeInputNumber" [maxlength]="max" [validatemaxlength]="true"
            (keyup)="resetErrorState()">
          </app-custom-input>
        </div>
        <!-- <app-custom-button-color text="CAMBIAR NÚMERO" (action)="changeNumber()"></app-custom-button-color> -->
        <app-custom-button-color text="REENVIAR" (action)="retrySend()"></app-custom-button-color>
        <app-custom-button text="VALIDAR"  (action)="validateEmail()"></app-custom-button>
      </div>  

    <div *ngIf="validationCompleted" class="informacion-producto containter-validacion-finalizada">
        <div class="container-texto-finalizada">
            <p class="texto-descripcion"> ¡Validación Completada!</p>
        </div>
     </div>  

  </div>
  <loading *ngIf="loading"></loading>