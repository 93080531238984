import { Component, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-pasivos-datos-personales',
  templateUrl: './pasivos-datos-personales.component.html',
  styleUrls: ['./pasivos-datos-personales.component.scss']
})
export class PasivosDatosPersonalesComponent {
  componenteActivo: string = 'datosGenerales';

  mostrarComponente(componente: string) {
    this.componenteActivo = componente;
  }

  retrocederComponente(componente: string) {
    this.componenteActivo = componente;
  }
}
