<img *ngIf="!esMovil" src="assets/04Pasivos.svg" class="stepper">
<img *ngIf="esMovil" src="assets/04PasivosMovil.svg" class="stepper">
<div class="layout-container">
  <div class="container-datos-cliente">
    <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
  </div>

  <div class="formulario-container">
    <div class="tipo-cuenta">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Moneda</span>
      </div>
      <div class="opciones-radio">
        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="currency" name="tipoCuenta" value="SOL" class="radio-input"
            (change)="changeDisbusemenmtMode('SOL');clearSelectOption();">
          <span class="radio-label">Soles S/</span>
        </label>      
        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="currency" name="tipoCuenta" value="USD" class="radio-input"
            (change)="changeDisbusemenmtMode('USD');clearSelectOption();">
          <span class="radio-label">Dólares USD $</span>
        </label>      
      </div>
    </div>

    <div class="tipo-cuenta">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Cuenta de Ahorros</span>
      </div>

      <div class="form-group-cuenta">
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="listFilterSavingsAccountType"
          [isReadonly]="elementsReadOnly.includes('typeSavingAccount')" [(selectedValue)]="typeSavingAccount"
          (selectedValueChange)="typeSavingAccount=$event" [isError]="isErrorTypeSavingAccount">
        </app-custom-inpunt-select>
      </div>
    </div>

    <!-- <div class="abono-cuenta-interbancaria">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Ejecutivo de Negocio</span>
      </div>

      <div class="form-group-cuenta">
        <app-custom-inpunt-select></app-custom-inpunt-select>
      </div>
    </div> -->

  </div>
  
  

    <!-- <div class="formulario-container">
      
    <div class="abono-cuenta">
        <div class="seleccion-indicador">
          <span class="punto"></span><span class="texto-seleccion">Moneda</span>
        </div>
        <div class="opciones-radio">
          <label class="opcion-radio">
            <input type="radio" name="tipoCuenta" value="nueva" class="radio-input" (change)="tipoCuentaSeleccionado='nueva'">
            <span class="radio-label">Soles S/</span>
          </label>
          
          <label class="opcion-radio">
            <input type="radio" name="tipoCuenta" value="existente" class="radio-input" (change)="tipoCuentaSeleccionado='existente'">
            <span class="radio-label">Dólares US $</span>
          </label>
          
        </div>
      </div>

      <div class="abono-cuenta-interbancaria">
        <div class="seleccion-indicador">
          <span class="punto"></span><span class="texto-seleccion">Cuenta de Ahorros</span>
        </div>

        <div class="form-group-cuenta">
          <label>Número de cuenta destino (CCI)</label>
          <app-custom-inpunt-select></app-custom-inpunt-select>
        </div>
        <br>
        <div class="form-group-cuenta">
          <label>Número de cuenta destino (CCI)</label>
          <app-custom-input></app-custom-input>
        </div>
      </div>   
    </div>     -->
</div>


<div class="container-buttons">
  <div class="footer-buttons">
    <a class="link-atras" (click)="backForm()">ATRÁS</a>
    <app-custom-button text="continuar" (action)="redireccionarResumen()"></app-custom-button>
  </div>
</div>

<app-adjunto-modal *ngIf="showModal" 
  (onClose)="handleClose()" 
  (onFileSelectedAction)="handleSelectFileAction($event)" 
  (onFileDownloadAction)="handleFileDownload($event)" 
  (onFileDeleteAction)="handleFileDelete($event)" 
  [applicationFiles]="filesList"
  [(value)]="comment"
  (onSave)="sendCommentHandler()">
</app-adjunto-modal>

<loading *ngIf="loading"></loading>