import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccessDeniedRoutingModule } from './access-denied-routing.module';
import { AccessDeniedComponent } from './access-denied.component';
import { CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, StepperComponent, CustomButtonComponent } from 'app/components';
import { SharedModule } from 'app/shared/modules/shared/shared.module';


@NgModule({
  declarations: [
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    AccessDeniedRoutingModule,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    SharedModule,
    CustomButtonComponent
  ]
})
export class AccessDeniedModule { }
