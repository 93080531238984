<div class="login-container">
    <div class="contenido-central">
        <h2 class="bienvenida-texto">Bienvenidos a la Plataforma de <br>Venta Asistida del banco</h2>
        <img img src="assets/logo.svg"  class="login-logo" alt="Logo del Banco">
    </div>
    <div class="login-form">
        <h3 class="form-title">Ingrese sus datos</h3>

        <div class="form-group">
            <label>Usuario</label>
            <app-custom-input-login  
                [(value)]="userInput" 
                [isError]="isErrorUser" 
                (keyup)="resetErrorState('userInput')" 
                ></app-custom-input-login>
        </div>
        <div class="form-group">
            <label>Contraseña</label>
            <app-custom-input-password-login  
                [(value)]="passwordInput" 
                [isErrorCustomInputLogin]="isErrorPass"
                (keyup)="resetErrorState('passwordInput')" 
                 ></app-custom-input-password-login >
        </div>


        <div class="recordar-container">
            <label>Recordar</label>
            
            <label class="switch">
                <input type="checkbox" id="recordar" name="recordar">
                <span class="slider round"></span>
            </label>
           
        </div>

        <div class="buttonLogin">
            <app-custom-button text="Ingresar" (action)="loginHandler()" [width]="'250px'" [height]="'50px'" ></app-custom-button>
        </div>
       
    </div>
</div>
<loading *ngIf="loading"></loading>