import { Component } from '@angular/core';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent {

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';

  loading: boolean = false;
  dateFromReport1: string = '';
  dateUntilReport1: string = '';
  dateFromReport2: string = '';
  dateUntilReport2: string = '';
  dateFromReport3: string = '';
  dateUntilReport3: string = '';

  constructor(
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private parameterService: ParameterService
  ) {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.dateFromReport1 = `${year}-${month}-01`;
    this.dateUntilReport1 = `${year}-${month}-${day}`;
    this.dateFromReport2 = `${year}-${month}-01`;
    this.dateUntilReport2 = `${year}-${month}-${day}`;
    this.dateFromReport3 = `${year}-${month}-01`;
    this.dateUntilReport3 = `${year}-${month}-${day}`;
  }

  seachReportApplication() {
    let model = {
      dateFrom: this.dateFromReport1,
      dateUntil: this.dateUntilReport1,
      userId: this.usuario
    };
    this.downloadReportApplication(model, `Solicitudes_${this.dateFromReport1}-${this.dateUntilReport1}.xlsx`);
  }

  seachReportTrama() {
    let model = {
      dateFrom: this.dateFromReport2,
      dateUntil: this.dateUntilReport2,
      userId: this.usuario
    };
    this.downloadReportDataLake(model, `Trama_${this.dateFromReport2}-${this.dateUntilReport2}.xlsx`);
  }

  downloadReportApplication(model: any, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadReportApplication(model).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });        
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        console.log(error);
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, error.message, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  downloadReportDataLake(model: any, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadReportTramaDatalake(model).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });        
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, error.message, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  downloadReportOptionalInsurances(model: any, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadReportOptionalInsurances(model).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });        
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, error.message, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  seachReportOptionalInsurance() {
    let model = {
      dateFrom: this.dateFromReport3,
      dateUntil: this.dateUntilReport3,
      userId: this.usuario
    };
    this.downloadReportOptionalInsurances(model, `SegurosOptativos_${this.dateFromReport3}-${this.dateUntilReport3}.xlsx`);    
  }

}
