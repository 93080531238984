export class ClienteSession {
    documentTypeDesciption!: string;
    documentType!: string;
    documentNumber!: string;
    fullName!: string;
    userCreation!: string;
    offersearchdate!: Date;
}

export class ProductoSession {
    image!: string;
    offerid!: string;
    campaignCode!: string;
    amount!: number;
    product!: string;
    subproductCode!: string;
    name!: string;
    nameBase!: string;
    validityStart!: string;
    validityEnd!: string;
    tea!: string;
    installments!: number;
    installmentsMax!: number;
    currency!: string;
    currencySymbol!: string;
    priority!: string;
    type!: string;
    offerProductCode!: string;
    offerSubProductCode!: string;
}