import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasivosDatosPersonalesComponent } from './pasivos-datos-personales.component';

const routes: Routes = [
  {path: '',component: PasivosDatosPersonalesComponent},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasivosDatosPersonalesRoutingModule { }
