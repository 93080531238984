import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-finalizacion',
  templateUrl: './finalizacion.component.html',
  styleUrls: ['./finalizacion.component.scss']
})
export class FinalizacionComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';

  finalMessage: string = '';
  loading: boolean = false;
  customer: any = {};
  imagePath: string = 'assets/send_application.svg';

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService) 
  {
    
  }

  async ngOnInit () {
    await this.getApplication(this.applicationId);
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.customer = response;
      this.finalMessage = this.customer.state == AppConstants.ApplicationStates.Finalizado ? `¡Tu solicitud ha sido aprobada de manera exitosa!` :
                          this.customer.state == AppConstants.ApplicationStates.PendienteAprobacion && this.customer.product === AppConstants.Products.Savings ? `¡Tu solicitud se envió correctamente para su futura aprobación!` : 
                          this.customer.state == AppConstants.ApplicationStates.PendienteAprobacion ? `¡Tu solicitud de préstamo se envió correctamente para su futura aprobación!` : 
                          this.customer.state == AppConstants.ApplicationStates.PendienteAutorizacion ? `¡Tu solicitud de préstamo se envió correctamente para su verificación (BackOffice)!` : '';
      this.imagePath = this.customer.state == AppConstants.ApplicationStates.Finalizado ? 'assets/img_finalizacion.svg' :
                       this.customer.state == AppConstants.ApplicationStates.PendienteAprobacion ? 'assets/send_application.svg' : 'assets/send_application.svg';
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }


  goHome() {
    this.router.navigate(['home']);
  }

 
}
