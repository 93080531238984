<div class="layout-container">

    <div *ngIf="!esMovil" class="formulario-container">
      <div class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="documents"
            [isReadonly]="elementsReadOnly.includes('tipoDocumento')" [(selectedValue)]="tipoDocumento"
            (selectedValueChange)="tipoDocumento=$event" [isError]="isErrorTipoDocumento">
          </app-custom-inpunt-select>
        </div>
  
        <div class="form-group">
          <label>Nro. de Documento</label>
          <app-custom-input [(value)]="nroDocInput" [isError]="isErrorNroDocInput"
            [isReadonly]="elementsReadOnly.includes('nroDocInput')"
            (keyup)="resetErrorState('nroDocInput')"></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Primer Nombre</label>
          <app-custom-input [(value)]="primerNombre" [isError]="isErrorPrimerNombre"
            [isReadonly]="elementsReadOnly.includes('primerNombre')"
            (keyup)="resetErrorState('primerNombre')" [minlength]="primerNombreValMin" [maxlength]="primerNombreValMax"></app-custom-input>
        </div>
  
      </div>
  
      <div class="form-row">
        <div class="form-group">
          <label>Segundo Nombre</label>
          <app-custom-input [(value)]="segundoNombre" [isError]="isErrorSegundoNombre"
            [isReadonly]="elementsReadOnly.includes('segundoNombre')" (keyup)="resetErrorState('segundoNombre')" [minlength]="segundoNombreValMin" [maxlength]="segundoNombreValMax"></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Apellido Paterno</label>
          <app-custom-input [(value)]="apellidoPaterno" [isError]="isErrorApellidoPaterno"
            [isReadonly]="elementsReadOnly.includes('apellidoPaterno')"
            (keyup)="resetErrorState('apellidoPaterno')"></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Apellido Materno</label>
          <app-custom-input [(value)]="apellidoMaterno" [isError]="isErrorApellidoMaterno"
            [isReadonly]="elementsReadOnly.includes('apellidoMaterno')"
            (keyup)="resetErrorState('apellidoMaterno')" [minlength]="apellidoMaternoValMin" [maxlength]="apellidoMaternoValMax"></app-custom-input>
        </div>
  
      </div>
      <div class="form-row">
        <div class="form-group">
          <label>Fecha de Nacimiento</label>
          <div class="custom-input-container">
            <app-custom-datepicker [(value)]="fechaNacimiento" [isError]="isErrorFechaNacimiento"
              (keyup)="resetErrorState('fechaNacimiento')" [isDisabled]="elementsReadOnly.includes('fechaNacimiento')" [validateYears]="true"></app-custom-datepicker>
          </div>
        </div>
  
        <div class="form-group">
          <label>Nacionalidad</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="nacionalidad"
            [isReadonly]="elementsReadOnly.includes('nacionalidad')" [isError]="isErrorNationality" [width]="'190px'"
            [options]="opcionesPaisDomicilio" (selectedValueChange)="nacionalidad=$event">
          </app-custom-inpunt-select>
        </div>
  
        <div class="form-group">
          <label>Género</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="genero"
            [isReadonly]="elementsReadOnly.includes('genero')" [isError]="isErrorGenero" [width]="'190px'"
            [options]="opcionesGenero" (selectedValueChange)="genero=$event">
          </app-custom-inpunt-select>
        </div>
  
      </div>
      <div class="form-row">
        <div class="form-group">
          <label>País de Domicilio</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="countryDomicile" (selectedValueChange)="countryDomicile=$event" [isReadonly]="elementsReadOnly.includes('countryDomicile')"
            [isError]="isErrorCountryDomicile" [width]="'190px'" [options]="opcionesPaisDomicilio">
          </app-custom-inpunt-select>
        </div>
        <div class="form-group">
            <label>Nivel de Educación</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="nivelEducacion" (selectedValueChange)="nivelEducacion=$event" [isReadonly]="elementsReadOnly.includes('nivelEducacion')"
            [isError]="isErrorNivelEducacion" [width]="'190px'" [options]="opcionesNivelEducacion"> 
            </app-custom-inpunt-select>
        </div>
    
        <div class="form-group">
            <label>Profesión</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="profesion" (selectedValueChange)="profesion=$event" [isReadonly]="elementsReadOnly.includes('profesion')"
            [isError]="isErrorProfesion" [width]="'190px'" [options]="opcionesProfesion" >
                </app-custom-inpunt-select>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group-estado-civil">
          <label>Estado Civil</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="estadoCivil" [isReadonly]="elementsReadOnly.includes('estadoCivil')"
          [isError]="isErrorEstadoCivil" [width]="'190px'" [options]="opcionesEstadoCivil" 
          (selectedValueChange)="handleSelectionChange($event, 'isErrorEstadoCivil')">
        </app-custom-inpunt-select>
        </div>
        <div class="form-group-estado-civil">
            <label>Cargo Ocupación</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="cargoOcupacion" [isReadonly]="elementsReadOnly.includes('cargoOcupacion')"
            [isError]="isErrorCargoOcupacion" [width]="'190px'" [options]="opcionesCargoOcupacion" >
            </app-custom-inpunt-select>
        </div>
      </div>
  
      <div *ngIf="showDatosConyugue" class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [isReadonly]="elementsReadOnly.includes('conyugueDocumentType')"
          [(selectedValue)]="conyugueDocumentType"  (selectedValueChange)="conyugueDocumentType=$event" [isError]="isErrorDocumenTypeSpouse">
          </app-custom-inpunt-select>
        </div>
        <div class="form-group">
          <label>N° de documento</label>
          <app-custom-input [(value)]="conyugueDocumentNumber" [isError]="isErrorDocumenNumberSpouse" [isReadonly]="elementsReadOnly.includes('conyugueDocumentNumber')"
          (keyup)="resetErrorState('conyugueDocumentNumber')" ></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Nombres</label>
          <app-custom-input [(value)]="conyugueNames" [isError]="isErrorSpouseNames" [isReadonly]="elementsReadOnly.includes('conyugueNames')"
          (keyup)="resetErrorState('conyugueNames')" ></app-custom-input>
        </div>
      </div>
      <div *ngIf="showDatosConyugue" class="form-row">
  
        <div class="form-group">
          <label>Apellido Paterno</label>
          <app-custom-input [(value)]="conyugueLastName" [isError]="isErrorSpouseLastName" [isReadonly]="elementsReadOnly.includes('conyugueLastName')"
          (keyup)="resetErrorState('conyugueLastName')" ></app-custom-input>
        </div>
        <div class="form-group">
          <label>Apellido Materno</label>
          <app-custom-input [(value)]="conyugueSecondLastName" [isError]="isErrorSpouseSecondLastName" [isReadonly]="elementsReadOnly.includes('conyugueSecondLastName')"
          (keyup)="resetErrorState('conyugueSecondLastName')" ></app-custom-input>
        </div>
      </div>
    </div>
  
    <div *ngIf="esMovil" class="formulario-container">
  
      <div class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="documents"
            [isReadonly]="elementsReadOnly.includes('tipoDocumento')" [(selectedValue)]="tipoDocumento"
            (selectedValueChange)="tipoDocumento=$event" [isError]="isErrorTipoDocumento">
          </app-custom-inpunt-select>
        </div>
  
        <div class="form-group">
          <label>Nro. de Documento</label>
          <app-custom-input [(value)]="nroDocInput" [isError]="isErrorNroDocInput"
            [isReadonly]="elementsReadOnly.includes('nroDocInput')"
            (keyup)="resetErrorState('nroDocInput')"></app-custom-input>
        </div>
  
  
      </div>
  
      <div class="form-row">
  
        <div class="form-group">
          <label>Primer Nombre</label>
          <app-custom-input [(value)]="primerNombre" [isError]="isErrorPrimerNombre"
            [isReadonly]="elementsReadOnly.includes('primerNombre')"
            (keyup)="resetErrorState('primerNombre')" [minlength]="primerNombreValMin" [maxlength]="primerNombreValMax"></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Segundo Nombre</label>
          <app-custom-input [(value)]="segundoNombre" [isError]="isErrorSegundoNombre"
            [isReadonly]="elementsReadOnly.includes('segundoNombre')" (keyup)="resetErrorState('segundoNombre')" [minlength]="segundoNombreValMin" [maxlength]="segundoNombreValMax"></app-custom-input>
        </div>
  
      </div>
  
      <div class="form-row">
        <div class="form-group">
          <label>Apellido Paterno</label>
          <app-custom-input [(value)]="apellidoPaterno" [isError]="isErrorApellidoPaterno"
            [isReadonly]="elementsReadOnly.includes('apellidoPaterno')"
            (keyup)="resetErrorState('apellidoPaterno')" [minlength]="apellidoPaternoValMin" [maxlength]="apellidoPaternoValMax"></app-custom-input>
        </div>
  
        <div class="form-group">
          <label>Apellido Materno</label>
          <app-custom-input [(value)]="apellidoMaterno" [isError]="isErrorApellidoMaterno"
            [isReadonly]="elementsReadOnly.includes('apellidoMaterno')"
            (keyup)="resetErrorState('apellidoMaterno')" [minlength]="apellidoMaternoValMin" [maxlength]="apellidoMaternoValMax"></app-custom-input>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group">
          <label>Fecha de Nacimiento</label>
          <div class="custom-input-container">
            <app-custom-datepicker [(value)]="fechaNacimiento" [isError]="isErrorFechaNacimiento"
              (keyup)="resetErrorState('fechaNacimiento')" [isDisabled]="elementsReadOnly.includes('fechaNacimiento')" [validateYears]="true"></app-custom-datepicker>
          </div>
        </div>
  
        <div class="form-group">
          <label>Nacionalidad</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="nacionalidad"
            [isReadonly]="elementsReadOnly.includes('nacionalidad')" [isError]="isErrorNationality" [width]="'190px'"
            [options]="opcionesPaisDomicilio" (selectedValueChange)="nacionalidad=$event">
          </app-custom-inpunt-select>
        </div>
  
  
      </div>
  
  
      <div class="form-row">
        <div class="form-group">
          <label>Género</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="genero"
            [isReadonly]="elementsReadOnly.includes('genero')" [isError]="isErrorGenero" [width]="'190px'"
            [options]="opcionesGenero" (selectedValueChange)="genero=$event">
          </app-custom-inpunt-select>
        </div>
        <div class="form-group">
          <label>País de Domicilio</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="countryDomicile" (selectedValueChange)="countryDomicile=$event" [isReadonly]="elementsReadOnly.includes('countryDomicile')"
            [isError]="isErrorCountryDomicile" [width]="'190px'" [options]="opcionesPaisDomicilio">
          </app-custom-inpunt-select>
        </div>
  
  
      </div>
  
      <div class="form-row">
        <div class="form-group">
            <label>Nivel de Educación</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="nivelEducacion" (selectedValueChange)="nivelEducacion=$event" [isReadonly]="elementsReadOnly.includes('nivelEducacion')"
            [isError]="isErrorNivelEducacion" [width]="'190px'" [options]="opcionesNivelEducacion"> 
            </app-custom-inpunt-select>
        </div>
  
        <div class="form-group">
            <label>Profesión</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="profesion" (selectedValueChange)="profesion=$event" [isReadonly]="elementsReadOnly.includes('profesion')"
            [isError]="isErrorProfesion" [width]="'190px'" [options]="opcionesProfesion" >
                </app-custom-inpunt-select>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group">
          <label>Estado Civil</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="estadoCivil" [isReadonly]="elementsReadOnly.includes('estadoCivil')"
          [isError]="isErrorEstadoCivil" [width]="'190px'" [options]="opcionesEstadoCivil" 
          (selectedValueChange)="handleSelectionChange($event, 'isErrorEstadoCivil')">
        </app-custom-inpunt-select>
        </div>
        <div class="form-group">
            <label>Cargo Ocupación</label>
            <app-custom-inpunt-select placeholder="Seleccionar" [(selectedValue)]="cargoOcupacion" [isReadonly]="elementsReadOnly.includes('cargoOcupacion')"
            [isError]="isErrorCargoOcupacion" [width]="'190px'" [options]="opcionesCargoOcupacion" >
            </app-custom-inpunt-select>
        </div>
      </div>
      
      <div *ngIf="showDatosConyugue" class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [isReadonly]="elementsReadOnly.includes('conyugueDocumentType')"
          [(selectedValue)]="conyugueDocumentType"  (selectedValueChange)="conyugueDocumentType=$event" [isError]="isErrorDocumenTypeSpouse">
          </app-custom-inpunt-select>
        </div>
        <div class="form-group">
          <label>N° de documento</label>
          <app-custom-input [(value)]="conyugueDocumentNumber" [isError]="isErrorDocumenNumberSpouse" [isReadonly]="elementsReadOnly.includes('conyugueDocumentNumber')"
          (keyup)="resetErrorState('conyugueDocumentNumber')" ></app-custom-input>
        </div>
  
      </div>
      <div *ngIf="showDatosConyugue" class="form-row">
  
        <div class="form-group">
          <label>Nombres</label>
          <app-custom-input [(value)]="conyugueNames" [isError]="isErrorSpouseNames" [isReadonly]="elementsReadOnly.includes('conyugueNames')"
          (keyup)="resetErrorState('conyugueNames')" ></app-custom-input>
        </div>
        <div class="form-group">
          <label>Apellido Paterno</label>
          <app-custom-input [(value)]="conyugueLastName" [isError]="isErrorSpouseLastName" [isReadonly]="elementsReadOnly.includes('conyugueLastName')"
          (keyup)="resetErrorState('conyugueLastName')" ></app-custom-input>
        </div>
      </div>
  
      <div *ngIf="showDatosConyugue" class="form-row">
  
        <div class="form-group">
          <label>Apellido Materno</label>
          <app-custom-input [(value)]="conyugueSecondLastName" [isError]="isErrorSpouseSecondLastName" [isReadonly]="elementsReadOnly.includes('conyugueSecondLastName')"
          (keyup)="resetErrorState('conyugueSecondLastName')" ></app-custom-input>
        </div>
      </div>
  
    </div>
  </div>
  
  <div *ngIf="onlyView" class="footer-button">
    <div style="display: flex; justify-content: center;">
      <app-custom-button text="CERRAR" (action)="close()"></app-custom-button>
    </div>  
  </div>
  
  <div *ngIf="!onlyView" class="footer-buttons">
      <app-custom-button-link text="CANCELAR" (action)="close()"></app-custom-button-link>
      <app-custom-button text="GUARDAR" (action)="validarYCambiarComponente()"></app-custom-button>
  </div>
  <loading *ngIf="loading"></loading>