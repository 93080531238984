<app-header [headerText]="'Inicio de autenticación'"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> {{ applicationId }}</span>
  </div>
</div>

<div class="layout-container">

  <app-custom-tab [tabs]="myTabs">
    <div *ngIf="myTabs[0].active">
      <app-validacion-facial (validacionCompletada)="markTabAsCompleted(0)"></app-validacion-facial>
    </div>
    <div *ngIf="myTabs[1].active">
      <app-validacion-correo (validacionCompletadaCorreo)="markTabAsCompleted(1)"></app-validacion-correo>
    </div>
  </app-custom-tab>
  
</div>

<div class="contenedor-botones">
  <div class="form-flex-container">
    <div class="container-atras">
      <!-- <app-custom-button-color text="Atrás"></app-custom-button-color> -->
    </div>
    <div class="container-continuar">
      <app-custom-button text="Continuar" [isDisabled]="botonDeshabilitado"  (action)="nextStepApplication()"> </app-custom-button>
    </div>
  </div>

</div>

<app-modal-resumen *ngIf="showModalResumen" [width]="450" [height]="430" (onClose)="handleCloseResumen ()"
           [titleText]="tituloModal" >
</app-modal-resumen>

<app-footer></app-footer>

<loading *ngIf="loading"></loading>