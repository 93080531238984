import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pasivos-validacion',
  templateUrl: './pasivos-validacion.component.html',
  styleUrls: ['./pasivos-validacion.component.scss']
})
export class PasivosValidacionComponent {
  
  constructor(private router: Router) { }

  validacionCorreo(){
    this.router.navigate(['pasivos-validacion-correo']);
  }

  fingerprintAuthentication() {
    this.router.navigate(['pasivos-validacion-biometrica']);
  }

  faceIdAuthentication() {
    this.router.navigate(['pasivos-validacion-biometrica']);
  }
 
}
