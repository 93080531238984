import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasivosAutenticacionRoutingModule } from './pasivos-autenticacion-routing.module';
import { PasivosAutenticacionComponent } from './pasivos-autenticacion.component';
import { CustomButtonComponent, CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, StepperComponent, ModalResumenComponent } from 'app/components';
import { PasivosValidacionFacialComponent } from './components/pasivos-validacion-facial/pasivos-validacion-facial.component';
import { PasivosValidacionDactilarComponent } from './components/pasivos-validacion-dactilar/pasivos-validacion-dactilar.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';


@NgModule({
  declarations: [  
    PasivosAutenticacionComponent, 
    PasivosValidacionFacialComponent, 
    PasivosValidacionDactilarComponent
  ],
  imports: [
    CommonModule,
    PasivosAutenticacionRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    ModalResumenComponent,
    SharedModule
  ]
})
export class PasivosAutenticacionModule { }
