import { ChangeDetectorRef, Component, ViewChild,Output,EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ParameterService } from 'app/services/parameter.service';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-generales-modal-bandeja',
  templateUrl: './datos-generales-modal-bandeja.component.html',
  styleUrls: ['./datos-generales-modal-bandeja.component.scss']
})
export class DatosGeneralesModalBandejaComponent {

  customerApplication: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER_TRAY) ?? '{}');
  // applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  // workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';  
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  esMovil: boolean = false;

  opcionesGenero = [
    { label: 'MASCULINO', value: 'M' },
    { label: 'FEMENINO', value: 'F' }
  ];

  opcionesEstadoCivil: any = [];
  opcionesPaisDomicilio: any = [];
  opcionesRelacionLaboral: any = [];
  opcionesTipoVienda: any = [];
  documents: any = [];
  elementsReadOnly: any = [];


  loading: boolean = false;

  showDatosConyugue = false;

  application: any = {};

  //variables formulario
  tipoDocumento: string = '';
  nroDocInput: string = '';
  primerNombre: string = '';
  segundoNombre: string = '';
  apellidoPaterno: string = '';
  apellidoMaterno: string = '';
  fechaNacimiento: string = '';
  nacionalidad: string = '';
  genero: string ='';
  countryDomicile: string = '';
  housingType: string = '';
  employmentRelationship: string = '';
  estadoCivil: string = '';
  cellphone: string = '';
  conyugueDocumentType: string = '';
  conyugueDocumentNumber: string = '';
  conyugueNames: string = '';
  conyugueLastName: string = '';
  conyugueSecondLastName: string = '';
  email: string = '';

  //Validators
  isErrorTipoDocumento: boolean = false;
  isErrorNroDocInput: boolean = false;
  isErrorPrimerNombre: boolean = false;  
  isErrorSegundoNombre: boolean = false; 
  isErrorApellidoPaterno: boolean = false;
  isErrorApellidoMaterno: boolean = false;
  isErrorFechaNacimiento: boolean = false;
  isErrorNationality: boolean = false;
  isErrorGenero: boolean = false;
  isErrorEstadoCivil: boolean = false;
  isErrorCountryDomicile: boolean = false;
  isErrorHousingType: boolean = false;
  isErrorEmploymentRelationship: boolean = false;
  isErrorCellphone: boolean = false;
  isErrorEmail: boolean = false;

  isErrorDocumenTypeSpouse: boolean = false;
  isErrorDocumenNumberSpouse: boolean = false;
  isErrorSpouseNames: boolean = false;
  isErrorSpouseLastName: boolean = false;
  isErrorSpouseSecondLastName: boolean = false;

  @Output() onCancel = new EventEmitter<void>();
  @HostListener('window:resize', ['$event'])
  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  typeInputNumber: string = 'tel';
  max: number = 9;

  primerNombreValMin: number = 3;
  primerNombreValMax: number = 30;
  segundoNombreValMin: number = 0;
  segundoNombreValMax: number = 30;
  apellidoPaternoValMin: number = 3;
  apellidoPaternoValMax: number = 30;
  apellidoMaternoValMin: number = 3;
  apellidoMaternoValMax: number = 30;
  emailValMax: number = 40;

  @Input() onlyView: boolean = true;

  constructor(
    private router: Router,  
    private cdr: ChangeDetectorRef,
    private parameterService: ParameterService,
    private alertService: AlertService,
    private applicationService: ApplicationService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    console.log('onlyView: ', this.onlyView);
    this.lockedElemtns();
    await this.getParameters([
      AppConstants.ParameterCode.DOCUMENT_TYPE,
      AppConstants.ParameterCode.NACIONALITY,
      AppConstants.ParameterCode.MARITAL_STATUS,
      AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP,
      AppConstants.ParameterCode.ELEMENTS_BLOCKED,
      AppConstants.ParameterCode.HOUSING_SITUATION
    ]);
    await this.getApplication(this.customerApplication.applicationId);
  }

  close() {
    this.onClose.emit(); 
  }

  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    
  }

  cancelar(event: Event): void {
    event.preventDefault();

    this.onCancel.emit(); 
  }

  validarYCambiarComponente() {

    //Validaciones
    this.isErrorTipoDocumento = !this.tipoDocumento;
    this.isErrorNroDocInput = !this.nroDocInput;
    this.isErrorPrimerNombre = this.primerNombre == null ? true : (this.primerNombre.length >= this.primerNombreValMin ? false : true);   
    this.isErrorSegundoNombre = this.segundoNombreValMin === 0 ? false : (this.segundoNombre.length >= this.segundoNombreValMin ? false : true);
    this.isErrorApellidoPaterno = this.apellidoPaterno == null ? true : (this.apellidoPaterno.length >= this.apellidoPaternoValMin ? false : true);
    this.isErrorApellidoMaterno = this.apellidoMaterno == null ? true : (this.apellidoMaterno.length >= this.apellidoMaternoValMin ? false : true);
    this.isErrorFechaNacimiento = !this.validateYearAndAge(this.fechaNacimiento);
    this.isErrorNationality = !this.nacionalidad;
    this.isErrorGenero = !this.genero;
    this.isErrorEstadoCivil = !this.estadoCivil;
    this.isErrorCountryDomicile = !this.countryDomicile;
    this.isErrorHousingType = !this.housingType;
    this.isErrorEmploymentRelationship = !this.employmentRelationship;
    this.isErrorCellphone = this.cellphone == null ? true : (this.cellphone.length === this.max ? !this.cellphone : true);
    this.isErrorEmail = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email);

    if(this.showDatosConyugue) {
      this.isErrorDocumenTypeSpouse = !this.conyugueDocumentType;
      this.isErrorDocumenNumberSpouse = !this.conyugueDocumentNumber;
      this.isErrorSpouseNames = !this.conyugueNames;
      this.isErrorSpouseLastName = !this.conyugueLastName;
      this.isErrorSpouseSecondLastName = !this.conyugueSecondLastName;
    } 
    else {
      this.isErrorDocumenTypeSpouse = false;
      this.isErrorDocumenNumberSpouse = false;
      this.isErrorSpouseNames = false;
      this.isErrorSpouseLastName = false;
      this.isErrorSpouseSecondLastName = false;
    }    
  
    // Comprobamos si algún campo tiene error
    const requiredElements = [
      this.isErrorTipoDocumento,
      this.isErrorNroDocInput,
      this.isErrorPrimerNombre,
      this.isErrorSegundoNombre,
      this.isErrorApellidoPaterno,
      this.isErrorApellidoMaterno,
      this.isErrorFechaNacimiento,
      this.isErrorNationality,
      this.isErrorGenero,
      this.isErrorEstadoCivil,
      this.isErrorCountryDomicile,
      this.isErrorHousingType,
      this.isErrorEmploymentRelationship,
      this.isErrorCellphone,
      this.isErrorEmail
    ];

    const requiredElementsConditional = [
      this.isErrorDocumenTypeSpouse,
      this.isErrorDocumenNumberSpouse,
      this.isErrorSpouseNames,
      this.isErrorSpouseLastName,
      this.isErrorSpouseSecondLastName
    ];

    if (requiredElements.every(element => !element) 
      && (this.showDatosConyugue ? requiredElementsConditional.every(element => !element) : true)) {
    
      let model = {
        documentType: this.tipoDocumento,
        documentNumber: this.nroDocInput,
        name: this.primerNombre,
        middlename: this.segundoNombre,
        lastname: this.apellidoPaterno,
        secondLastname: this.apellidoMaterno,
        birthDate: this.fechaNacimiento,
        citizenship: this.nacionalidad,
        gender: this.genero,
        countryDomicile: this.countryDomicile,
        housingType: this.housingType,
        employmentRelationShip: this.employmentRelationship,
        maritalStatus: this.estadoCivil,
        email: this.email,
        cellphone: this.cellphone,
        documentTypeSpouse : this.showDatosConyugue === true ? this.conyugueDocumentType : null,
        documentNumberSpouse : this.showDatosConyugue === true ? this.conyugueDocumentNumber : null,
        spouseNames : this.showDatosConyugue === true ? this.conyugueNames : null,
        spouseLastName : this.showDatosConyugue === true ? this.conyugueLastName : null,
        spouseSecondLastName : this.showDatosConyugue === true ? this.conyugueSecondLastName : null,
      };
      
      this.updateApplication({ 
        applicationId: this.customerApplication.applicationId,
        workflowInstanceId : this.customerApplication.workflowInstanceId,
        taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
        updatedBy: this.usuario,
        updatedByFullName: this.userName,
        personalInformation: model 
      });

      
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }

  }

  resetErrorState(inputType: string) {
    if (inputType === 'nroDocInput') {
      this.isErrorNroDocInput = this.nroDocInput.length === 0;  
    }else   if (inputType === 'primerNombre') {
      this.isErrorPrimerNombre = this.primerNombre === undefined || this.primerNombre === null  ? true : (this.primerNombre.length >= this.primerNombreValMin ? false : true); 
    }else   if (inputType === 'segundoNombre') {
      if(this.segundoNombre != undefined || this.segundoNombre != null) {
        this.segundoNombre.length > 0 ? this.segundoNombreValMin = 3 : this.segundoNombreValMin = 0;        
      }
      this.isErrorSegundoNombre = this.segundoNombreValMin === 0 ? false : (this.segundoNombre.length >= this.segundoNombreValMin ? false : true);
    }else   if (inputType === 'apellidoPaterno') {
      this.isErrorApellidoPaterno = this.apellidoPaterno === undefined || this.apellidoPaterno === null  ? true : (this.apellidoPaterno.length >= this.apellidoPaternoValMin ? false : true);
    }else   if (inputType === 'apellidoMaterno') {
      this.isErrorApellidoMaterno = this.apellidoMaterno === undefined || this.apellidoMaterno === null  ? true : (this.apellidoMaterno.length >= this.apellidoMaternoValMin ? false : true);
    }else   if (inputType === 'nacionalidad') {
      this.isErrorNationality = this.nacionalidad.length === 0;  
    }else if (inputType === 'fechaNacimiento') {
      this.isErrorFechaNacimiento = !this.validateYearAndAge(this.fechaNacimiento);  
    }
  }

  errorStates: { [key: string]: boolean } = {
    isErrorEstadoCivil: false
  };



  handleSelectionChange(value: string, errorField: string) {
    if (value && this.errorStates.hasOwnProperty(errorField)) {
      this.errorStates[errorField] = false; // Actualiza el estado de error específico a false si hay un valor seleccionado.
    }

    this.showDatosConyugue = value === AppConstants.MaritalStatus.MARRIED;
    this.estadoCivil = value;
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    this.documents = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DOCUMENT_TYPE && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 }));
  

    let nationality = listParameters
        .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.NACIONALITY && f.state === AppConstants.StateFlag.Active)
        .sort((a: any, b: any) => {
          if (a.parameterDetCode004 !== null && b.parameterDetCode004 !== null) {
            if (a.parameterDetCode004 < b.parameterDetCode004) return -1;
            if (a.parameterDetCode004 > b.parameterDetCode004) return 1;
          } else if (a.parameterDetCode004 === null && b.parameterDetCode004 !== null) {
            return 1;
          } else if (a.parameterDetCode004 !== null && b.parameterDetCode004 === null) {
            return -1;
          }
          // Si ambos son nulos o iguales en parameterDetCode004, ordenar por parameterDetCode002
          if (a.parameterDetCode002 < b.parameterDetCode002) return -1;
          if (a.parameterDetCode002 > b.parameterDetCode002) return 1;
          return 0;
        });

    nationality.forEach((element: any) => {
      this.opcionesPaisDomicilio.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
    });

    this.opcionesEstadoCivil = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.MARITAL_STATUS && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesRelacionLaboral = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    if(this.onlyView == false) {
      this.elementsReadOnly = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.ELEMENTS_BLOCKED && f.state === AppConstants.StateFlag.Active && f.parameterDetCode003 === AppConstants.StateFlag.Active)
      .map((element: any) => element.parameterDetCode002);            
    }
    
    this.opcionesTipoVienda = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.HOUSING_SITUATION && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  updateApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.close();
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    this.tipoDocumento = model.documentType == null ? '' : model.documentType;
    this.nroDocInput = model.documentNumber;
    this.primerNombre = model.name;
    this.segundoNombre = model.middleName;
    this.apellidoPaterno = model.lastname;
    this.apellidoMaterno = model.secondLastname;
    this.fechaNacimiento = model.birthDate == null || model.birthDate == '' ? '' : model.birthDate.slice(0, 10);
    this.nacionalidad = model.citizenship == null ? '' : model.citizenship;
    this.genero = model.sex == null ? '' : model.sex;
    this.countryDomicile = model.countryCode == null ? '' : model.countryCode;
    this.housingType = model.typehousingCode == null ? '' : model.typehousingCode;
    this.employmentRelationship = model.jobTypeCode;
    this.estadoCivil = model.maritalStatus == null ? '' : model.maritalStatus;
    this.cellphone = model.personalCellPhoneNumber;
    this.conyugueDocumentType = model.documentTypeSpouse == null ? '' : model.documentTypeSpouse;
    this.conyugueDocumentNumber = model.documentNumberSpouse;
    this.conyugueNames = model.spouseNames;
    this.conyugueLastName = model.spouseLastname;
    this.conyugueSecondLastName = model.spouseSecondLastname;
    this.email = model.emailAddress;

    if(this.estadoCivil != null && this.estadoCivil != ''){
      this.handleSelectionChange(this.estadoCivil, 'isErrorEstadoCivil');
    }
  }

  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }

  lockedElemtns() {
    this.elementsReadOnly = [
      'tipoDocumento',
      'nroDocInput',
      'primerNombre',
      'segundoNombre',
      'apellidoPaterno',
      'apellidoMaterno',
      'fechaNacimiento',
      'nacionalidad',
      'genero',
      'countryDomicile',
      'housingType',
      'employmentRelationship',
      'estadoCivil',
      'cellphone',
      'email',
      'conyugueDocumentType',
      'conyugueDocumentNumber',
      'conyugueNames',
      'conyugueLastName',
      'conyugueSecondLastName'
    ];
  }

  validateYearAndAge(date: string): boolean {
    try {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(date)) {
        return false;
      }

      const [year, month, day] = date.split('-').map(num => parseInt(num, 10));

      if (year <= 1900) {
        return false;
      }

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      let age = currentYear - year;

      if (currentMonth < month || (currentMonth === month && currentDay < day)) {
        age--;
      }

      if (age < 18) {
        return false;
      }

      return true;
  
    } catch (error) {
      console.error("Error al validar la fecha:", error);
      return false;
    }
  }
  
}
