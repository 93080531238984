import { Component, EventEmitter, Output } from '@angular/core';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { AppConstants } from 'app/shared/constants/app-constants';

@Component({
  selector: 'app-datos-cliente',
  templateUrl: './datos-cliente.component.html',
  styleUrls: ['./datos-cliente.component.scss']
})
export class DatosClienteComponent {
  @Output() abrirModal = new EventEmitter<void>(); 

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  simulation: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.SIMULACION) ?? '{}');
  
  loading: boolean = false;
  
  abrirModalEvento() {
    this.abrirModal.emit(); // Emite el evento.
  }
}
