<div class="modal-overlay">
  <div class="modal-content"[ngStyle]="{'width.px': width, 'height.px': 'auto'}">
    <div class="modal-header">
      <app-custom-button-border  
        iconUrl=".././assets/adjuntos.svg" 
        (action)="fileInput.click()"
        text="Subir">
      </app-custom-button-border>
      <input
        type="file"
        #fileInput
        style="display: none"
        (change)="handleSelectFileAction($event)"
      />
    </div>
    <div class="modal-body">

      <p>Archivos Adjuntos</p>

      <div *ngFor="let file of filesList" class="file-label">
        <label>{{ file.name }}</label>
        <span class="icon-container">
            <img src=".././assets/descargar.svg" class="download-icon" (click)="handleFileDownload(file)">
            <img src=".././assets/eliminar.svg" class="delete-icon" (click)="handleFileDelete(file)">
        </span>
      </div>
      
    </div>
    <div class="modal-body">
      <p>Dejar comentario</p>
      <textarea class="custom-input"  
            [(ngModel)]="comment">
      </textarea>
    </div>


    <div class="modal-footer">
      <!-- <app-custom-button-link text="CERRAR" (action)="close()"></app-custom-button-link> -->
      <app-custom-button-color text="CERRAR" (action)="close()"></app-custom-button-color>
      <app-custom-button text="Guardar" (action)="sendCommentHandler()"></app-custom-button>
    </div>
  </div>
</div>
<loading *ngIf="loading"></loading>