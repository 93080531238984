import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminEmpresasAccionesComponent } from './admin-empresas-acciones.component';

const routes: Routes = [
  {path: '',component: AdminEmpresasAccionesComponent},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminEmpresasAccionesRoutingModule { }
