import { ChangeDetectorRef, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { HelperService } from 'app/shared/services/helper.service';
import * as FileSaver from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-direccion',
  templateUrl: './datos-direccion.component.html',
  styleUrls: ['./datos-direccion.component.scss']
})
export class DatosDireccionComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  application: any = {};

  esMovil: boolean = window.innerWidth <= 768;

  // Variables formulario
  tipoVia: string = '';
  nombreVia: string = '';
  numero: string = '';
  interior: string = '';
  urbanizacion: string = '';
  referencia: string = '';
  departamento: string = '';
  provincia: string = '';
  distrito: string = '';

  // Validators
  isErrorTipoVia: boolean = false;
  isErrorNombreVia: boolean = false;
  isErrorNumero: boolean = false;
  isErrorInterior: boolean = false;
  isErrorUrbanizacion: boolean = false;
  isErrorReferencia: boolean = false;
  isErrorDepartamento: boolean = false;
  isErrorProvincia: boolean = false;
  isErrorDistrito: boolean = false;

  opcionesTipoVia: any = [];
  opcionesDepartamento: any = [];
  opcionesProvincia: any = [];
  opcionesDistrito: any = [];

  loading: boolean = false;
  elementsReadOnly: any = [];
  arrayProvinces: any = [];
  arrayDistricts: any = [];
  typeInputNumber: string = 'tel';
  max: number = 8;

  filesList: any = [];
  sizeFile: any = [];
  fileTemp!: File;
  showModal = false;
  comment: string = '';

  nombreViaValMin: number = 3;
  nombreViaValMax: number = 30;
  numeroValMin: number = 1;
  numeroValMax: number = 5;
  interiorValMin: number = 0;
  interiorValMax: number = 5;
  urbanizacionValMin: number = 0;
  urbanizacionValMax: number = 35;
  referenciaValMin: number = 1;
  referenciaValMax: number = 100;

  
  @Output() cambiarComponente = new EventEmitter<string>();

  constructor(
    private cdr: ChangeDetectorRef,
    private parameterService: ParameterService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.VIA_TYPE,
      AppConstants.ParameterCode.DEPARTAMENT,
      AppConstants.ParameterCode.PROVINCE,
      AppConstants.ParameterCode.DISTRIC,
      AppConstants.ParameterCode.SIZE_FILES
    ]);
    await this.getApplication(this.applicationId);
    await this.getAttachmentFiles(this.applicationId);
    // this.dataMockup();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async openAttachmentModal() {
    this.showModal = true;
    await this.retrieveApplicationComments(this.applicationId);
  }

  irAtras() {
    this.cambiarComponente.emit('datosGenerales');
  }

  validarYCambiarComponente() {
    // Validaciones para cada campo
    this.isErrorTipoVia = !this.tipoVia;
    this.isErrorNombreVia = this.nombreVia == null ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);
    this.isErrorNumero = this.numero == null ? true : (this.numero.length >= this.numeroValMin ? false : true);
    this.isErrorReferencia = this.referencia == null ? true : (this.referencia.length >= this.referenciaValMin ? false : true);
    this.isErrorDepartamento = this.departamento === '' ? true : false;
    this.isErrorProvincia = this.provincia === '' ? true : false;
    this.isErrorDistrito = this.distrito === '' ? true : false;

    const requiredElements = [
      this.isErrorTipoVia,
      this.isErrorNombreVia,
      this.isErrorNumero,
      this.isErrorReferencia,
      this.isErrorDepartamento,
      this.isErrorProvincia,
      this.isErrorDistrito
    ];

    if (requiredElements.every(element => !element)) {

      let model = {
        streetType: this.tipoVia,
        streetName: this.nombreVia,
        streetNumber: this.numero,
        inside: this.interior,
        urbanization: this.urbanizacion,
        reference: this.referencia,
        department: this.departamento,
        province: this.provincia,
        district: this.distrito
      };

      // console.log(JSON.stringify(model));
      this.updateApplication({ 
        applicationId: this.applicationId,
        workflowInstanceId : this.workflowId,
        taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
        updatedBy: this.usuario,
        updatedByFullName: this.userName,
        address: model 
      });       
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }
  }

  resetErrorState(inputType: string) {
    if (inputType === 'nombreVia') {
      this.isErrorNombreVia = this.nombreVia === undefined || this.nombreVia === null  ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);  
    }else   if (inputType === 'numero') {
      this.isErrorNumero = this.numero === undefined || this.numero === null  ? true : (this.numero.length >= this.numeroValMin ? false : true);  
    }else   if (inputType === 'referencia') {
      this.isErrorReferencia = this.referencia === undefined || this.referencia === null  ? true : (this.referencia.length >= this.referenciaValMin ? false : true);  
    }
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    this.opcionesTipoVia = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.VIA_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }))

    this.opcionesDepartamento = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DEPARTAMENT && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }))

    this.arrayProvinces = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PROVINCE && f.state === AppConstants.StateFlag.Active);

    this.arrayDistricts = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DISTRIC && f.state === AppConstants.StateFlag.Active);

    this.sizeFile = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SIZE_FILES && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));

  }

  getProvinces(event: any) {
    this.provincia = '';
    this.distrito = '';
    this.opcionesDistrito = [];
    this.opcionesProvincia = this.arrayProvinces
      .filter((f: any) => f.parameterDetCode006 === event.value)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  getDistricts(event: any) {
    this.distrito = '';
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === event.value && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  updateApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.cambiarComponente.emit('siguienteComponente');
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    this.tipoVia = model.customerStreetType == null ? '' : model.customerStreetType;
    this.nombreVia = model.customerStreetName;
    this.numero = model.customerStreetNumber;
    this.interior = model.customerInterior;
    this.urbanizacion = model.customerUrbanization;
    this.referencia = model.customerReference;
    this.departamento = model.customerDepartmentCode == null ? '' : model.customerDepartmentCode;
    this.provincia = model.customerProvinceCode == null ? '' : model.customerProvinceCode;
    this.distrito = model.customerDistrictCode == null ? '' : model.customerDistrictCode;

    if(this.departamento != null && this.departamento != '') {
      this.opcionesDistrito = [];
      this.opcionesProvincia = this.arrayProvinces
        .filter((f: any) => f.parameterDetCode006 ===this.departamento)
        .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 })); 
    }

    if(this.provincia != null && this.provincia != '')
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === this.provincia && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

  }

  dataMockup () {    
    this.tipoVia = '01';
    this.nombreVia = 'ALFREDO BENAVIDES';
    this.numero = '5409';
    this.interior = 'INTERIOS 01';
    this.urbanizacion = 'Urbanizacion las gardenias';
    this.referencia = 'Av.Benavides con Av. Velasaco astete';
    this.departamento = '15';
    this.provincia = '01';
    this.distrito = '40';
    
    this.opcionesDistrito = [];
    this.opcionesProvincia = this.arrayProvinces
      .filter((f: any) => f.parameterDetCode006 ===this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === this.provincia && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  handleClose() {
    this.showModal = false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  async readFile(event: any) {
    const file = event.file.target.files[0];
    if (!file) {
        return;
    }
    return await new Response(file).arrayBuffer();
  }

  base64ArrayBuffer(arrayBuffer: any) {
    var base64 = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63;               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
}

  async handleSelectFileAction(event: any) {
    try {
      const files = event.file.target.files;
      const file = files[0];

      if (this.helperService.isValidFileType(file.type)) {
        if (files && file) {
          const exist = this.filesList.filter((f: any) => f.name === file.name);
          if (exist.length > 0) {
            this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_EXIST, MessageSeverity.error);
            return;
          }

          const sizeParameterBytes = (this.sizeFile == null || this.sizeFile === undefined ||
          this.sizeFile[0] == null || this.sizeFile[0] === undefined) ? null : this.sizeFile[0];

          if (sizeParameterBytes != null) {
            let pesoMaximo = sizeParameterBytes.parameterDetCode001;
            if (file.size > sizeParameterBytes.parameterDetCode001 * 1000000) {
                this.alertService.showMessage('Validar Archivo', `Solo puede adjuntar archivos de tamaño menor o igual a ${pesoMaximo} MB.`, MessageSeverity.warn);
                return;
            }
          }

          this.fileTemp = <File>event.file.target.files[0];
          var extension = this.fileTemp.name.split('.');
          var keyValue = this.uuidv4() + '.' + extension[extension.length - 1];

          let model = {
            applicationId: this.applicationId,
            file: {
              name: this.fileTemp.name,
              type: AppConstants.AttachmentMode.Customer,
              fileId: keyValue,
              fileBase64: this.base64ArrayBuffer(await this.readFile(event)),
              contenType: this.fileTemp.type
            }
          };
          this.uploadFile(model);
        }

      } else {
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_INCORRECT, MessageSeverity.error);
      }

    } catch (error) {
      console.error(error);
    }    
  }

  uploadFile(model: any) {
    this.loading = true;
    this.applicationService.postUploadFile(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.filesList.push({name: this.fileTemp.name, key: model.file.fileId});
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_OK, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  handleFileDownload(event: any) {
    this.downloadFile(event.file.key, event.file.name);
  }

  handleFileDelete(event: any) {
    const keyIndex = this.filesList.findIndex((item: any) => item.key === event.file.key);
    let model = {
      applicationId: this.applicationId,
      fileId: event.file.key,
      index: keyIndex
    };
    this.deleteFile(model);
  }

  downloadFile(fileId: string, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadFile(fileId).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });

        let extension = fileId.split('.');
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, AppConstants.MessageAlert.DOWNLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  deleteFile(model : any): void {
    this.loading = true;
    this.applicationService.postDeleteFile(model).subscribe({
      next: async (response: any) => {
        this.filesList.splice(model.index, 1);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DELETE_FILE, AppConstants.MessageAlert.DELETE_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  sendCommentHandler() {
    if(this.comment == '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_EMPY_CREDENTIALS, MessageSeverity.warn);
    }
    else {
      let model = {
        applicationId: this.applicationId,
        commentKey: AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE,
        comment: this.comment,
        reason: '',
        updatedBy: this.usuario,
        updatedByFullName: this.userName
      };
  
      this.sendAddComentApplication(model);
    }
  }

  sendAddComentApplication(model : any): void {
    this.loading = true;
    this.applicationService.postSendAddComentApplication(model).subscribe({
      next: async (response: any) => {
        this.showModal = false;
        this.comment = '';
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_SUCCES_APPLICATION, MessageSeverity.success);
      },
      error: (error) => {
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_APPLICATION, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  async retrieveApplicationComments(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplicationComments(model)).then((response: any) => {
      const comments = response.filter((f: any) => f.id === AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE);
      if(comments.length > 0){
        this.comment = comments[0].message;
      }
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

}
