import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "offers";
  private BASE_URL: string = environment.offersApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;
  
  postObtenerOfertas(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/retrieve`, model);
  }

}
