import { Component,ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ParameterService } from 'app/services/parameter.service';
import { ApplicationService } from 'app/services/application.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-datos-direccion-modal',
  templateUrl: './datos-direccion-modal.component.html',
  styleUrls: ['./datos-direccion-modal.component.scss']
})
export class DatosDireccionModalComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  application: any = {};

  esMovil: boolean = false;

  // Variables formulario
  tipoVia: string = '';
  nombreVia: string = '';
  numero: string = '';
  interior: string = '';
  urbanizacion: string = '';
  referencia: string = '';
  departamento: string = '';
  provincia: string = '';
  distrito: string = '';

  // Validators
  isErrorTipoVia: boolean = false;
  isErrorNombreVia: boolean = false;
  isErrorNumero: boolean = false;
  isErrorInterior: boolean = false;
  isErrorUrbanizacion: boolean = false;
  isErrorReferencia: boolean = false;
  isErrorDepartamento: boolean = false;
  isErrorProvincia: boolean = false;
  isErrorDistrito: boolean = false;

  opcionesTipoVia: any = [];
  opcionesDepartamento: any = [];
  opcionesProvincia: any = [];
  opcionesDistrito: any = [];

  loading: boolean = false;
  elementsReadOnly: any = [];
  arrayProvinces: any = [];
  arrayDistricts: any = [];
  typeInputNumber: string = 'tel';
  max: number = 8;

  nombreViaValMin: number = 3;
  nombreViaValMax: number = 30;
  numeroValMin: number = 1;
  numeroValMax: number = 5;
  interiorValMin: number = 0;
  interiorValMax: number = 5;
  urbanizacionValMin: number = 0;
  urbanizacionValMax: number = 35;
  referenciaValMin: number = 1;
  referenciaValMax: number = 100;

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  constructor(
    private router: Router,  
    private cdr: ChangeDetectorRef,
    private parameterService: ParameterService,
    private alertService: AlertService,
    private applicationService: ApplicationService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.VIA_TYPE,
      AppConstants.ParameterCode.DEPARTAMENT,
      AppConstants.ParameterCode.PROVINCE,
      AppConstants.ParameterCode.DISTRIC,
    ]);
    await this.getApplication(this.applicationId);
  }

  
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }  

  close() {
    this.onClose.emit(); 
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    
  }

  validarYCambiarComponente() {
    // Validaciones para cada campo
    this.isErrorTipoVia = !this.tipoVia;
    this.isErrorNombreVia = this.nombreVia == null ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);
    this.isErrorNumero = this.numero == null ? true : (this.numero.length >= this.numeroValMin ? false : true);
    this.isErrorReferencia = this.referencia == null ? true : (this.referencia.length >= this.referenciaValMin ? false : true);
    this.isErrorDepartamento = this.departamento === '' ? true : false;
    this.isErrorProvincia = this.provincia === '' ? true : false;
    this.isErrorDistrito = this.distrito === '' ? true : false;

    const requiredElements = [
      this.isErrorTipoVia,
      this.isErrorNombreVia,
      this.isErrorNumero,
      this.isErrorReferencia,
      this.isErrorDepartamento,
      this.isErrorProvincia,
      this.isErrorDistrito
    ];

    if (requiredElements.every(element => !element)) {

      let model = {
        streetType: this.tipoVia,
        streetName: this.nombreVia,
        streetNumber: this.numero,
        inside: this.interior,
        urbanization: this.urbanizacion,
        reference: this.referencia,
        department: this.departamento,
        province: this.provincia,
        district: this.distrito
      };

      this.updateApplication({ 
        applicationId: this.applicationId,
        workflowInstanceId : this.workflowId,
        taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
        updatedBy: this.usuario,
        updatedByFullName: this.userName,
        address: model 
      });       
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }
  }

  resetErrorState(inputType: string) {
    if (inputType === 'nombreVia') {
      this.isErrorNombreVia = this.nombreVia === undefined || this.nombreVia === null  ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);  
    }else   if (inputType === 'numero') {
      this.isErrorNumero = this.numero === undefined || this.numero === null  ? true : (this.numero.length >= this.numeroValMin ? false : true);  
    }else   if (inputType === 'referencia') {
      this.isErrorReferencia = this.referencia === undefined || this.referencia === null  ? true : (this.referencia.length >= this.referenciaValMin ? false : true);  
    }
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    this.opcionesTipoVia = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.VIA_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }))

    this.opcionesDepartamento = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DEPARTAMENT && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }))

    this.arrayProvinces = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PROVINCE && f.state === AppConstants.StateFlag.Active);

    this.arrayDistricts = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DISTRIC && f.state === AppConstants.StateFlag.Active);

  }

  getProvinces(event: any) {
    this.provincia = '';
    this.distrito = '';
    this.opcionesDistrito = [];
    this.opcionesProvincia = this.arrayProvinces
      .filter((f: any) => f.parameterDetCode006 === event.value)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  getDistricts(event: any) {
    this.distrito = '';
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === event.value && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  updateApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.close();
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    this.tipoVia = model.customerStreetType == null ? '' : model.customerStreetType;
    this.nombreVia = model.customerStreetName;
    this.numero = model.customerStreetNumber;
    this.interior = model.customerInterior;
    this.urbanizacion = model.customerUrbanization;
    this.referencia = model.customerReference;
    this.departamento = model.customerDepartmentCode == null ? '' : model.customerDepartmentCode;
    this.provincia = model.customerProvinceCode == null ? '' : model.customerProvinceCode;
    this.distrito = model.customerDistrictCode == null ? '' : model.customerDistrictCode;

    if(this.departamento != null && this.departamento != '') {
      this.opcionesDistrito = [];
      this.opcionesProvincia = this.arrayProvinces
        .filter((f: any) => f.parameterDetCode006 ===this.departamento)
        .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 })); 
    }

    if(this.provincia != null && this.provincia != '')
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === this.provincia && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

  }

  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }

}
