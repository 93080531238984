import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasivosValidacionCorreoComponent } from './pasivos-validacion-correo.component';
import { PasivosValidacionCorreoComponentRoutingModule } from './pasivos-validacion-correo-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent } from 'app/components';



@NgModule({
  declarations: [PasivosValidacionCorreoComponent],
  imports: [
    CommonModule,
    PasivosValidacionCorreoComponentRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent
  ]
})
export class PasivosValidacionCorreoModule { }
