<div class="componente-container">
  
        <div class="parte-superior">
          <div class="imagen-container">
            <img src="assets/fotografia.svg" alt="Imagen Superior" />
          </div>
          <div class="info-container">
            <p><strong>N° SOLICITUD:</strong> {{ applicationId }}</p>
            <p><strong>{{ client.documentTypeDesciption }}:</strong> {{ client.documentNumber }}</p>
            <p><strong>CLIENTE:</strong> {{ client.fullName }}</p>
          </div>
        </div>
      
        <app-line-divider></app-line-divider>
      
        <div class="parte-inferior">
          <div class="imagen-container-inferior">
            <img src={{product.image}} alt="Imagen Inferior" />
          </div>
          <div class="info-container-inferior">
            <p class="producto">{{ product.name }}</p>
            <p class="monto"><strong>{{ simulation.loanAmountFormat }}</strong></p>
          </div>
        </div>
    
        <app-line-divider></app-line-divider>
      
        <div class="parte-inferior">
            <app-custom-button-color iconUrl="./assets/img/adjuntos.svg" 
            text="VER ADJUNTOS"  (action)="abrirModalEvento()">
            </app-custom-button-color>
        </div>
    </div>
<loading *ngIf="loading"></loading>