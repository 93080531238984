import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-pasivos-finalizacion',
  templateUrl: './pasivos-finalizacion.component.html',
  styleUrls: ['./pasivos-finalizacion.component.scss']
})
export class PasivosFinalizacionComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  application: any = {};
  applicationSavingAccount: any = {};

  finalMessage: string = '¡Finalizado con Éxito!';
  imagePath: string = 'assets/send_application.svg';
  loading: boolean = false;

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService) 
  {    
  }

  async ngOnInit() {
    await this.retrieveApplication(this.applicationId);
    await this.retrieveApplicationSavingAccount(this.applicationId);
  }

  goHome() {
    this.router.navigate(['home']);
  }

  async retrieveApplication(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(applicationId)).then((response: any) => {      
      this.application  = response;
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });  
  }

  async retrieveApplicationSavingAccount(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplicationSavingAccount(applicationId)).then((response: any) => {      
      this.applicationSavingAccount  = response;
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });  
  } 

}
