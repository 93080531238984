import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public isValidFileType(fileType: string): boolean {
    
    const validTypes = [
      'image/png',                            // PNG
      'image/jpeg',                           // JPEG
      'application/pdf',                      // PDF
      'audio/mpeg',                           // MP3
      'application/msword',                   // Word (.doc)
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word (.docx)
      'application/vnd.ms-excel',             // Excel (.xls)
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // Excel (.xlsx)
    ];

    return validTypes.includes(fileType);
  }
}
