<app-header [headerText]="'Autenticación Facial'"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> {{applicationId}} </span>
  </div>
</div>



<div class="contenedor-informacion">

    <div class="informacion-producto container-imagen">
      <img src="assets/img/validacion_facial.svg" alt="Descripción de la imagen">
   
    </div>

     <div *ngIf="validationIncomplete" class="informacion-producto containter-validacion">
        <div class="container-texto-celular">
            <label class="texto-celular">1. </label> <p class="texto-descripcion"> Ingrese el número de celular</p>
        </div>
        <div class="container-celular">
          <app-custom-input [(value)]="cellphone" [isError]="isErrorCellphone" [type]="typeInputNumber" [maxlength]="max" [validatemaxlength]="true"
            (keyup)="resetErrorState()">
          </app-custom-input>
        </div>
        <app-custom-button text="Enviar" (action)="sendAuthFace()"></app-custom-button>
        <p class="texto-validacion">
            Se enviará un link de autenticación al número brindado para iniciar la validación.     
         </p>
     </div>
     
    <div *ngIf="validationInProgress" class="informacion-producto containter-validacion">
        <div class="container-texto-finalizada">
            <p class="texto-descripcion"> Esperando Autenticación del Cliente </p>
        </div>
        <app-custom-button-color text="CAMBIAR NÚMERO" (action)="changeNumber()"></app-custom-button-color>
        <app-custom-button-color text="REENVIAR" (action)="retrySend()"></app-custom-button-color>
        <app-custom-button text="VALIDAR"  (action)="checkStatusAuthentication()"></app-custom-button>
    </div>  

    <div *ngIf="validationCompleted" class="informacion-producto containter-validacion-finalizada">
        <div class="container-texto-finalizada">
            <p class="texto-descripcion"> ¡Autenticación Completada!</p>
        </div>
     </div>  

</div>

<div class="contenedor-botones">
  <div class="form-flex-container">
    <div class="container-atras">
      <app-custom-button-color text="Atrás" (action)="goAuth()"></app-custom-button-color>
    </div>
    <div class="container-continuar">
      <app-custom-button text="Continuar" [isDisabled]="botonDeshabilitado" (action)="executeServices()"> </app-custom-button>
    </div>
  </div>

</div>

<app-footer></app-footer>

<app-modal-resumen *ngIf="showModalResumen" [width]="450" [height]="430" (linkAction)="accionPersonalizada()"
           [titleText]="tituloModal" [body]="updateApplication" (onClose)="handleCloseAlta()">
</app-modal-resumen>

<loading *ngIf="loading"></loading>