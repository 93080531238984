<app-header [headerText]="'5. Final'"  headerImageSrc="assets/check.svg"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <img [src]="imagePath" alt="Descripción de la imagen">
    <p class="titulo-finalizacion">¡Finalizado con Exito!</p>
    <p class="texto-finalizacion">
      {{ finalMessage }}
    </p>
    <app-custom-button text="Regresar" (action)="goHome()"></app-custom-button>
  </div>
</div>
<app-footer></app-footer>

<loading *ngIf="loading"></loading>