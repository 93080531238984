
<app-header [headerText]="'Parámetros'"  [showImage]="false"></app-header>

<div class="contenedor-filtros">
  <!-- Div para los inputs de filtro -->
  <div class="filtro-inputs">
    <div class="form-group">
      <label class="titulo-filtro">Nombre</label>
      <app-custom-input-login [width]="'300px'"
                       placeholder="Seleccione">
      </app-custom-input-login>
    </div>
    <app-custom-button
    text="buscar" ></app-custom-button>
    <app-custom-button-color 
    text="limpiar" ></app-custom-button-color>
  </div>
</div>
<!-- <div class="resultado-busqueda">
  <div class="resultados">
    <label for="" class="label-resultado">Resultado de Búsqueda</label>
    <label for="" class="label-nro-resultado">{{nroResultados}} registros encontrados</label>
  </div>
 <div class="btn-add-usuario">
  <app-custom-button
  text="Agregar Empresa"  iconUrl="assets/img/add_empresa.svg"  (action)="addUsuario('add')"></app-custom-button>
 </div>
</div> -->
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="tusDatos" actionButtonText="EDITAR" [navigate]="addUsuario.bind(this)"></app-custom-table-paginator>
  </div>
</div>
<app-footer></app-footer>



