
<app-header [headerText]="textoCabecera"  [showImage]="false"></app-header>

<div class="layout-container">
  <div  class="formulario-container">
      <div class="form-row">
          <div class="form-group">
            <label>Usuario</label>
            <app-custom-input [width]="'220px'"></app-custom-input>
          </div>

          <div class="form-group">
            <label>Contraseña</label>
            <app-custom-input [width]="'220px'"></app-custom-input>
          </div>

      </div>
      <div class="form-row">
        <div class="form-group">
          <label>Nombre Completo</label>
          <app-custom-input-flex   placeholderText="" [width]="'500px'"></app-custom-input-flex>
        </div>
      </div>
  </div>  


  <div  class="formulario-container">
    <div class="form-row">
        <div class="form-group">
          <label>Tipo de Documento</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
        
        </div>

        <div class="form-group">
          <label>N° de Documento</label>
          <app-custom-input [width]="'220px'"></app-custom-input>
        </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Código Banco</label>
        <app-custom-input-flex   placeholderText="" [width]="'500px'"></app-custom-input-flex>
      </div>
    </div>
</div>  


  <div  class="formulario-container">
    <div class="form-row">
      <div class="form-group">
        <label>Email</label>
        <app-custom-input-flex   placeholderText="" [width]="'500px'"></app-custom-input-flex>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Empresa</label>
        <app-custom-input-flex   placeholderText="" [width]="'500px'"></app-custom-input-flex>
      </div>
    </div>
    
  </div>  

  

  <div  class="formulario-container">
    <div class="form-row">
        <div class="form-group">
          <label>Rol</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
        </div>

        <div class="form-group">
          <label>País</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
        </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label>Estado</label>
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
      </div>

      <div class="form-group">
        <label>Agencia</label>
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
      </div>
  </div>


</div>  

<div  class="formulario-container">
  <div class="form-row">
      <div class="form-group">
        <label>Canal de Venta</label>
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'500px'"></app-custom-inpunt-select>
      </div>

  </div>

</div>  


<div  class="footer-switch">
   <app-custom-input-switch></app-custom-input-switch>
   <label>Anular Solicitud - Reporte Solicitud</label>
</div>  

<div class="footer-buttons">
  <a href="#" class="link-atras" >Cancelar</a>
    <app-custom-button
    text="CONFIRMAR"  ></app-custom-button>
</div>

</div>
<app-footer></app-footer>



