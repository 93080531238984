export const AppConstants = {

    Session: {
        PRODUCT: 'PRODUCTO',
        CLIENT: 'CLIENT',
        OFFERS: 'OFFERS',
        CUSTOMER: 'CUSTOMER',
        CUSTOMER_TRAY: 'CUSTOMER_TRAY',
        SIMULACION: 'SIMULACION',
        SIMULACION_OPTIONALA_INSURANCES: 'SIMULACION_OPTIONAL_INSURANCES',
        ACCESS_TOKEN: 'ACCESS_TOKEN',
        USERID: 'USERID',
        USERNAME: 'USERNAME',
        TIMESECONDS: 'TIMESECONDS',
        CHANGEPASSWORD: 'CHANGEPASSWORD',
        EXPIREDPASSWORD: 'EXPIREDPASSWORD',
        USERROLID: 'ROLID',
        USERROLNAME: 'ROLNAME',
        EMAIL: 'CORREO',
        AGENCYCODE: 'AGENCY_CODE',
        AGENCYNAME: 'AGENCY_NAME',
        SALESCHANNEL: 'SALES_CHANNEL',
        SALES_CHANNEL_DESCRIPTION: 'SALES_CHANNEL_DESCRIPTION',
        APPLICATION_ID: 'APPLICATION_ID',
        WORKFLOW_ID: 'WORKFLOW_ID',
        SALESSUBCHANNEL: 'SALES_SUBCHANNEL',
        SALES_SUB_CHANNEL_DESCRIPTION: 'SALES_SUB_CHANNEL_DESCRIPTION',
        FILTERS_TRAY: 'FILTERS_TRAY',
        ENTERPRISE: 'ENTERPRISE',
        ENTERPRISE_DESCRIPTION: 'ENTERPRISE_DESCRIPTION',
        CUSTOMER_INFORMATION: 'CUSTOMER_INFORMATION'
    },

    TitleAlert: {
        LOGIN_TITLE: 'Inicio Sesión.',
        OPTIONAL_INSURENCES_TITLE: 'Seguros Optativos',
        SIMULATION_TITLE: 'Simulador Préstamo',
        CONSULT_OFFERS_TITLE: 'Consulta de Ofertas',
        COMPLIANCE_TITLE: 'Políticas Crediticia',
        APPLICATION_TITLE: 'Registro Solicitud',
        APPLICATION_UPDATE_TITLE: 'Actualizacion Solicitud',
        DISBURSEMENT_MODE_TITLE: 'Modo de Desembolso',
        VALIDATION_APPLICATION: 'Validacion de Solicitud',
        NOTIFICATION_TITLE: 'Notificación',
        CONSULT_NOTIFICATION_TITLE: 'Consulta envío Notificación',
        CONSULT_AUTHENTICATION_TITLE: 'Validar Autenticación',
        PROCESS_AUTHENTICATION_TITLE: 'Proceso Autenticación',
        RETRIEVE_APPLICATION_TITLE: 'Obtener Solicitud',
        CUSTOMER_RETRIEVE_TITLE: 'Consultar Cliente',
        NOTIFICATION_VALIDATION_EMAIL_TITLE: 'Validacion Correo Electrónico',
        UPLOAD_FILES: 'Adjuntar Archivos',
        DOWNLOAD_FILE: 'Descargar Archivo',
        DELETE_FILE: 'ELiminar Archivo',
        CANCELLATION_APPLICATION: 'Anulación de Solicitud',
        DECLINE_APPLICATION: 'Rechazar de Solicitud',
        SAVING_ACCOUNT_TYPE_TITLE: 'Tipo de Cuenta de Ahorros',
    },

    MessageAlert: {
        OPTIONAL_INSURANCE_RETRIEVE_SUCCESS: 'Se obtuvieron los seguros correctamente.',
        OPTIONAL_INSURANCE_RETRIEVE_ERROR: 'Ocurrio un error al obtener los seguros, volver a intentar.',
        OPTIONAL_INSURANCE_CALCULATION: 'Ocurrio un error al calcular el monto de la prima, volver a intentar.',
        SIMULATION_SUCCESS: 'Simulación exitosa.',
        SIMULATION_ERROR: 'Ocurrio un error, volver a intentar.',
        USER_DOESNT_EXIST: 'El usuario o la contraseña son incorrectos.',
        PASSWORD_DOESNT_CORRECT: 'El usuario o la contraseña son incorrectos.',
        USER_IS_INACTIVE: 'El usuario o la contraseña son incorrectos.',
        USER_IS_LOCKED: 'Usuario bloqueado.',
        LOGIN_ERROR: 'Ocurrio un error al obtener datos del usuario, volver a intentar.',
        OFFERS_RETRIEVE_ERROR: 'Ocurrio un error al obtener las ofertas, volver a intentar.',
        OFFERS_RETRIEVE_SUCCESS: 'Se obtuvieron las ofertas correctamente.',
        COMPLIANCE_RETRIEVE_ERROR: 'No es posible validar la admision del cliente, volver a intentar.',
        REGISTER_APPLICATION_SUCCESS: 'Se registro la solicitud correctamente.',
        REGISTER_APPLICATION_ERROR: 'Ocurrio un error al registrar la solicitud, volver a intentar.',
        ERROR_FORM_REQUIRED: 'Debe completar los campos necesarios.',
        UPDATE_APPLICATION_SUCCESS: 'Se actualizo correctamente.',
        UPDATE_APPLICATION_ERROR: 'Ocurrio un error al actualizar la solicitud, volver a intentar.',
        CUSTOMER_ACCOUNTS_ERROR_RETRIEVE: 'Ocurrio un error al obtener las cuentas del cliente.',
        DISBURSEMENT_TYPE_ERROR_REQUIRED: 'Debe elegir un modo de desembolso.',
        DISBURSEMENT_CURRENT_ACOUNT_REQUIRED: 'Debe seleccionar una cuenta existe.',
        DISBURSEMENT_BANK_TRANSFER_REQUIRED: 'Debe seleccionar una entidada financiera.',
        DISBURSEMENT_BANK_TRANSFER_CCI_REQUIRED: 'El número de CCI es incorrecto.',
        DISBURSEMENT_BANK_TRANSFER_CCI_ERROR: 'El número de CCI debe contener los 03 primeros digitos del banco seleccionado.',
        DISBURSEMENT_MODE_SUCCESS: 'Se grabaron los datos correctamente.',
        DISBURSEMENT_MODE_ERROR: 'Ocurrio un error, volver a intentar.',
        APPLICATION_UPDATE_STATE_ERROR: 'Debe seleccionar una opción para continuar.',
        APPLICATION_ANNULLED: 'La solicitud fue anulada, volver a consultar.',
        APPLICATION_NOT_CREDENTIALS: 'Debe completar las credenciales (Usuario y Clave)',
        ERROR_USER_DISTICT: 'El usuario ingresado no es el logeado.',
        ERROR_USER_NOT_AUTHORIZED: 'El usuario ingresado no tiene los permisos para realizar dicha acción.',
        NOTIFICATION_SUCCES: 'Link de Autenticación enviado correctamente.',
        CONSULT_AUTHENTICATION_TITLE: 'Pendiente de completar autenticación facial.',
        RETRIEVE_APPLICATION_MESSAGE: 'Ocurrio un error al recuperar la solicitud.',
        SIMULATION_NOT_QUOTA_ERROR: 'Debe ingresar una cuota válida.',
        SIMULATION_QUOTA_ERROR: 'Debe ingresar una cuota válida.',
        SIMULATION_VALIDATOR_AMOUNT_MIN_ERROR: 'El monto mínimo de la oferta es ',
        SIMULATION_VALIDATOR_AMOUNT_MAX_ERROR: 'El monto máximo de la oferta es ',
        CONSULT_VERIFICATION_EMAIL_TITLE: 'Pendiente de completar la validación.',
        NOTIFICATION_EMAIL_SUCCES: 'Correo enviado exitosamente.',
        ERROR_VERIFICATION_EMAIL_TITLE: 'Código incorrecto.',
        UPLOAD_FILE_INCORRECT: 'Debe seleccionar un archivo .PNG, .JPG, .MP3, .DOC, .XLS, .XLSX o .PDF',
        UPLOAD_FILE_EXIST: 'El archivo seleccionado ya existe.',
        UPLOAD_FILES_MESSAGE_OK: 'Se adjuntarón los archivos correctamente.',
        UPLOAD_FILES_MESSAGE_ERROR: 'Ocurrio un error al adjuntar el archivo, volver a intentar.',
        DOWNLOAD_FILES_MESSAGE_ERROR: 'Ocurrio un error al descargar el archivo, volver a intentar.',
        DELETE_FILES_MESSAGE_ERROR: 'Ocurrio un error al eliminar el archivo, volver a intentar.',
        CANCELLATION_APPLICATION_NOT_EXIST: 'No se puede anular la solicitud, aún no se genera una.',
        CANCELLATION_APPLICATION_SUCCES: 'Se anuló la solicitud correctamente.',
        UPDATE_APPLICATION_SUCCES: 'Se actualizó la solicitud correctamente.',
        ADD_COMMENT_SUCCES_APPLICATION: 'Se agregó el comentario correctamente.',
        ADD_COMMENT_ERROR_APPLICATION: 'Ocurrio un error al agregar el comentario.',
        ADD_COMMENT_ERROR_EMPY_CREDENTIALS: 'Debe ingresar un comentariao válido.',
        UPLOAD_FILES_CRONOGRAMA_MESSAGE_ERROR: 'Ocurrio un error al adjuntar el cronograma de pago automáticamente.',
        UPLOAD_FILES_CRONOGRAMA_MESSAGE_OK: 'Se adjuntó el cronograma de pago correctamente.',
        ERROR_GOOGLE_RECAPTCHA_ENTERPRISE: 'Ocurrio un error con el servicio de Google Recaptcha.',
        SUSPICIOUS_ACTIVITY: 'Error al indentificarse. Se detectó actividad sospechosa.',
        ERROR_GOOGLE_RECAPTCHA_ENTERPRISE_OFF: 'El servicio de Google Recaptcha Enterprise no está disponible.',
        SAVING_ACCOUNT_CURRENCY_TYPE_ERROR_REQUIRED: 'Debe elegir el tipo de moneda.',
        SAVING_ACCOUNT_TYPE_ERROR_REQUIRED: 'Debe seleccionar una tipo de cuenta de ahorros.',
        SAVING_ACCOUNT_TYPE_SUCCESS: 'Se grabaron los datos correctamente.',
        SAVING_ACCOUNT_TYPE_ERROR: 'Ocurrio un error, volver a intentar.',
    },

    ParameterCode: {
        DOCUMENT_TYPE: 1,
        PRODUCTS_TYPE: 2,
        MARITAL_STATUS: 3,
        NACIONALITY: 4,
        VIA_TYPE: 5,
        LEVEL_STUDY: 6,
        EMPLOYMENT_RELATIONSHIP: 7,
        PROFESION: 8,
        DEPARTAMENT: 9,
        PROVINCE: 10,
        DISTRIC: 11,
        AGENCYS: 12,
        PAY_DAYS: 13,
        DESGRAVAMEN_TYPE: 14,
        NUMBER_INSTALLMENTS: 15,
        ELEMENTS_BLOCKED: 16,
        HOUSING_SITUATION: 17,
        BANKS_TRANSFERS: 18,
        VARIABLE: 19,
        VARIABLE_DETAIL: 20,
        STATES_FILTER: 21,
        SIZE_FILES: 22,
        REASONS: 23,
        BANK_TRANSFER_COMISSION: 24,
        SAVINGS_ACCOUNT_TYPE: 25
    },

    StateFlag: {
        NoValue: -1,
        NoValueString: 'NV',
        Active: 1,
        ActiveString: '1',
        Inactive: 0,
        InactiveString: '0',
    },

    MaritalStatus: {
        MARRIED: '2'
    },

    DisbursementCode: {
        WithoutDisbursement: 0,
        NewAccount: 1,
        ExistingAccount: 2,
        ImmediateInterbankTransfer: 3
    },

    DisbursementName: {
        WithoutDisbursement: 'Sin Desembolso',
        NewAccount: 'Depósito a cuenta nueva',
        ExistingAccount: 'Depósito a cuenta existente',
        ImmediateInterbankTransfer: 'Depósito a cuenta Interbancaria'
    },

    ApplicationStates: {
        None: 0,
        EnProcesoEvaluacion: 1,
        EnProcesoAutentificacion: 2,
        PendienteAutorizacion: 3,
        Aprobado: 4,
        Anulado: 5,
        Finalizado: 6,
        Observado: 7,
        Rechazado: 8,
        PendienteAprobacion: 9

    },

    WorkflowTasks: {
        PRODUCT_EVALUATION: 10,
        PRODUCT_ACCEPTANCE: 20,
        CUSTOMER_VERIFICATION: 30,
        APPROVAL: 40,
        DISBURSEMENT: 50,
        OFFER_EXPIRATION: 60,
        PASIVOS_PRODUCT_EVALUATION: 10,
        PASIVOS_ACCEPTANCE: 20,
        PASIVOS_CUSTOMER_VERIFICATION: 30,
        PASIVOS_CUSTOMER_APROBACION: 40,
        PASIVOS_COMPLETADO: 50
    },

    Channels: {
        DesktopPVA: 10,
        MovilPVA: 20,
        DesktopPasivosPVA: 30,
        MovilPasivosPVA: 40,
    },

    Process: {
        PLD_DRACMA_APROBACION: 10,
        APERTURA_CUENTA_AHORROS: 40
    },

    Roles: {
        ADM: 'R0001',
        ASESOR_VENTA: 'R0002',
        SUPERVISOR: 'R0003',
        ANALISTA: 'R0004',
        ASESOR_VENTA_CALLCENTER: 'R0005',
        SUPERVISOR_CALL: 'R0006',
        OPERADOR: 'R0007',
        REPORTES_BI: 'R0008',
        JEFATURA_RED: 'R0009'
    },

    StatusZytrus: {
        Success: 8000
    },

    AttachmentMode: {
        Customer: 1,
        Entities: 2
    },

    OptionsAnalista: {
        APROBADO: 'APROBADO',
        OBSERVADO: 'OBSERVADO',
        RECHAZADO: 'RECHAZADO'
    },

    ApplicationComment: {
        COMMENT_ATTACHMENT_CODE: '0001',
        DISBURSEMENT_USER_CODE: '0002'
    },

    OptionalInsuracesCode: {
        UNEMPLOYMENT: 'DDES',
        LIFE: 'DVID'
    },

    Desgravamen: {
        NONE: '000',
        BASIC: '005',
        WITH_RETURN: '008'
    },

    GuardMetaData: {
        PROFILES: 'profiles'
    },

    RecaptchaEnterprise: {
        Login_event: 'login',
        Authface: 'authface'
    },

    Products: {
        Loans: 'PTMN',
        Savings: 'AHOR'
    }
}
