
<app-header [headerText]="textoCabecera"  [showImage]="false"></app-header>

<div class="layout-container">
  <div  class="formulario-container">

      <div class="form-row">
        <div class="form-group">
          <label>Nombre Completo</label>
          <app-custom-input-flex   placeholderText="" [width]="'500px'"></app-custom-input-flex>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label>Estado</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
        </div>


    </div>
  </div>  


  <div  class="formulario-container">
    <div class="form-row">
        <div class="form-group">
          <label>País</label>
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'220px'"></app-custom-inpunt-select>
        
        </div>

      

    </div>
    
</div>  

<div class="footer-buttons">
  <a href="#" class="link-atras" >Cancelar</a>
    <app-custom-button
    text="CONFIRMAR"  ></app-custom-button>
</div>

</div>
<app-footer></app-footer>



