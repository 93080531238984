<img *ngIf="!esMovil" src="assets/03Pasivos.svg" class="stepper">
<img *ngIf="esMovil" src="assets/03PasivosMovil.svg" class="stepper">
<div class="layout-container">
  <div class="container-datos-cliente">
    <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
  </div>
  
    <div class="formulario-container">
  
    <strong class="segundo-titulo">CONCENTIMIENTO PARA EL TRATAMIENTO PARA FINALIDADES ADICIONALES:</strong>

    <div class="radio-group">
        <input type="radio" [(ngModel)]="dataProtection" id="opcion-si" name="consentimiento"  value="1" class="radio-input" hidden>
        <label for="opcion-si" class="radio-custom"></label><label for="opcion-si" class="radio-option">SÍ</label>
        <label class="espacio"></label>
        <input type="radio" [(ngModel)]="dataProtection" id="opcion-no" name="consentimiento"  value="0" class="radio-input" hidden>
        <label for="opcion-no" class="radio-custom"></label><label for="opcion-no" class="radio-option">NO</label>
    </div>
    <br>
    <label>
      Con el presente concentimiento el cliente acepta que sus datos personales sean tratados para:
      </label> <br><br>
      <label>
        a. Enviar información comencial, promocional y/o publicidad con relación a los productos y/o servicios de BANBIF.
      </label>
      <label>
        b. Almacenar y tratar los datos personales con fines estadístidos y/o históricos. 
       </label>
       <label>
        c. Evaluar solicitudes que efectúe el cliente en el futuro y realizar prospección comercial.
       </label>
 
       <label>
        Tranferencia de Datos Personales: Con este consentimiento también se aurotiza la transferencia de sus datos personales a BANBIF, a efectos de que realice los usos descritos en los literales a, b y c anteriores. 0
       </label>
    </div>  
</div>


<div class="container-buttons">
  <div class="footer-buttons">
    <a class="link-atras" (click)="backForm()">ATRÁS</a>
    <app-custom-button
    text="siguiente" (action)="nextProcess()" ></app-custom-button>
</div>
</div>

<app-adjunto-modal *ngIf="showModal" 
  (onClose)="handleClose()" 
  (onFileSelectedAction)="handleSelectFileAction($event)" 
  (onFileDownloadAction)="handleFileDownload($event)" 
  (onFileDeleteAction)="handleFileDelete($event)" 
  [applicationFiles]="filesList"
  [(value)]="comment"
  (onSave)="sendCommentHandler()">
</app-adjunto-modal>

<loading *ngIf="loading"></loading>   