import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimuladorOfertaComponent } from './simulador-oferta.component';
import { SimuladorOfertaRoutingModule } from './simulador-oferta-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,
  BackLinkComponent, CustomDatepickerComponent} from 'app/components';
import { InputMaterialComponent } from 'app/components/input-material';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { CustomInputAutocompleteComponent } from 'app/components/custom-input-autocomplete';

@NgModule({
  declarations: [
    SimuladorOfertaComponent
  ],
  imports: [
    CommonModule,
    SimuladorOfertaRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    BackLinkComponent,
    CustomDatepickerComponent,
    InputMaterialComponent,
    CustomInputAutocompleteComponent,
    SharedModule
  ]
})
export class SimuladorOfertaModule { }
