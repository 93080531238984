import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-empresas',
  templateUrl: './admin-empresas.component.html',
  styleUrls: ['./admin-empresas.component.scss']
})
export class AdminEmpresasComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number =5;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  columnNames = ['Item', 'Empresa', 'País', 'Estado','Usuario Creación', 'Fecha Creación', 'Acciones'];
  tusDatos: any[] = [];
  showModalFiltros =false;
  modalWidth: number = 480; 
  modalHeight: number = 380; 
  constructor(private router: Router) { }
  ngOnInit(): void {

    this.tusDatos = [
      {
        "Item": "1",
        "Empresa": "C&C INVERSIONES",
        "País": "PERÚ",
        "Estado": "ACTIVO",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "2",
        "Empresa": "ATENTO",
        "País": "PERÚ",
        "Estado": "ACTIVO",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-02",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "3",
        "Empresa": "BAYENTAL",
        "País": "PERÚ",
        "Estado": "ACTIVO",
        "Usuario Creación": "user2",
        "Fecha Creación": "2023-02-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "4",
        "Empresa": "MULTIRECARGAS",
        "País": "PERÚ",
        "Estado": "ACTIVO",
        "Usuario Creación": "user3",
        "Fecha Creación": "2023-02-15",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "5",
        "Empresa": "SCC",
        "País": "PERÚ",
        "Estado": "ACTIVO",
        "Usuario Creación": "user3",
        "Fecha Creación": "2023-02-15",
        "Acciones": "<a href='#'>EDITAR</a>"
      }
    ];
    
    
    
  }

  addUsuario(accion: string){
    this.router.navigate(['admin-empresas-acciones'], { queryParams: { parametro: accion } });
  }
  

}
