import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportesRoutingModule } from './reportes-routing.module';
import { ReportesComponent } from './reportes.component';
import { CustomButtonColorComponent, CustomButtonComponent, CustomDatepickerComponent, CustomInpuntSelectComponent, CustomInputComponent, CustomInputFlexComponent, FooterComponent, HeaderComponent } from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';


@NgModule({
  declarations: [
    ReportesComponent
  ],
  imports: [
    CommonModule,
    ReportesRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    CustomInputFlexComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomButtonCircleComponent,
    CustomDatepickerComponent,
    SharedModule
  ]
})
export class ReportesModule { }
