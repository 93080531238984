import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinalizacionComponent } from './finalizacion.component';
import { FinalizacionRoutingModule } from './finalizacion-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent } from 'app/components';
import { SharedModule } from 'app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [FinalizacionComponent],
  imports: [
    CommonModule,
    FinalizacionRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    SharedModule
  ]
})
export class FinalizacionModule { }
