import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasivosValidacionBiometricaComponent } from './pasivos-validacion-biometrica.component';
import { PasivosValidacionBiometricaComponentRoutingModule } from './pasivos-validacion-biometrica-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent } from 'app/components';



@NgModule({
  declarations: [PasivosValidacionBiometricaComponent],
  imports: [
    CommonModule,
    PasivosValidacionBiometricaComponentRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent
  ]
})
export class PasivosValidacionBiometricaModule { }
