import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasivosHomeComponent } from './pasivos-home.component';

const routes: Routes = [
  {path: '',component: PasivosHomeComponent},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasivosHomeRoutingModule { }
