<app-header [headerText]="''" [showImage]="false"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <img [src]="imagePath" alt="Descripción de la imagen">
    <p class="titulo-finalizacion">Acceso Denegado</p>
    <p class="texto-finalizacion">
        No tienes permiso para acceder a esta página.
    </p>
  </div>
</div>
<app-footer></app-footer>