<div class="container-stepper">
  <img *ngIf="!esMovil" src="assets/img/02.svg" class="stepper">
  <img *ngIf="esMovil" src="assets/02.svg" class="stepper">
</div>

<div class="layout-container">
    <div class="container-datos-cliente">
      <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
    </div>
    <div  class="formulario-container">
   
        <label class="titulo-direccion">Datos de Dirección</label>

        <div class="form-row">
          <div class="form-group">
            <label>Tipo de Vía</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesTipoVia" [isReadonly]="elementsReadOnly.includes('tipoVia')"
            [(selectedValue)]="tipoVia"  (selectedValueChange)="tipoVia=$event" [isError]="isErrorTipoVia">
            </app-custom-inpunt-select>
          </div>

          <div class="form-group">
            <label>Nombre de vía</label>
            <app-custom-input [(value)]="nombreVia" [isError]="isErrorNombreVia" [isReadonly]="elementsReadOnly.includes('nombreVia')"
            (keyup)="resetErrorState('nombreVia')" [minlength]="nombreViaValMin" [maxlength]="nombreViaValMax"></app-custom-input>
          </div>
          
        </div>

        <div class="form-row">
            <div class="form-group">
              <label>Número </label>
              <app-custom-input [(value)]="numero" [type]="typeInputNumber" [maxlength]="max" [isError]="isErrorNumero" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('numero')"
              (keyup)="resetErrorState('numero')" [minlength]="numeroValMin" [maxlength]="numeroValMax"></app-custom-input>
            </div>
  
            <div class="form-group">
              <label>Interior</label>
              <app-custom-input [(value)]="interior" [isError]="isErrorInterior" [isReadonly]="elementsReadOnly.includes('interior')"
              (keyup)="resetErrorState('interior')" [minlength]="interiorValMin" [maxlength]="interiorValMax"></app-custom-input>
            </div>
        </div>

        <div class="form-row">
          <div class="form-group urbanización">
            <label>Urbanización</label>
            <app-custom-input [(value)]="urbanizacion" [isError]="isErrorUrbanizacion" [isReadonly]="elementsReadOnly.includes('urbanizacion')"
            (keyup)="resetErrorState('urbanizacion')" [minlength]="urbanizacionValMin" [maxlength]="urbanizacionValMax"></app-custom-input>
          </div>
        </div>

        <div class="form-row">
            <div class="form-group referencia">
              <label>Referencia</label>
              <app-custom-input [(value)]="referencia" [isError]="isErrorReferencia" [isReadonly]="elementsReadOnly.includes('referencia')"
              (keyup)="resetErrorState('referencia')" [minlength]="referenciaValMin" [maxlength]="referenciaValMax"></app-custom-input>
            </div>
        </div>
     
        <div class="form-row">
            <div class="form-group">
              <label>Departamento</label>
              <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDepartamento" [isReadonly]="elementsReadOnly.includes('departamento')"
              [(selectedValue)]="departamento"  (selectedValueChange)="departamento=$event" [isError]="isErrorDepartamento" (change)="getProvinces($event.target)">
              </app-custom-inpunt-select>
            </div>
  
            <div class="form-group">
              <label>Provincia</label>
              <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesProvincia" [isReadonly]="elementsReadOnly.includes('provincia')"
              [(selectedValue)]="provincia"  (selectedValueChange)="provincia=$event" [isError]="isErrorProvincia" (change)="getDistricts($event.target)">
              </app-custom-inpunt-select>
            </div>
            
        </div>

        <div class="form-row">
            <div class="form-group">
              <label>Distrito</label>
              <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDistrito" [isReadonly]="elementsReadOnly.includes('distrito')"
              [(selectedValue)]="distrito"  (selectedValueChange)="distrito=$event" [isError]="isErrorDistrito">
              </app-custom-inpunt-select>
            </div>
        </div>
    </div>

</div>
<div class="container-buttons">
  <div class="footer-buttons">
    <a href="#" class="link-atras">ATRÁS</a>
    <app-custom-button text="Siguiente" (action)="validarYCambiarComponente()"></app-custom-button>
</div>
</div>

<app-adjunto-modal *ngIf="showModal" 
  (onClose)="handleClose()" 
  (onFileSelectedAction)="handleSelectFileAction($event)" 
  (onFileDownloadAction)="handleFileDownload($event)" 
  (onFileDeleteAction)="handleFileDelete($event)" 
  [applicationFiles]="filesList"
  [(value)]="comment"
  (onSave)="sendCommentHandler()">
</app-adjunto-modal>

<loading *ngIf="loading"></loading>