import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BandejaSolicitudesComponent } from './bandeja-solicitudes.component';
import { BandejaSolicitudesRoutingModule } from './bandeja-solicitudes-routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent, 
  CustomInputFlexComponent} from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { DatosFiltroModalBandejaComponent } from './components/datos-generales-modal-bandeja/datos-filtro-modal-bandeja.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
@NgModule({
  declarations: [BandejaSolicitudesComponent,DatosFiltroModalBandejaComponent],
  imports: [
    CommonModule,
    BandejaSolicitudesRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    CustomInputFlexComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent,
    SharedModule
  ]
})
export class BandejaSolicitudesModule { }
