import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-line-divider',
  templateUrl: './line-divider.component.html',
  styleUrls: ['./line-divider.component.scss']
})
export class LineDividerComponent {

}
