import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasivosDatosPersonalesComponent } from './pasivos-datos-personales.component';
import { PasivosDatosPersonalesRoutingModule } from './pasivos-datos-personales-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent,AdjuntoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent, CustomButtonLinkComponent } from 'app/components';
import { DatosGeneralesComponent } from './components/datos-generales/datos-generales.component';
import { DatosDireccionComponent } from './components/datos-direccion/datos-direccion.component';
import { DatosDireccionLaboralComponent } from './components/datos-direccion-laboral/datos-direccion-laboral.component';
import { ProteccionDatosComponent } from './components/proteccion-datos/proteccion-datos.component';
import { ModoDesembolsoComponent } from './components/modo-desembolso/modo-desembolso.component';
import { DatosClienteComponent } from './components/datos-cliente/datos-cliente.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [PasivosDatosPersonalesComponent, DatosGeneralesComponent, DatosDireccionComponent, DatosDireccionLaboralComponent, ProteccionDatosComponent, 
    ModoDesembolsoComponent, DatosClienteComponent],
  imports: [
    CommonModule,
    PasivosDatosPersonalesRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    AdjuntoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent,
    CustomButtonLinkComponent,
    FormsModule,
    SharedModule
  ]
})
export class PasivosDatosPersonalesModule { }
