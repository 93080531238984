<div class="layout-container">
  <div class="formulario-container">
    <div class="tipo-cuenta">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Moneda</span>
      </div>
      <div class="opciones-radio">
        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="currency" name="tipoCuenta" value="SOL" class="radio-input"
            (change)="changeDisbusemenmtMode('SOL');clearSelectOption();">
          <span class="radio-label">Soles S/</span>
        </label>      
        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="currency" name="tipoCuenta" value="USD" class="radio-input"
            (change)="changeDisbusemenmtMode('USD');clearSelectOption();">
          <span class="radio-label">Dólares USD $</span>
        </label>      
      </div>
    </div>

    <div class="tipo-cuenta">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Cuenta de Ahorros</span>
      </div>
      
      <div class="form-group-cuenta">
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="listFilterSavingsAccountType"
          [isReadonly]="elementsReadOnly.includes('typeSavingAccount')" [(selectedValue)]="typeSavingAccount"
          (selectedValueChange)="typeSavingAccount=$event" [isError]="isErrorTypeSavingAccount">
        </app-custom-inpunt-select>
      </div>      
    </div>
  </div>
</div>


<div class="footer-buttons">
  <app-custom-button-link text="CANCELAR" (action)="close()"></app-custom-button-link>
  <app-custom-button text="CONFIRMAR" (action)="validarYCambiarComponente()"></app-custom-button>

</div>
<loading *ngIf="loading"></loading>