import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutenticacionComponent } from './autenticacion.component';
import { AutenticacionRoutingModule } from './autenticacion-routing.module';
import { CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, CustomInputLoginComponent, 
  CustomTabComponent,
  ModalResumenComponent} from 'app/components';

import { CustomButtonBorderComponent } from 'app/components/custom-button-border';
import { ValidacionFacialComponent } from './components/validacion-facial/validacion-facial.component';
import { ValidacionCorreoComponent } from './components/validacion-correo/validacion-correo.component';
import { SharedModule } from 'app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [AutenticacionComponent,  ValidacionFacialComponent, ValidacionCorreoComponent],
  imports: [
    CommonModule,
    AutenticacionRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    CustomButtonBorderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomInputLoginComponent,
    CustomTabComponent,
    ModalResumenComponent,
    SharedModule
  ]
})
export class AutentiacionModule { }
