import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { HelperService } from 'app/shared/services/helper.service';
import * as FileSaver from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-adjuntos-modal-bandeja',
  templateUrl: './datos-adjuntos-modal-bandeja.component.html',
  styleUrls: ['./datos-adjuntos-modal-bandeja.component.scss']
})
export class DatosAdjuntosModalBandejaComponent {

  customerApplication: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER_TRAY) ?? '{}');
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';
  approvingRole: boolean = this.rolCode === AppConstants.Roles.ANALISTA ? true : false;
  @Input() onlyView: boolean = true;

  loading: boolean = false;

  filesList: any = [];
  sizeFile: any = [];
  fileTemp!: File;
  showModal = false;

  @Input() width: number = 400;
  @Input() height: number = 350; 

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  constructor(
    private router: Router,
    private alertService: AlertService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private helperService: HelperService) 
  {
    
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.BANKS_TRANSFERS
    ]);
    await this.getAttachmentFiles(this.customerApplication.applicationId);
  }


  close() {
    this.onClose.emit(); 
  }

  // onLinkClick() {
  //   this.linkAction.emit();
  // }

  clickUpload() {
    // this.onClose.emit(); 
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    this.sizeFile = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SIZE_FILES && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  async readFile(event: any) {
    const file = event.target.files[0];
    if (!file) {
        return;
    }
    return await new Response(file).arrayBuffer();
  }

  base64ArrayBuffer(arrayBuffer: any) {
    var base64 = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63;               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
}

  async handleSelectFileAction(event: any) {
    try {
      const files = event.target.files;
      const file = files[0];

      if (this.helperService.isValidFileType(file.type)) {
        if (files && file) {
          const exist = this.filesList.filter((f: any) => f.name === file.name);
          if (exist.length > 0) {
            this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_EXIST, MessageSeverity.error);
            return;
          }

          const sizeParameterBytes = (this.sizeFile == null || this.sizeFile === undefined ||
          this.sizeFile[0] == null || this.sizeFile[0] === undefined) ? null : this.sizeFile[0];

          if (sizeParameterBytes != null) {
            let pesoMaximo = sizeParameterBytes.parameterDetCode001;
            if (file.size > sizeParameterBytes.parameterDetCode001 * 1000000) {
                this.alertService.showMessage('Validar Archivo', `Solo puede adjuntar archivos de tamaño menor o igual a ${pesoMaximo} MB.`, MessageSeverity.warn);
                return;
            }
          }

          this.fileTemp = <File>event.target.files[0];
          var extension = this.fileTemp.name.split('.');
          var keyValue = this.uuidv4() + '.' + extension[extension.length - 1];

          let model = {
            applicationId: this.customerApplication.applicationId,
            file: {
              name: this.fileTemp.name,
              type: AppConstants.AttachmentMode.Customer,
              fileId: keyValue,
              fileBase64: this.base64ArrayBuffer(await this.readFile(event)),
              contenType: this.fileTemp.type
            }
          };
          this.uploadFile(model);
        }

      } else {
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_INCORRECT, MessageSeverity.error);
      }

    } catch (error) {
      console.error(error);
    }    
  }

  uploadFile(model: any) {
    this.loading = true;
    this.applicationService.postUploadFile(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.filesList.push({name: this.fileTemp.name, key: model.file.fileId});
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_OK, MessageSeverity.success);
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  handleFileDownload(event: any) {
    this.downloadFile(event.key, event.name);
  }

  handleFileDelete(event: any) {
    const keyIndex = this.filesList.findIndex((item: any) => item.key === event.key);
    let model = {
      applicationId: this.customerApplication.applicationId,
      fileId: event.key,
      index: keyIndex
    };
    this.deleteFile(model);
  }

  downloadFile(fileId: string, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadFile(fileId).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });

        let extension = fileId.split('.');
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, AppConstants.MessageAlert.DOWNLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  deleteFile(model : any): void {
    this.loading = true;
    this.applicationService.postDeleteFile(model).subscribe({
      next: async (response: any) => {
        this.filesList.splice(model.index, 1);
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DELETE_FILE, AppConstants.MessageAlert.DELETE_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }

}
