import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "parameter";
  private BASE_URL: string = environment.parametersApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getParametersList(request : any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/GetParametersList`, request);
  }

}
