import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-bandeja-solicitudes',
  templateUrl: './bandeja-solicitudes.component.html',
  styleUrls: ['./bandeja-solicitudes.component.scss']
})
export class BandejaSolicitudesComponent {

  enterpriseId: string = sessionStorage.getItem(AppConstants.Session.ENTERPRISE) ?? '0';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  rolCode: string = sessionStorage.getItem(AppConstants.Session.USERROLID) ?? '';

  showCompanyColum: boolean = this.rolCode === AppConstants.Roles.ASESOR_VENTA ||
                              this.rolCode === AppConstants.Roles.ASESOR_VENTA_CALLCENTER ||
                              this.rolCode === AppConstants.Roles.SUPERVISOR_CALL ? false : true;

  showPasivosColum: boolean = this.rolCode === AppConstants.Roles.OPERADOR ||  this.rolCode === AppConstants.Roles.SUPERVISOR ? true : false;

  columnNames = this.showPasivosColum === true ? 
                ['Fecha de Solicitud', 'Producto', 'Cliente','Estado','N° Solicitud','Asesor','Acciones'] : 
                this.showCompanyColum === false ? 
                ['Fecha de Solicitud', 'Producto', 'Cliente','Estado','N° Solicitud','Asesor', 'Val. Cliente', 'Val. Correo','Acciones'] : 
                ['Fecha de Solicitud', 'Producto', 'Cliente','Estado','N° Solicitud','Asesor', 'Val. Cliente', 'Val. Correo', 'Empresa','Acciones'];
  // tusDatos: any[] = [];
  showModalFiltros =false;
  modalWidth: number = 480; 
  modalHeight: number = 380; 

  loading: boolean = false;
  dateFrom!: string;
  dateUntil!: string;
  applications: any = [];

  states: any = [];
  documents: any = [];
  state: string = '0';
  document: string = '0';
  products: any = [];
  productFilter: string = '0';
  applicationNumber: string = '';
  filterInput: any;
  documentNumber: string = '';
  max: number = 12;
  filtersOnCache: any = {};

  filterEnterprise: number = this.rolCode === AppConstants.Roles.ASESOR_VENTA_CALLCENTER || 
                             this.rolCode === AppConstants.Roles.ASESOR_VENTA || 
                             this.rolCode === AppConstants.Roles.SUPERVISOR_CALL ? +this.enterpriseId : 0;
  
  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private parameterService: ParameterService) 
  { 
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.dateFrom = `${year}-${month}-${day}`;
    this.dateUntil = `${year}-${month}-${day}`;
  }

  async ngOnInit() {
    this.initializeFilters();
    this.initializeCache();
    await this.getParameters([
      AppConstants.ParameterCode.DOCUMENT_TYPE,
      AppConstants.ParameterCode.PRODUCTS_TYPE,
      AppConstants.ParameterCode.STATES_FILTER
    ]);
    await this.getApplications(this.validateFiltersCache());    
  }

  handleClose() {
    this.showModalFiltros = false;

  }
  openModalFiltro() {
    this.showModalFiltros = true;
  }

  navigateFromParent(row: any) {
    // Lógica de navegación personalizada aquí, por ejemplo:
    sessionStorage.setItem(AppConstants.Session.CUSTOMER_TRAY, JSON.stringify(row));
    const path = row.product === AppConstants.Products.Savings ? 'bandeja-solicitudes-producto-pasivo' : 'bandeja-solicitudes-producto';
    this.router.navigate([path]);
  }

  getFilters() {
    var filter = {
        userId: "",
        productCode: "",
        applicationId: "",
        documentType: "1",
        documentNumber: "",
        channelId: 10,
        from: this.dateFrom,
        until: this.dateUntil,
        state: 0
    };
    return filter;
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  }

  async getApplications(model: any) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplications(model)).then((response: any) => {
      this.applications = response.map((element: any) => ({
          'Fecha de Solicitud': this.formatDate(element.createdDateTime),
          'Producto': element.productName,
          'Cliente': `${element.customerFullName}<br />${element.documentType}: ${element.documentNumber}`,
          'Estado': element.stateName,
          'N° Solicitud': element.applicationId,
          'Asesor': element.createdby,
          'Val. Cliente': element.stateAuthentication,
          'Val. Correo': element.stateEmailValidation,
          'Empresa': element.enterpriseName,
          'Acciones': "<a href='#'>Ver</a>",
          applicationId: element.applicationId,
          workflowInstanceId: element.workflowInstanceId,
          // offerProductCode: element.offerProductCode,
          // offerSubProductCode: element.offerSubProductCode,
          product: element.product,
          productCode: element.productCode,
          productName: element.productName,
          productNamImage: element.productNamImage,
          createdDateTime: element.createdDateTime,
          createdDateTimeFormat: this.formatDate(element.createdDateTime),
          customerFullName: element.customerFullName,
          stateCode: element.stateCode,
          stateName: element.stateName,
          createdby: element.createdby,
          updatedDateTime: element.updatedDateTime,
          updatedDateTimeFormat: this.formatDate(element.updatedDateTime)
        }));
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    const base = [
      { label: 'Todos', value: "0" }
    ];
  
    const documents = listParameters
      .filter(f => f.parameterCode === AppConstants.ParameterCode.DOCUMENT_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a, b) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map(element => ({
        label: element.parameterDetCode002,
        value: element.parameterDetCode001,
        length: element.parameterDetCode003
      }));
  
    this.documents = [...base, ...documents];
  
    // const states = listParameters
    //   .filter(f => f.parameterCode === AppConstants.ParameterCode.STATES_FILTER && f.state === AppConstants.StateFlag.Active)
    //   .sort((a, b) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
    //   .map(element => ({
    //     label: element.parameterDetCode002,
    //     value: element.parameterDetCode001,
    //     length: element.parameterDetCode003
    //   }));
  
    // this.states = [...base, ...states];

    this.states = [
      {
        label: 'Todos',
        value: '0',
      },
      {
        label: 'En Proceso Evaluación',
        value: '1',
      },
      {
        label: 'En Proceso Autentificación',
        value: '2',
      },
      {
        label: 'Pendiente Autorización',
        value: '3',
      },
      {
        label: 'Aprobado',
        value: '4',
      },
      {
        label: 'Anulado',
        value: '5',
      },
      {
        label: 'Finalizado',
        value: '6',
      },
      {
        label: 'Observado',
        value: '7',
      },
      {
        label: 'Rechazado',
        value: '8',
      },
      {
        label: 'Pendiente Aprobación',
        value: '9',
      }
    ];

    const products = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PRODUCTS_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode007, value: element.parameterDetCode002 }));
    
    this.products = [...base, ...products];
  }

  async seach() {  
    this.initializeFilters();
    await this.getApplications(this.validateFiltersCache()); 
  }

  handleLinkAction(data: { dateFrom: string, dateUntil: string, productFilter: string, applicationNumber: string }) {
    this.dateFrom = data.dateFrom;
    this.dateUntil = data.dateUntil;
    this.productFilter = data.productFilter;
    this.applicationNumber = data.applicationNumber;
  }

  initializeFilters() 
  {
    this.filterInput = {
      product: this.productFilter,
      applicationId: this.applicationNumber,
      dateFrom: this.dateFrom,
      dateUntil: this.dateUntil
    }
  }

  getFiltersCustom() {
    var filter = {
        productCode: this.productFilter == '0' ? '' : this.productFilter,
        applicationId: this.applicationNumber,
        documentType: this.document == '0' ? '' : this.document,
        documentNumber: this.documentNumber,
        channelId: 10,
        from: this.dateFrom,
        until: this.dateUntil,
        state: this.state,
        enterpriseId: this.filterEnterprise
    };
    return filter;
  }

  async clear() {
    this.state = '0';
    this.document = '0';;
    this.productFilter = '0';
    this.applicationNumber = '';
    this.documentNumber = '';
    
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.dateFrom = `${year}-${month}-${day}`;
    this.dateUntil = `${year}-${month}-${day}`;

    this.initializeFilters();
    this.clearFiltersCache();
    await this.getApplications(this.getFiltersCustom()); 
  }

  clearFiltersCache() {
    try {
      sessionStorage.setItem(AppConstants.Session.FILTERS_TRAY, JSON.stringify({}));
    } catch (error) {
      console.error(error);
    }
  }

  validateFiltersCache() {
    try {
      this.filtersOnCache = JSON.parse(sessionStorage.getItem(AppConstants.Session.FILTERS_TRAY) ?? '{}');

      if(Object.keys(this.filtersOnCache).length === 0) {
        const filter = this.getFiltersCustom();
        sessionStorage.setItem(AppConstants.Session.FILTERS_TRAY, JSON.stringify(filter));
        return filter;        
      }
      else {        
        if(JSON.stringify(this.getFiltersCustom()) === JSON.stringify(this.filtersOnCache)) {
          return this.filtersOnCache;
        }
        else {
          const newFilter = this.getFiltersCustom();
          sessionStorage.setItem(AppConstants.Session.FILTERS_TRAY, JSON.stringify(newFilter));

          this.productFilter = newFilter.productCode === '' ? '0' : newFilter.productCode;
          this.applicationNumber = newFilter.applicationId;
          this.document = newFilter.documentType === '' ? '0' : newFilter.documentType;
          this.documentNumber = newFilter.documentNumber;
          this.dateFrom = newFilter.from;
          this.dateUntil = newFilter.until;
          this.state = newFilter.state;

          return newFilter;
        } 
      }
    } catch (error) {
      console.error(error);
      return this.getFiltersCustom();
    }
  }

  initializeCache() {
    try {
      this.filtersOnCache = JSON.parse(sessionStorage.getItem(AppConstants.Session.FILTERS_TRAY) ?? '{}');

      if(Object.keys(this.filtersOnCache).length != 0) {
        this.productFilter = this.filtersOnCache.productCode === '' ? '0' : this.filtersOnCache.productCode;
        this.applicationNumber = this.filtersOnCache.applicationId;
        this.document = this.filtersOnCache.documentType === '' ? '0' : this.filtersOnCache.documentType;
        this.documentNumber = this.filtersOnCache.documentNumber;
        this.dateFrom = this.filtersOnCache.from;
        this.dateUntil = this.filtersOnCache.until;
        this.state = this.filtersOnCache.state;

        this.initializeFilters();
      }

    } catch (error) {
      console.error(error);
    }    
  }

}
