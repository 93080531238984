<app-header [headerText]="'Autenticación Dactilar'"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> 01234567</span>
  </div>
</div>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <img src="assets/imgValBiometrica.svg" alt="Descripción de la imagen" class="container-imagen">
  </div>
</div>

<div *ngIf="showWaitingAuth" class="contenedor-informacion">
    <div class="informacion-producto">
      <app-custom-button iconUrl="assets/img/finger-print.svg" text="INICIAR BIOMETRÍA DACTILAR" (action)="callAuthFingerprint()"></app-custom-button>
    </div>
  </div>

<div *ngIf="showSuccessAuth" class="contenedor-informacion">
    <div class="informacion-producto">
        <p class="titulo-resultado">¡Completado con Exito!</p>
    </div>
</div>

<div class="contenedor-botones">
  <div class="form-flex-container">
    <div class="container-atras">
      <app-custom-button-color text="Atrás" (action)="goAuth()"></app-custom-button-color>
    </div>
    <div class="container-continuar">
      <app-custom-button text="Continuar" [isDisabled]="botonDeshabilitado" (action)="goNext()"> </app-custom-button>
    </div>
  </div>

</div>

<app-footer></app-footer>

