<!-- componente-padre.component.html -->
<app-header [headerText]="'Datos Personales'"  headerImageSrc="assets/img/cliente_datos.svg"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <app-datos-generales *ngIf="componenteActivo === 'datosGenerales'" (cambiarComponente)="mostrarComponente('datosDireccion')"></app-datos-generales>
    <app-datos-direccion *ngIf="componenteActivo === 'datosDireccion'" (cambiarComponente)="mostrarComponente('proteccionDatos')" (retrocederComponente)="retrocederComponente('datosGenerales')"></app-datos-direccion>
    <app-proteccion-datos *ngIf="componenteActivo === 'proteccionDatos'" (cambiarComponente)="mostrarComponente('modoDesembolso')" (retrocederComponente)="retrocederComponente('datosDireccion')"></app-proteccion-datos>
    <app-modo-desembolso *ngIf="componenteActivo === 'modoDesembolso'" (retrocederComponente)="retrocederComponente('proteccionDatos')"></app-modo-desembolso> 

  </div>
</div>
<app-footer></app-footer>


