import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminEmpresasAccionesRoutingModule } from './admin-empresas-acciones-routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent, 
  CustomInputFlexComponent,
  CustomInputSwitchComponent} from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { AdminEmpresasAccionesComponent } from './admin-empresas-acciones.component';

@NgModule({
  declarations: [AdminEmpresasAccionesComponent],
  imports: [
    CommonModule,
    AdminEmpresasAccionesRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent,
    CustomInputFlexComponent,
    CustomInputSwitchComponent
  ]
})
export class AdminEmpresasAccionesModule { }
