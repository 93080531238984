import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare function openPopUpFingerPrint(nuDocUsuario: string, coTipoTrx: string, coCanalBf: string, tiDocCliente: string, nuDocCliente: string, idTxnBf: string): any;

@Component({
  selector: 'app-pasivos-validacion-dactilar',
  templateUrl: './pasivos-validacion-dactilar.component.html',
  styleUrls: ['./pasivos-validacion-dactilar.component.scss']
})
export class PasivosValidacionDactilarComponent implements OnInit {

  showWaitingAuth: boolean = true;
  showSuccessAuth: boolean = false;
  botonDeshabilitado: boolean = true;

  constructor(
    private router: Router) {
  }

  ngOnInit(): void {

  }

  goAuth() {
    this.router.navigate(['pasivos-autenticacion']);
  }

  goNext() {
    this.router.navigate(['pasivos-finalizacion']);
  }

  callAuthFingerprint() {
    this.testFingerPrintValidation();
    // this.showWaitingAuth = !this.showWaitingAuth;
    // this.showSuccessAuth = !this.showSuccessAuth;
    // this.botonDeshabilitado = !this.botonDeshabilitado;
  }

  testFingerPrintValidation() {
    try {
      let tranxId = this.generarNumeroAleatorio(8);
      console.log(tranxId);
      let responseZytrus = openPopUpFingerPrint("48066128", '1', "EIBS", "1", "48066128", tranxId);

      console.log(responseZytrus);

    } catch (error) {
      console.error(error);
    }    
  }

  generarNumeroAleatorio(longitud: number): string {
    if (longitud <= 0) {
        throw new Error("La longitud debe ser un número positivo.");
    }

    const min = Math.pow(10, longitud - 1);
    const max = Math.pow(10, longitud) - 1;

    const numeroAleatorio = Math.floor(Math.random() * (max - min + 1)) + min;

    return numeroAleatorio.toString();
  }

}
