<app-header [headerText]="'Validación Biométrica'"></app-header>
<div class="contenedor-padre">
  <div class="contenedor-solicitud">
    <span class="solicitud-texto">N° SOLICITUD: </span>
    <span class="solicitud-numero"> 01234567</span>
  </div>
</div>
<div class="contenedor-principal">
  <div class="contenedor-imagen">
    <img src="assets/imgValBiometrica.svg" alt="Descripción de la imagen">
  </div>
  <div class="contenedor-texto">
    <p>Con la lectura de mi huella digital confirmo la apertura de cuenta de ahorro sin cobro de mantenimiento
       o transferencia de cuenta de ahorros.</p>
       <p>
   Y la aceptación del tratamiento de datos personales y se muestre el enlace donde puede visualizar la política del Banco</p>

    <div  class="contenedor-boton">
      <app-custom-button  iconUrl="assets/huella.svg" (action)="enviarAutenticacion()"  text="Iniciar"></app-custom-button>
      <app-custom-button-color text="Enviar a Supervisor" ></app-custom-button-color>
    </div>
  </div>

</div>

<app-footer></app-footer>

