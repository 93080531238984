

<app-header [headerText]="'Bandeja de Solicitudes'" headerImageSrc="assets/bandejaSolicitudes.svg"></app-header>

<div class="layout-container">
  <div class="contenedor-filtros">
    <div class="filtro-inputs">
      <div class="form-group filtro-1">
        <label class="titulo-filtro">Estado</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="states" [(selectedValue)]="state">
        </app-custom-inpunt-select>
      </div>
  
      <div class="form-group filtro-2">
        <label class="titulo-filtro">Tipo Documento</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="documents" [(selectedValue)]="document">
        </app-custom-inpunt-select>
      </div>
  
    </div>
    <div class="filtro-inputs">
      <div class="form-group filtro-3">
        <label class="titulo-filtro">Numero de Documento</label>
        <app-custom-input [(value)]="documentNumber" [maxlength]="max"></app-custom-input>
      </div>
    </div>
  
    <div class="filtro-botones">
      <app-custom-button-color 
      text="limpiar" (action)="clear()"></app-custom-button-color>
  
      <app-custom-button
      text="buscar" (action)="seach()"></app-custom-button>
    
      <app-custom-button-circle   (action)="openModalFiltro(); $event.preventDefault()" iconUrl="assets/filtro.svg"></app-custom-button-circle>
    </div>
  </div>

  <div class="tabla-responsive">
    <app-custom-table-paginator [columnNames]="columnNames" [data]="applications" [navigate]="navigateFromParent.bind(this)"></app-custom-table-paginator>
  </div>
  
</div>

<app-info-modal *ngIf="showModalFiltros" [titulo]="'Filtros avanzados'"  [imagenUrl]="'assets/filtroBlanco.svg'" [width]="modalWidth" [height]="modalHeight" (onClose)="handleClose()">
  <app-datos-filtro-modal-bandeja (onClose)="handleClose()" [data]="products" [filter]="filterInput" (linkAction)="handleLinkAction($event)"></app-datos-filtro-modal-bandeja>
</app-info-modal>

<app-footer></app-footer>

<loading *ngIf="loading"></loading>