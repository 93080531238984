import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminParametrosAccionesRoutingModule } from './admin-parametros-acciones-routing.module';
import { CustomTablePaginatorComponent,CustomButtonComponent, CustomInputComponent,CustomButtonColorComponent, 
  HeaderComponent,CustomInpuntSelectComponent,CustomTableSimulatorComponent,FooterComponent,LineDividerComponent,StepperComponent, InfoModalComponent, CustomDatepickerComponent, CustomInputLoginComponent, 
  CustomInputFlexComponent,
  CustomInputSwitchComponent} from 'app/components';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { AdminParametrosAccionesComponent } from './admin-parametros-acciones.component';


@NgModule({
  declarations: [AdminParametrosAccionesComponent],
  imports: [
    CommonModule,
    AdminParametrosAccionesRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    CustomDatepickerComponent,
    CustomInputLoginComponent,
    CustomInputFlexComponent,
    CustomInputSwitchComponent
  ]
})
export class AdminParametrosAccionesModule { }
