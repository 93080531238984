<div class="custom-input-container">
  <input [type]="passwordVisible ? 'text' : 'password'" class="custom-input" [placeholder]="placeholderText"  [(ngModel)]="value" (ngModelChange)="onValueChange($event)">
  <div class="input-icon-container">
      <img 
      [src]="passwordVisible ? 'assets/eye-open.svg' : 'assets/eye-close.svg'" 
      alt="Icono" 
      class="input-icon" 
      (click)="togglePasswordVisibility()">
  </div>
</div>
