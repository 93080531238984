import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router,ActivatedRoute} from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-parametros-acciones',
  templateUrl: './admin-parametros-acciones.component.html',
  styleUrls: ['./admin-parametros-acciones.component.scss']
})
export class AdminParametrosAccionesComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 12;
  textoCabecera: string="";
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  columnNames = ['Detalle 1', 'Detalle 2','Detalle 3', 'Detalle 4','Detalle 5', 'Detalle 6', 'Acciones'];
  tusDatos: any[] = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const accion = params['parametro']; 
      console.log("VERRR",accion)
      if (accion === 'add') {
        this.textoCabecera = "Agregando Parámetros";
      } else {
        this.textoCabecera = "Actualizar Parámetros";
      }
    });
  }
  
addDetails() {
  const newDetails = [
    { "Detalle 1": "6", "Detalle 2": "Empresa Nueva 1", "Detalle 3": "PERÚ", "Detalle 4": "ACTIVO", "Detalle 5": "adminUser", 
    "Detalle 6": "2023-03-01", "Acciones": "<a href='#'>EDITAR</a>" },
  ];

  this.tusDatos = [...this.tusDatos, ...newDetails];
}

}
