import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-parametros',
  templateUrl: './admin-parametros.component.html',
  styleUrls: ['./admin-parametros.component.scss']
})
export class AdminParametrosComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 4;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  columnNames = ['Código', 'Parámetro','Usuario Creación', 'Fecha Creación', 'Acciones'];
  tusDatos: any[] = [];
  showModalFiltros =false;
  modalWidth: number = 480; 
  modalHeight: number = 380; 
  constructor(private router: Router) { }
  ngOnInit(): void {

    this.tusDatos = [
      {
        "Código": "1",
        "Parámetro": "Usuario de consulta de Biometría",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "2",
        "Parámetro": "Mensajes de Política",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "3",
        "Parámetro": "Tabla de Factores",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "4",
        "Parámetro": "Tipos de documentos",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      }
    ];
    
    
    
  }

  addUsuario(accion: string){
    this.router.navigate(['admin-parametros-acciones'], { queryParams: { parametro: accion } });
  }
  

}
