import { Component, Input,EventEmitter,Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule,FormsModule],
  selector: 'app-custom-input-password',
  templateUrl: './custom-input-password.component.html',
  styleUrls: ['./custom-input-password.component.scss']
})
export class CustomInputPasswordComponent {

  @Input() placeholderText: string = '';
  @Input() label: string = '';
  @Input() width: string = 'auto'; 
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  passwordVisible: boolean = false;

  onValueChange(value: string) {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

}
