import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-datos-filtro-modal-bandeja',
  templateUrl: './datos-filtro-modal-bandeja.component.html',
  styleUrls: ['./datos-filtro-modal-bandeja.component.scss']
})
export class DatosFiltroModalBandejaComponent {

  dateFrom: string = '';
  dateUntil: string = '';
  productFilter: string = '0';
  applicationNumber: string = '';

  typeInputNumber: string = 'tel';
  max: number = 12;

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Input() data!: any[];
  @Input() filter!: any;
  @Output() linkAction = new EventEmitter<{ dateFrom: string, dateUntil: string, productFilter: string, applicationNumber: string }>();

  constructor(private router: Router) {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.dateFrom = `${year}-${month}-${day}`;
    this.dateUntil = `${year}-${month}-${day}`;
  }

  ngOnInit() {
    this.setValues();
  }

  close() {
    this.onClose.emit(); 
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  emitLinkAction() {
    this.linkAction.emit({ dateFrom: this.dateFrom, dateUntil: this.dateUntil, productFilter: this.productFilter, applicationNumber: this.applicationNumber });
  }

  setValues() {
    try {
      this.dateFrom= this.filter.dateFrom;
      this.dateUntil = this.filter.dateUntil;
      this.productFilter = this.filter.product;
      this.applicationNumber = this.filter.applicationId;
    } catch (error) {
      console.log(error);
    }
  }
}
