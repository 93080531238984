import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminParametrosComponent } from './admin-parametros.component';

const routes: Routes = [
  {path: '',component: AdminParametrosComponent},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminParametrosRoutingModule { }
