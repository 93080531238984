import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomInputComponent, CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ApplicationService } from 'app/services/application.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { OffersService } from 'app/services/offers.service';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  posts: any[] = [];
  loading: boolean = false;
  searchDate!: Date;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;

  constructor(
    public dialog: MatDialog, 
    private cdr: ChangeDetectorRef,
    private router: Router,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private offersService: OffersService
  ) {
    this.esMovil = window.innerWidth <= 768;
  }

 
  async ngOnInit() {
    await this.getDocuments();
    this.productos = [];
  }
  

  
  isSearchDisabled: boolean = true; 
  selectedTypeDocument = '1';
  mostrarInformacion = false;
  dni = '';
  nombreCliente = '';
  numeroResultados = 0;
  
  tituloModal='';
  subTituloModal='';
  parrafo='';
  textoEnlaceModal='';


  miPlaceholder = "Número documento";

  documentTypeDescription: string = '';
  documents: any = [];
  documentForm: string = '';
  typeInputDocument: string = 'text';
  max: number = 8;
  valNumber: boolean = true;
  @ViewChild(CustomInputComponent) customInput!: CustomInputComponent;
  offersAvailable: boolean = false;
  offers: any = [];
  productos: any = [];
  offersFilter: any = [];

  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';

  ngAfterViewInit() {
    this.cdr.detectChanges();

  }

  abrirModal(): void {
    // this.dialog.open(DynamicModalComponent, {
    //   width: '421px',
    //   height:'330px',
    //   data: {
    //     icon: 'report_problem', // Reemplaza con el icono deseado
    //     title: 'alerta',
    //     message: 'No cumple con las políticas del Banco.',
    //     cliente: 'JUAN RUBEN RAMIREZ',
    //     dni: '12345678'
    //   }
    // });
    this.mostrarInformacion = true;
  }

  cerrarModal() {
    this.documentForm = '';
    this.showModal = false;
    this.customInput.focusInput();
  }

  accionPersonalizada(){
    this.showModal = false;
  }

  redireccionar(producto: any) {
    
    this.router.navigate(['simulador-oferta'], { queryParams: producto });
  }

  openModal(title: string, subtitle: string, description: string, labelButton: string) {
    this.showModal = true;
    this.tituloModal= title;
    this.subTituloModal= subtitle;
    this.parrafo = description;
    this.textoEnlaceModal= labelButton;
  }

  async getDocuments() {
    this.loading = true;
    let model = [AppConstants.ParameterCode.DOCUMENT_TYPE];
    this.parameterService.getParametersList(model).subscribe({
      next: async (response: any) => {
        let parameters = (response.filter((f: any) => f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        parameters.forEach((element: any) => {
          this.documents.push({ label: element.parameterDetCode002, value: element.parameterDetCode001, length: element.parameterDetCode003 });
        });
        this.typeOperation();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  typeOperation(): void {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if (document === undefined) {
        this.max = 8;
        this.valNumber = true;
    } else {
        this.documentTypeDescription = document.label;
        this.max = document.length;
        this.documentForm = '';
        if (document.label.toUpperCase().includes('C.E')
            || document.label.toUpperCase().includes('CE')
            || document.label.toUpperCase().includes('CARNE')
            || document.label.toUpperCase().includes('EXTRANJERIA')) {
            this.valNumber = false;
            this.typeInputDocument = 'text';
        } else {
            this.valNumber = true;
            this.typeInputDocument = 'tel';
        }
    }
  }

  removeSpacesDocument() {
    this.documentForm = this.documentForm.replace(/ /g, ' ');
  }

  onlyNumber() {
    if (this.valNumber) {
    } else {
      this.documentForm = this.documentForm.replace(/[^a-zA-Z0-9_-]/g, '');
    }
  }

  searchOffer() {
    let document = this.documents.find((f: any) => f.value === this.selectedTypeDocument);
    if(this.documentForm.length == document.length) {
      let model = {
        "documentType": this.selectedTypeDocument,
        "documentNumber": this.documentForm,
        "userId": this.usuario,
        "product": AppConstants.Products.Loans
      }
      this.validateApplicationInProgress(model);
    }
  }

  validateApplicationInProgress(model: any) {
    this.loading = true;   
    // this.callGetOffers(model);
    this.applicationService.returnApplication(model).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.status === 204) {
          this.callGetOffers(model);
        } else {
          if (response.body.createdBy === this.usuario) {
            sessionStorage.setItem(AppConstants.Session.APPLICATION_ID, response.body.applicationId);
            sessionStorage.setItem(AppConstants.Session.WORKFLOW_ID, response.body.instannceId);

            var client = new ClienteSession();
                client.documentTypeDesciption = response.body.documentTypeDescription;
                client.documentType = response.body.documentType;
                client.documentNumber = response.body.documentNumber;
                client.fullName = response.body.fullName;
                client.userCreation = this.usuario;


            var product = new ProductoSession();
                product.image = response.body.product.image;
                product.name = response.body.product.name;

            sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
            sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
            sessionStorage.setItem(AppConstants.Session.SIMULACION, JSON.stringify({ loanAmountFormat: response.body.applicationAmountFormat}));

            this.router.navigateByUrl(response.body.path);
            this.loading = false;
          }
          else {
            this.loading = false;
            this.alertService.showMessage('Error', 'La solicitud '+ response.body.applicationId +' ya esta siendo tomada por el usuario ' + response.body.createdBy, MessageSeverity.error);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.log('Error:', error);
        this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, AppConstants.MessageAlert.RETRIEVE_APPLICATION_MESSAGE, MessageSeverity.error);
      },
      complete: () => {
      }
    });
  }

  callGetOffers(model: any) {    
    this.clear();
    this.loading = true;
    this.searchDate = this.getCurrentDateTimeUTC5();
    this.offersService.postObtenerOfertas(model).subscribe({
      next: async (response: any) => {
        try {
          const lisFilter = response.datos.length > 0 && response.meta.totalRegistros > 0 ?
                          response.datos.filter((item: any) => item.nombre.includes("PTMN") && item.nombre.includes("PS02")).sort((a: any, b: any)  => {
                            if (b.monto !== a.monto) {
                              return b.monto - a.monto;
                            } else {
                              return b.plazo - a.plazo;
                            }
                          }) : [];

          if(lisFilter.length > 0) {
            this.callComplianceClient(model);

            this.dni = lisFilter[0].cliente.documento.numero;
            this.nombreCliente = lisFilter[0].cliente.nombresCompletos;
            this.numeroResultados = lisFilter.length;
            
            this.offers = lisFilter;

            //this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS, AppConstants.MessageAlert.OFFERS_RETRIEVE_SUCCESS, MessageSeverity.success);
          }
          else {
            this.loading = false;
            this.openModal('SIN OFERTA', 'No presenta ofertas por el momento', `DNI: ${this.documentForm}`, 'CERRAR');
          }
          
        } catch (error) {
          console.error(error);
        }      
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_OFFERS_TITLE, AppConstants.MessageAlert.OFFERS_RETRIEVE_ERROR, MessageSeverity.error);
        console.error(error);
      },
      complete: () => {
        console.info('complete')
      }
    });
  }

  callComplianceClient(model: any) {
    this.loading = true;
    this.offersAvailable = false;
    this.customerService.postAdmissionCustomer(model).subscribe({
      next: async (response) => {
        this.loading = false;


        if (response.meta && response.meta.mensajes && response.meta.mensajes.length > 0) {
          let codigoMensaje = response.meta.mensajes[0].codigo;
          if (codigoMensaje === "0") {
            if(response.datos && response.datos.respuestaOperacion && response.datos.respuestaOperacion.flagOperacion == "1") {

              // const plazoMin = Math.min(...this.offers.map((oferta: any) => oferta.plazo));
              const plazoMax = Math.max(...this.offers.map((oferta: any) => oferta.plazo));
              
              // const montoMin = Math.min(...this.offers.map((oferta: any) => oferta.monto));
              // const montoMax = Math.max(...this.offers.map((oferta: any) => oferta.monto));

              for (let index = 0; index < this.offers.length; index++) {
                let offer = this.offers[index];

                let model = {
                  documentType: this.selectedTypeDocument,
                  documentNumber: offer.cliente.documento.numero,
                  fullName: offer.cliente.nombresCompletos,
                  offerid: offer.codigo,
                  campaignCode: offer.nombre,
                  monto: +offer.monto,
                  producto: offer.producto.descripcion,
                  codigoSubproducto: offer.subProducto.codigo,
                  nombre: offer.subProducto.codigo === 'PRELD' ? 'PLD CONSUMO DRACMA' : offer.subProducto.descripcion,
                  nombreBase: offer.subProducto.descripcion,
                  fechaInicio: offer.fechaInicio.split(" ")[0],
                  fechaFin: offer.fechaFin.split(" ")[0],
                  tea: offer.tasa,
                  plazo: offer.plazo,
                  linkTexto: 'Escoger',
                  url: offer.subProducto.codigo === 'PRELD' ? 'assets/dinero.svg' : 'assets/alcancia.svg',
                  moneda: offer.moneda === 'USD' ? 'SOL' : offer.moneda,
                  monedaSimbolo: offer.moneda === 'USD' ? 'S/ ' : '$ ',
                  prioridad: offer.prioridad,
                  tipo: offer.tipo,
                  plazoMinimo: offer.plazo,
                  plazoMaximo: plazoMax
                };
                
                this.productos.push(model);
                this.offersFilter.push(model);
              }

              this.mostrarInformacion = true;
            }
            else {
              let title = response.datos.reglaOriginacion[0].mensaje;
              // let description = response.datos.reglaOriginacion[0].descripcion + ' - ' + response.datos.reglaOriginacion[0].id;
              let description = response.datos.reglaOriginacion[0].descripcion;
              this.openModal(title, description, '', 'CERRAR');
            }

          } else {
            this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
          }
        } else {
          this.alertService.showMessage(AppConstants.TitleAlert.COMPLIANCE_TITLE, AppConstants.MessageAlert.COMPLIANCE_RETRIEVE_ERROR, MessageSeverity.error);
        }
  
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      }
    });
  }

  clear () {
    this.offers = [];
    this.productos = [];
    this.dni = '';
    this.nombreCliente = '';
    this.numeroResultados = 0;
  }

  async selectProduct(row: any) {
    //Validar Producto Permitido por Pefil
    if(await this.validateFlujoAcces()) {

      var client = new ClienteSession();
      client.documentTypeDesciption = this.documentTypeDescription;
      client.documentType = row.documentType;
      client.documentNumber = row.documentNumber;
      client.fullName = row.fullName;
      client.userCreation = this.usuario;
      client.offersearchdate = this.searchDate;

      const parts = row.campaignCode.split("-");
      const producto = parts.length >= 2 ? parts[0] : '';
      const subproducto = parts.length >= 2 ? parts[1] : '';

      var product = new ProductoSession();
          product.image = row.url;
          product.offerid = row.offerid;
          product.campaignCode = row.campaignCode;
          product.amount = row.monto;
          product.product = row.producto;
          product.subproductCode = row.codigoSubproducto;
          product.name = row.nombre;
          product.nameBase = row.nombreBase;
          product.validityStart = row.fechaInicio;
          product.validityEnd = row.fechaFin;
          product.tea = row.tea;
          product.installments = row.plazo;
          product.installmentsMax = row.plazoMaximo;
          product.currency = row.moneda;
          product.currencySymbol = row.monedaSimbolo;
          product.priority = row.prioridad;
          product.type = row.tipo;
          product.offerProductCode = producto;
          product.offerSubProductCode = subproducto;

      sessionStorage.setItem(AppConstants.Session.CLIENT, JSON.stringify(client));    
      sessionStorage.setItem(AppConstants.Session.PRODUCT, JSON.stringify(product));
      sessionStorage.setItem(AppConstants.Session.OFFERS, JSON.stringify(this.offersFilter));
    }
    else {
      return;
    }
    
    this.router.navigate(['simulador-oferta']);
  }

  async validateFlujoAcces() {
    return true;
  }

  getCurrentDateTimeUTC5(): Date {
    const currentDateUTC = new Date();
    const utcMinus5Offset = 5 * 60 * 60 * 1000;
    const currentDateUTC5 = new Date(currentDateUTC.getTime() - utcMinus5Offset);  
    return currentDateUTC5;
  }

}
