<img *ngIf="!esMovil" src="assets/img/05.svg" class="stepper">
<img *ngIf="esMovil" src="assets/05.svg" class="stepper">
<div class="layout-container">
  <div class="container-datos-cliente">
    <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
  </div>
  <div class="formulario-container">
    <div class="form-row opciones-container">
      <div class="form-group boton-container">
        <button class="boton" [ngClass]="{'boton-abono': botonActivo === 'abono'}"
          (click)="mostrarAbonoEnCuenta()">Abono en<br>cuenta</button>
      </div>
      <div class="form-group boton-container">
        <button class="boton" [ngClass]="{'boton-abono': botonActivo === 'interbancario'}"
          (click)="mostrarAbonoEnCuentaInterbancaria()">Abono en<br>cuenta interbancaria</button>
      </div>
    </div>
    <div class="abono-cuenta" *ngIf="mostrarAbonoCuenta">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Seleccione</span>
      </div>
      <div class="opciones-radio">
        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="subModeDisbursement" name="tipoCuenta" value="nueva" class="radio-input"
            (change)="changeDisbusemenmtMode('nueva')">
          <span class="radio-label">Cuenta nueva</span>
        </label>

        <label class="opcion-radio">
          <input type="radio" [(ngModel)]="subModeDisbursement" name="tipoCuenta" value="existente" class="radio-input"
            (change)="changeDisbusemenmtMode('existente')">
          <span class="radio-label">Cuenta existente</span>
        </label>
      </div>

      <div class="opciones-radio" *ngIf="tipoCuentaSeleccionado === 'existente'">
        <div class="form-group-cuenta">
          <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="currentAccounts"
            [isReadonly]="elementsReadOnly.includes('account')" [(selectedValue)]="account"
            (selectedValueChange)="account=$event" [isError]="isErrorAccount">
          </app-custom-inpunt-select>
        </div>
      </div>
    </div>

    <div class="abono-cuenta-interbancaria" *ngIf="mostrarAbonoCuentaInterbancaria">
      <div class="seleccion-indicador">
        <span class="punto"></span><span class="texto-seleccion">Seleccione entidad</span>
      </div>

      <div class="form-group-cuenta">
        <label>Seleccionar Entidad Financiera</label>
        <app-custom-inpunt-select placeholder="Seleccionar" [width]="'190px'" [options]="listBanks"
          [isReadonly]="elementsReadOnly.includes('typeBank')" [(selectedValue)]="typeBank"
          (selectedValueChange)="typeBank=$event" [isError]="isErrorTypeBank">
        </app-custom-inpunt-select>
      </div>
      <br>
      <div class="form-group-cuenta">
        <label>Número de cuenta destino (CCI)</label>
        <app-custom-input [(value)]="accountNumberInterbank" [isError]="isErrorAccountNumberInterbank" [type]="'tel'"
          [maxlength]="max" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('accountNumberInterbank')"
          (keyup)="resetErrorState('accountNumberInterbank')">
        </app-custom-input>
      </div>
      <br>
      <div class="container-propietario">
        <label class="texto-propietario">El propietario de Cuenta deberá ser el mismo cliente.</label>
      </div>
    </div>


  </div>

</div>


<div class="container-buttons">
  <div class="footer-buttons">
    <a href="#" class="link-atras">ATRÁS</a>
    <app-custom-button text="continuar" (action)="redireccionarResumen()"></app-custom-button>
  </div>
</div>

<app-adjunto-modal *ngIf="showModal" 
  (onClose)="handleClose()" 
  (onFileSelectedAction)="handleSelectFileAction($event)" 
  (onFileDownloadAction)="handleFileDownload($event)" 
  (onFileDeleteAction)="handleFileDelete($event)" 
  [applicationFiles]="filesList"
  [(value)]="comment"
  (onSave)="sendCommentHandler()">
</app-adjunto-modal>

<loading *ngIf="loading"></loading>