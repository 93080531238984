<app-header [headerText]="'4. Resumen'"></app-header>
<div class="contenedor-informacion">
  <div class="informacion-producto">
    <div class="container-info-producto">
      <div class="lado-izquierdo">
        <span class="punto"></span>
        <span class="texto-producto">Producto</span>
      </div>
      <!-- <app-custom-button-color text="EDITAR"></app-custom-button-color> -->
    </div>
  </div>


  <div class="informacion-producto">
    <div class="caja-redondeada-sombreada">
      <div class="contenido-caja">
        <div class="contenido-caja-container">
          <div class="seccion-imagen">
            <img src="assets/alcancia.svg" alt="Descripción de la imagen">
            <div class="detalle-titulo">
              <span class="titulo-producto">{{ product.name }}</span>
              <p class="valor-numerico">{{ simulation.loanAmountFormat }}</p>
            </div>
          </div>
          <div class="container-detalles">
            <div class="seccion-detalles">
              <div class="detalle detalle-productos">                
                <p>
                  <span class="titulo">Valor de cuota:</span>
                  {{ customer?.monthlyFee | currency: (customer?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (customer?.currencyCode === 'SOL' ? 'S/' : '$') }}
                </p>                
                <p><span class="titulo">TEA:</span> {{ customer?.tea }} %</p>
              </div>
              <div class="detalle detalle-productos">
                <p><span class="titulo">Número de cuotas:</span> {{ customer?.numberInstallments }}</p>
                <p><span class="titulo">TCEA:</span> {{ customer?.tcea }} %</p>
              </div>              
            </div>
            <div class="seccion-detalles">
              <p><span class="titulo">{{ customer?.desgravamenCode === '005' ? 'Seguro Desgravamen Básico:' : customer?.desgravamenCode === '008' ? 'Seguro Desgravamen con Devolución:' : 'Desgravamen:' }}</span> {{ customer?.desgravamen }} %</p>
            </div>
            <div class="seccion-detalles">
              <p><span class="titulo">Primer pago:</span> {{ firstPaymentDateFormat | dateFormat }}</p>
            </div>
            <div class="seccion-detalles">
              <p><span class="titulo">Fecha de Desembolso:</span> {{ customer?.disbursementDate | dateFormat }}</p>
            </div>
            <div *ngIf="optionalInsurancesContracted.length > 0">
              <hr class="linea-separacion">

              <div class="seccion-detalles">
                <div class="detalle detalle-productos" *ngFor="let seguro of optionalInsurancesContracted">
                  <p class="titulo-seguro">{{ seguro.insuranceName }}</p>
                  <p class="nombre-plan">{{ seguro.planName }}</p>
                  <p><span class="titulo">Monto bruto prima única</span> {{ seguro?.amountBruto | currency: (seguro?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (seguro?.currencyCode === 'SOL' ? 'S/' : '$') }}</p>
                  <p><span class="titulo">Monto neto prima única</span>  {{ seguro?.amountNeto | currency: (seguro?.currencyCode === 'SOL' ? 'PEN' : 'USD'): (seguro?.currencyCode === 'SOL' ? 'S/' : '$') }}</p>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>    
  </div>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos Personales</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Nombre:</span>{{ customer?.name }} {{ customer?.middleName}} {{ customer?.lastname }} {{ customer?.secondLastname}}</p>
          <p><span class="titulo">{{ client.documentTypeDesciption }}:</span> {{ customer?.documentNumber }}</p>
        </div>
        <div class="inf-detalle">
          <p><span class="titulo">Celular:</span> {{ customer?.personalCellPhoneNumber }}</p>
          <p><span class="titulo">Correo:</span> {{ customer?.emailAddress }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModal();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>

  <div class="informacion-producto">
      <div class="detalles-personales">
        <div class="producto-container">
          <div class="titulo-detalle">
            <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Datos de Dirección</p>
          </div>
          <div class="inf-detalle">
            <p> {{ customer?.customerStreetName }} {{ customer?.customerStreetNumber }}</p>
          </div>
        </div>
        <div class="boton-editar">
          <div class="detalle-boton">
            <app-custom-button-color text="EDITAR" (action)="openModalDireccion();"></app-custom-button-color>
          </div>
        </div>
      </div>
  </div>

    <hr>

  <div class="informacion-producto">
      <div class="detalles-personales">
        <div class="producto-container">
          <div class="titulo-detalle">
            <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Otros Datos</p>
          </div>
          <div class="inf-detalle">
            <p> Datos del Empleador:</p>
            
          </div>
          <div class="inf-detalle">
            <p><span class="titulo">RUC Empresa:</span> {{ customer?.companyCode }}</p>
            <p><span class="titulo">Nombre Empresa:</span> {{ customer?.companyName }}</p>
          </div>
        </div>
        <div class="boton-editar">
          <div class="detalle-boton">
            <app-custom-button-color text="EDITAR" (action)="openModalOtros();"></app-custom-button-color>
          </div>
        </div>
      </div>

  </div>

  <hr>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Modo de Desembolso</p>
        </div>
        <div class="inf-detalle">
          <p> {{ disbusementName }}</p>
          <div *ngIf="customer?.disbursementType==2">
            <p><span class="titulo">Numero de Cuenta:</span> {{ customer?.currentAccountNumber}}</p>
          </div>
          <div *ngIf="customer?.disbursementType==3">
            <p><span class="titulo">CCI:</span> {{ customer?.interbankAccountNumber}}</p>
          </div>
        </div>
        <div class="inf-detalle" *ngIf="customer?.disbursementType==3">
          <p><span class="titulo">Banco Destino:</span> {{ customer.destinationBankName}}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModalDesembolso();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="informacion-producto">
    <div class="detalles-personales">
      <div class="producto-container">
        <div class="titulo-detalle">
          <img src="assets/img/circulo.svg" alt="Descripción de la imagen" class="img-circulo"><p class="valor-numerico">Archivos Adjuntos</p>
        </div>
        <div *ngFor="let file of filesList" class="inf-detalle">
          <p>{{ file.name }}</p>
        </div>
      </div>
      <div class="boton-editar">
        <div class="detalle-boton">
          <app-custom-button-color text="EDITAR" (action)="openModalAdjuntos();"></app-custom-button-color>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <!-- <div *ngIf="!esMovil" class="contenedor-boton">
    <app-custom-button-color text="No Acepta"></app-custom-button-color>
    <app-custom-button text="Acepta" (action)="redireccionarFinalizacion()"></app-custom-button>
  </div> -->

  <div  class="contenedor-boton">
    <app-custom-button-color  [buttonWidth]="'160px'" text="No Acepta" (action)="handleSaveCancellationApplication()"></app-custom-button-color>
    <app-custom-button text="Acepta" (action)="nextStepApplication()"></app-custom-button>
  </div>
</div>


<app-footer></app-footer>

<app-info-modal *ngIf="showModal" [titulo]="'Datos Personales'" [width]="modalWidth" [height]="modalHeight" (onClose)="handleClose()">
  <app-datos-generales-modal (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-generales-modal>
</app-info-modal>

<app-info-modal *ngIf="showModalDireccion" [titulo]="'Datos de Dirección'" [width]="modalWidthDireccion" [height]="modalHeightDireccion" (onClose)="handleClose()">
  <app-datos-direccion-modal (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-direccion-modal>
</app-info-modal>

<app-info-modal *ngIf="showModalOtros" [titulo]="'Otros Datos'" [width]="modalWidthOtros" [height]="modalHeightOtros" (onClose)="handleClose()">
  <app-datos-otros-modal (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-otros-modal>
</app-info-modal>

<app-info-modal *ngIf="showModalDesembolso" [titulo]="'Modo de Desembolso'" [width]="modalWidthDesembolso" [height]="modalHeightDesembolso" (onClose)="handleClose()">
  <app-datos-desembolso-modal-bandeja (onClose)="handleClose()" (linkAction)="handleLinkAction($event)"></app-datos-desembolso-modal-bandeja>
</app-info-modal>

<app-datos-adjuntos-modal *ngIf="showModalAdjuntos" (onClose)="handleClose()" (linkAction)="handleLinkAttachmentAction($event)"></app-datos-adjuntos-modal>

<!-- <app-info-modal *ngIf="showModalAdjuntos" [titulo]="'Adjuntos'" [width]="modalWidthAdjuntos" [height]="modalHeightAdjuntos" (onClose)="handleClose()">
  <app-datos-adjuntos-modal (onClose)="handleClose()"></app-datos-adjuntos-modal>
</app-info-modal> -->

<loading *ngIf="loading"></loading>

