import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasivosAutenticacionComponent } from './pasivos-autenticacion.component';
import { PasivosValidacionFacialComponent } from './components/pasivos-validacion-facial/pasivos-validacion-facial.component';
import { PasivosValidacionDactilarComponent } from './components/pasivos-validacion-dactilar/pasivos-validacion-dactilar.component';

const routes: Routes = [
  {path: '', component: PasivosAutenticacionComponent},
  {path: 'facial', component: PasivosValidacionFacialComponent},
  {path: 'dactilar', component: PasivosValidacionDactilarComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasivosAutenticacionRoutingModule { }
