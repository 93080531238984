import { Component, EventEmitter, Output } from '@angular/core';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-validacion-correo',
  templateUrl: './validacion-correo.component.html',
  styleUrls: ['./validacion-correo.component.scss']
})
export class ValidacionCorreoComponent {
  
  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  customer: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.CUSTOMER) ?? '{}');
  application: any = {};

  email: string = this.customer.emailAddress;
  otpCode: string = '';

  @Output() validacionCompletadaCorreo = new EventEmitter<void>();

  typeInputNumber: string = 'tel';
  max: number = 6;

  loading: boolean = false;
  
  isErrorEmail: boolean = false;
  isErrorOtpCode: boolean = false;
  validationIncomplete = true;
  validationInProgress = false;
  validationCompleted = false;
  elementsReadOnly: any = ['email'];
  
  constructor(
    private applicationService: ApplicationService,
    private alertService: AlertService) 
  {
    
  }

  async ngOnInit() {
    await this.retrieveApplication(this.applicationId);
    await this.checkValidatedEmail(this.applicationId);
  }

  enviarValidacion() {
    this.showSections(false, false, true);
    this.validacionCompletadaCorreo.emit();
   
  }

  resetErrorState() {
    this.isErrorOtpCode = this.otpCode === null ? true : 
                          this.otpCode === undefined ? true : 
                          this.otpCode === '' ? true : 
                          this.otpCode.length === this.max ? false : true;
  }
  resetErrorEmail() {
    this.isErrorEmail = this.email === null ? true : 
                        this.email === undefined ? true : 
                        this.email === '' ? true : false;
  }

  showSections(incomplete : boolean, inprogress: boolean, completed: boolean) {
    this.validationIncomplete = incomplete;
    this.validationInProgress = inprogress;
    this.validationCompleted = completed;
  }

  async retrieveApplication(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(applicationId)).then((response: any) => {      
      this.application  = response;
      this.email = this.application.emailAddress;
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      // this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async retrySend() {
    let model = {
      applicationId: this.applicationId,
      email: this.email,
      createdBy: this.usuario
    };
    this.loading = true;
    await lastValueFrom(this.applicationService.postSendEmailVerification(model)).then((response: any) => {
      if(response.successSendMail) {
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_EMAIL_SUCCES, MessageSeverity.success);
      }
      else {
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, response.error, MessageSeverity.error);  
      }
      this.loading = false;        
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });
  }

  async sendOtpEmail() {
    this.resetErrorEmail();

    const requiredElements = [
      this.isErrorEmail
    ];

    if (requiredElements.every(element => !element)) {
      let model = {
        applicationId: this.applicationId,
        email: this.email,
        createdBy: this.usuario
      };
      this.loading = true;
      await lastValueFrom(this.applicationService.postSendEmailVerification(model)).then((response: any) => {
        if(response.successSendMail) {
          this.showSections(false, true, false);
          this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_EMAIL_SUCCES, MessageSeverity.success);
        }
        else {
          this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, response.error, MessageSeverity.error);  
        }
        this.loading = false;        
      }).catch((error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
        console.error(error);
      }); 
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }  
  }

  async validateEmail() {
    this.showSections(false, true, false);
    this.resetErrorState();

    const requiredElements = [
      this.isErrorOtpCode
    ];

    if (requiredElements.every(element => !element)) {
      let model = {
        applicationId: this.applicationId,
        email: this.email,
        code: this.otpCode,
        createdBy: this.usuario
      };
  
      this.loading = true;
      await lastValueFrom(this.applicationService.postEmailVerify(model)).then(async (response: any) => {      
        this.loading = false;
        if(response.succesValidation) {
          this.enviarValidacion();
        } else {          
          this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_VALIDATION_EMAIL_TITLE, AppConstants.MessageAlert.ERROR_VERIFICATION_EMAIL_TITLE, MessageSeverity.warn, 5000);
          this.showSections(false, true, false);
        }
      }).catch((error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_VALIDATION_EMAIL_TITLE, error.error.message, MessageSeverity.error);
        console.error(error);
      }); 
      
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }    
  }

  async retriveEmailNotification(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getEmailNotification(applicationId)).then(async (response: any) => {      
      this.loading = false;
      if(response.succesSendOtp) {
        this.showSections(false, true, false);
        // await this.validateEmail();
      } else {
        this.showSections(true, false, false);
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_VALIDATION_EMAIL_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async checkValidatedEmail(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getCheckValidatedEmail(applicationId)).then(async (response: any) => {      
      this.loading = false;
      if(response.validated) {
        this.enviarValidacion();
      } else {
        await this.retriveEmailNotification(applicationId);
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_VALIDATION_EMAIL_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

}
