import { ChangeDetectorRef, Component, ViewChild,Output,EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { CustomerAccountService } from 'app/services/customer-account.service';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { HelperService } from 'app/shared/services/helper.service';
import * as FileSaver from 'file-saver';
import { forkJoin, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-modo-desembolso',
  templateUrl: './modo-desembolso.component.html',
  styleUrls: ['./modo-desembolso.component.scss']
})
export class ModoDesembolsoComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  esMovil: boolean = false;
  
  application: any = {};

  filesList: any = [];
  sizeFile: any = [];
  fileTemp!: File;
  showModal = false;
  comment: string = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  loading: boolean = false;
  currentAccounts: any = [];
  elementsReadOnly: any = [];
  account: string = '';
  listBanks: any = [];
  typeBank: string = '';
  accountNumberInterbank: string = '';
  isErrorAccount: boolean = false;
  isErrorTypeBank: boolean = false;
  isErrorAccountNumberInterbank: boolean = false;
  disbursementCode: any = null;
  subModeDisbursement: string = '';
  customerCode: number = 0;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private customerAccountService: CustomerAccountService,
    private alertService: AlertService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private customerService: CustomerService,
    private helperService: HelperService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.BANKS_TRANSFERS,
      AppConstants.ParameterCode.SIZE_FILES
    ]);
    
    await this.getApplication(this.applicationId);
    await this.getAttachmentFiles(this.applicationId);
    this.orquestationCurrentAccounts();
  }

  mostrarAbonoCuenta: boolean = false;
  mostrarAbonoCuentaInterbancaria: boolean = false;
  botonActivo: string = '';
  tipoCuentaSeleccionado: string = '';
  max: number = 20;


  mostrarAbonoEnCuenta() {
    this.tipoCuentaSeleccionado = '';
    this.typeBank = '';
    this.mostrarAbonoCuenta = true;
    this.mostrarAbonoCuentaInterbancaria = false;
    this.botonActivo = 'abono'; // Actualiza el estado del botón activo
    this.isErrorTypeBank = false;
    this.clearValuesDisbursementMode();
    this.disbursementCode = null;
  }

  mostrarAbonoEnCuentaInterbancaria() {
    this.tipoCuentaSeleccionado = '';
    this.mostrarAbonoCuenta = false;
    this.mostrarAbonoCuentaInterbancaria = true;
    this.botonActivo = 'interbancario'; // Actualiza el estado del botón activo
    this.isErrorTypeBank = true;
    this.isErrorAccountNumberInterbank = true;
    this.isErrorAccount = false;
    this.clearValuesDisbursementMode();
    this.disbursementCode = AppConstants.DisbursementCode.ImmediateInterbankTransfer;
  }

  redireccionarResumen()
  {
    let model = {
      applicationId: this.applicationId,
      type: this.disbursementCode,
      currentAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ExistingAccount ? this.account : null,
      interbankAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.accountNumberInterbank : null,
      destinationBankName: this.typeBank === '' ? null : this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.listBanks.find((f: any) => f.value === this.typeBank).bankName : null
    };

    // console.log(JSON.stringify(model));
    this.validateDisbursementMode();
  }

  
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async openAttachmentModal() {
    this.showModal = true;
    await this.retrieveApplicationComments(this.applicationId);
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async retriveCurrentAccounts(model: any) {
    this.loading = true;
    await lastValueFrom(this.customerAccountService.postRetrieveCurrentAccounts(model)).then((response: any) => {
      if(response.accounts != null) {
        this.currentAccounts = response.accounts.map((element: any) => ({ label: `${element.accountNumber} ${element.descriptionProduct}`, value: element.accountNumber }));
      }      
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        this.alertService.showMessage("", AppConstants.MessageAlert.CUSTOMER_ACCOUNTS_ERROR_RETRIEVE, MessageSeverity.error);
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    this.listBanks = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.BANKS_TRANSFERS && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: `${element.parameterDetCode001} - ${element.parameterDetCode002}`, value: element.parameterDetCode001, bankName: element.parameterDetCode002 }));

    this.sizeFile = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SIZE_FILES && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
  }

  validateDisbursementMode() {    

    if(this.botonActivo === '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_TYPE_ERROR_REQUIRED, MessageSeverity.error);
      return;
    } else {
      if(this.botonActivo === 'abono') { 
        if(this.tipoCuentaSeleccionado === '') {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_TYPE_ERROR_REQUIRED, MessageSeverity.error);
          return;
        }
        else {
          if(this.tipoCuentaSeleccionado === 'existente') {
            // this.isErrorAccount = true;
            if(this.account === '') {
              this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_CURRENT_ACOUNT_REQUIRED, MessageSeverity.error);
              return;
            }          
          } else {
            this.account = '';
          }
        }
  
      } else if(this.botonActivo = 'interbancario') {
        // this.isErrorTypeBank = true;
        if(this.typeBank === '') {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_REQUIRED, MessageSeverity.error);
          return;
        }
        if(this.accountNumberInterbank.length < this.max) {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_CCI_REQUIRED, MessageSeverity.error);
          return;
        }
        if(this.accountNumberInterbank.substring(0, 3) != this.typeBank) {
          this.alertService.showMessage("", AppConstants.MessageAlert.DISBURSEMENT_BANK_TRANSFER_CCI_ERROR, MessageSeverity.error);
          return;
        }
      }
    }

    let model = {
      applicationId: this.applicationId,
      type: this.disbursementCode,
      currentAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ExistingAccount ? this.account : null,
      interbankAccountNumber: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.accountNumberInterbank : null,
      destinationBankName: this.disbursementCode === AppConstants.DisbursementCode.ImmediateInterbankTransfer ? this.listBanks.find((f: any) => f.value === this.typeBank).bankName : null
    };

    // console.log(JSON.stringify(model));
    this.addDisbursementMode(model);
  }

  clearValuesDisbursementMode() {
    this.tipoCuentaSeleccionado == '';
    this.typeBank == '';
    this.account = '';
    this.accountNumberInterbank = '';
    this.subModeDisbursement = '';
  }

  changeDisbusemenmtMode(type: string) {
    this.tipoCuentaSeleccionado = type;

    if(this.tipoCuentaSeleccionado === 'existente') {
      this.disbursementCode = AppConstants.DisbursementCode.ExistingAccount;
      this.isErrorAccount = true;
      this.isErrorTypeBank = false;
    } else {
      this.disbursementCode = AppConstants.DisbursementCode.NewAccount;
      this.isErrorAccount = false;
      this.isErrorTypeBank = false;
    }
  }

  resetErrorState(inputType: string) {
    if (inputType === 'accountNumberInterbank') {
      this.isErrorAccountNumberInterbank = this.accountNumberInterbank.length === 0;  
    }
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    if(model.disbursementType != null) {

      switch(model.disbursementType) {
        case AppConstants.DisbursementCode.WithoutDisbursement:
          break;
        case AppConstants.DisbursementCode.NewAccount:
          this.mostrarAbonoEnCuenta();
          this.changeDisbusemenmtMode('nueva');
          this.subModeDisbursement = 'nueva';
          break;
        case AppConstants.DisbursementCode.ExistingAccount:
          this.mostrarAbonoEnCuenta();
          this.changeDisbusemenmtMode('existente');
          this.subModeDisbursement = 'existente';
          this.account = (model.currentAccountNumber != null && model.currentAccountNumber != '') ? model.currentAccountNumber : '';
          this.isErrorAccount = (model.currentAccountNumber != null && model.currentAccountNumber != '') ? false : true;
          break;
        case AppConstants.DisbursementCode.ImmediateInterbankTransfer:
          this.mostrarAbonoEnCuentaInterbancaria();
          this.accountNumberInterbank = (model.interbankAccountNumber != null && model.interbankAccountNumber != '') ? model.interbankAccountNumber : '';
          
          if(model.interbankAccountNumber != null && model.interbankAccountNumber != '' && model.interbankAccountNumber.length >= 3) {
            this.typeBank = model.interbankAccountNumber.substring(0,3);
            this.isErrorAccountNumberInterbank = false;
            this.isErrorTypeBank = false;
          }
          break;
        default:
      }
    }
  }

  addDisbursementMode(model: any) {
    this.loading = true;
    this.applicationService.postRegisterDisbursementMode(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.DISBURSEMENT_MODE_TITLE, AppConstants.MessageAlert.DISBURSEMENT_MODE_SUCCESS, MessageSeverity.success);
        this.router.navigate(['resumen']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.DISBURSEMENT_MODE_TITLE, AppConstants.MessageAlert.DISBURSEMENT_MODE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  orquestationCurrentAccounts() {
    this.loading = true;
    const modelCustomer = {
      documentType: this.application.documentType,
      documentNumber: this.application.documentNumber
    };

    const sources: any = [];
    sources.push(this.customerService.postRetrieveId(modelCustomer));
    forkJoin(sources)
    .subscribe({
      next: (response: any) => {
        for(let index = 0; index < response.length; index++) {
          const element = response[index];
          this.customerCode = element.ibsCode;
        }
        this.loading = false;
      },
      error: (e) => {
        console.error(e)
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.CUSTOMER_RETRIEVE_TITLE, e.error.message, MessageSeverity.error);
      },
      complete: async () => {
        this.loading = false;
        if(this.customerCode != 0) {
          let model = {
            ibsCode: this.customerCode,
            producType: "AHOR",
            ShowBalance: false,
            currency: "SOL"
          };
          await this.retriveCurrentAccounts(model);
        }
      }
    });    
  }

  handleClose() {
    this.showModal = false;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  async readFile(event: any) {
    const file = event.file.target.files[0];
    if (!file) {
        return;
    }
    return await new Response(file).arrayBuffer();
  }

  base64ArrayBuffer(arrayBuffer: any) {
    var base64 = '';
    var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63;               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
}

  async handleSelectFileAction(event: any) {
    try {
      const files = event.file.target.files;
      const file = files[0];

      if (this.helperService.isValidFileType(file.type)) {
        if (files && file) {
          const exist = this.filesList.filter((f: any) => f.name === file.name);
          if (exist.length > 0) {
            this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_EXIST, MessageSeverity.error);
            return;
          }

          const sizeParameterBytes = (this.sizeFile == null || this.sizeFile === undefined ||
          this.sizeFile[0] == null || this.sizeFile[0] === undefined) ? null : this.sizeFile[0];

          if (sizeParameterBytes != null) {
            let pesoMaximo = sizeParameterBytes.parameterDetCode001;
            if (file.size > sizeParameterBytes.parameterDetCode001 * 1000000) {
                this.alertService.showMessage('Validar Archivo', `Solo puede adjuntar archivos de tamaño menor o igual a ${pesoMaximo} MB.`, MessageSeverity.warn);
                return;
            }
          }

          this.fileTemp = <File>event.file.target.files[0];
          var extension = this.fileTemp.name.split('.');
          var keyValue = this.uuidv4() + '.' + extension[extension.length - 1];

          let model = {
            applicationId: this.applicationId,
            file: {
              name: this.fileTemp.name,
              type: AppConstants.AttachmentMode.Customer,
              fileId: keyValue,
              fileBase64: this.base64ArrayBuffer(await this.readFile(event)),
              contenType: this.fileTemp.type
            }
          };
          this.uploadFile(model);
        }

      } else {
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILE_INCORRECT, MessageSeverity.error);
      }

    } catch (error) {
      console.error(error);
    }    
  }

  uploadFile(model: any) {
    this.loading = true;
    this.applicationService.postUploadFile(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.filesList.push({name: this.fileTemp.name, key: model.file.fileId});
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_OK, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.UPLOAD_FILES, AppConstants.MessageAlert.UPLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  handleFileDownload(event: any) {
    this.downloadFile(event.file.key, event.file.name);
  }

  handleFileDelete(event: any) {
    const keyIndex = this.filesList.findIndex((item: any) => item.key === event.file.key);
    let model = {
      applicationId: this.applicationId,
      fileId: event.file.key,
      index: keyIndex
    };
    this.deleteFile(model);
  }

  downloadFile(fileId: string, nameFile: string): void {
    this.loading = true;
    this.applicationService.downloadFile(fileId).subscribe({
      next: (response: any) => {
        const data: Blob = new Blob([response], {
            type: response.type
        });

        let extension = fileId.split('.');
        FileSaver.saveAs(data, nameFile);
      },
      error: (error: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DOWNLOAD_FILE, AppConstants.MessageAlert.DOWNLOAD_FILES_MESSAGE_ERROR, MessageSeverity.error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  deleteFile(model : any): void {
    this.loading = true;
    this.applicationService.postDeleteFile(model).subscribe({
      next: async (response: any) => {
        this.filesList.splice(model.index, 1);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.DELETE_FILE, AppConstants.MessageAlert.DELETE_FILES_MESSAGE_ERROR, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }  

  sendCommentHandler() {
    if(this.comment == '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_EMPY_CREDENTIALS, MessageSeverity.warn);
    }
    else {
      let model = {
        applicationId: this.applicationId,
        commentKey: AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE,
        comment: this.comment,
        reason: '',
        updatedBy: this.usuario,
        updatedByFullName: this.userName
      };
  
      this.sendAddComentApplication(model);
    }
  }

  sendAddComentApplication(model : any): void {
    this.loading = true;
    this.applicationService.postSendAddComentApplication(model).subscribe({
      next: async (response: any) => {
        this.showModal = false;
        this.comment = '';
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_SUCCES_APPLICATION, MessageSeverity.success);
      },
      error: (error) => {
        this.alertService.showMessage("", AppConstants.MessageAlert.ADD_COMMENT_ERROR_APPLICATION, MessageSeverity.error);
        console.error(error);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  async retrieveApplicationComments(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplicationComments(model)).then((response: any) => {
      const comments = response.filter((f: any) => f.id === AppConstants.ApplicationComment.COMMENT_ATTACHMENT_CODE);
      if(comments.length > 0){
        this.comment = comments[0].message;
      }
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

}
