
<div class="layout-container">

    <div class="formulario-container">
        <div class="form-row">
          <div class="form-group">
            <label>Numero de Solicitud</label>
            <app-custom-input [(value)]="applicationNumber" [maxlength]="max">
            </app-custom-input>
          </div>
          <div class="form-group">
            <label>Producto</label>
            <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="data" [(selectedValue)]="productFilter"></app-custom-inpunt-select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Fecha Inicio</label>
            <app-custom-datepicker [(value)]="dateFrom" (change)="emitLinkAction()"></app-custom-datepicker>
          </div>
          <div class="form-group">
            <label>Fecha Fin</label>
            <app-custom-datepicker [(value)]="dateUntil" (change)="emitLinkAction()"></app-custom-datepicker>
          </div>
        </div>

      </div>    
</div>

<div class="footer-buttons">

  <app-custom-button text="CERRAR" (action)="close();emitLinkAction();"></app-custom-button>

  </div>