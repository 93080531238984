import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasivosFinalizacionRoutingModule } from './pasivos-finalizacion-routing.module';
import { PasivosFinalizacionComponent } from './pasivos-finalizacion.component';
import { CustomButtonComponent, CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, StepperComponent } from 'app/components';
import { SharedModule } from 'app/shared/modules/shared/shared.module';


@NgModule({
  declarations: [
    PasivosFinalizacionComponent
  ],
  imports: [
    CommonModule,
    PasivosFinalizacionRoutingModule,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    SharedModule
  ]
})
export class PasivosFinalizacionModule { }
