import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-usuarios',
  templateUrl: './admin-usuarios.component.html',
  styleUrls: ['./admin-usuarios.component.scss']
})
export class AdminUsuariosComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 12;
  nameUser: string = '';
  max: number = 100;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

 
  @ViewChild('myTable') tableComponent!: CustomTableSimulatorComponent;
  columnNames = ['Item', 'Usuario', 'Nombre', 'Usuario Creación', 'Fecha Creación', 'Acciones'];
  tusDatos: any[] = [];
  showModalFiltros =false;
  modalWidth: number = 480; 
  modalHeight: number = 380; 
  constructor(private router: Router) { }
  ngOnInit(): void {

    this.tusDatos = [
      {
        "Item": "1",
        "Usuario": "aruizAsesor",
        "Nombre": "JUAN RUBEN RAMIREZ VILCHEZ",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "2",
        "Usuario": "blopezAsesor",
        "Nombre": "MARIA ELENA LOPEZ SANCHEZ",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-01-02",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "3",
        "Usuario": "cramirezContador",
        "Nombre": "CARLOS ALBERTO TORRES",
        "Usuario Creación": "user2",
        "Fecha Creación": "2023-02-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "4",
        "Usuario": "dgonzalezAnalista",
        "Nombre": "DIANA GONZÁLEZ MARÍN",
        "Usuario Creación": "user3",
        "Fecha Creación": "2023-02-15",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "5",
        "Usuario": "emoralesGerente",
        "Nombre": "EDUARDO MORALES LÓPEZ",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-03-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "6",
        "Usuario": "frodriguezDirector",
        "Nombre": "FABIÁN RODRÍGUEZ SOTO",
        "Usuario Creación": "user4",
        "Fecha Creación": "2023-03-20",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "7",
        "Usuario": "gramirezAsesor",
        "Nombre": "GABRIELA RAMÍREZ DÍAZ",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-04-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "8",
        "Usuario": "hsanchezAnalista",
        "Nombre": "HÉCTOR SÁNCHEZ MOLINA",
        "Usuario Creación": "user5",
        "Fecha Creación": "2023-04-15",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "9",
        "Usuario": "imejiaGerente",
        "Nombre": "IVÁN MEJÍA ALVAREZ",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-05-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "10",
        "Usuario": "jtorresDirector",
        "Nombre": "JULIO TORRES CASTRO",
        "Usuario Creación": "user6",
        "Fecha Creación": "2023-05-20",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "11",
        "Usuario": "klopezAsesor",
        "Nombre": "KARLA LÓPEZ MIRANDA",
        "Usuario Creación": "adminUser",
        "Fecha Creación": "2023-06-01",
        "Acciones": "<a href='#'>EDITAR</a>"
      },
      {
        "Item": "12",
        "Usuario": "lrodriguezAnalista",
        "Nombre": "LUIS RODRÍGUEZ PÉREZ",
        "Usuario Creación": "user7",
        "Fecha Creación": "2023-06-15",
        "Acciones": "<a href='#'>EDITAR</a>"
      }
    ];
    
    
    
  }

  addUsuario(accion: string){
    this.router.navigate(['admin-usuarios-acciones'], { queryParams: { parametro: accion } });
  }
  
  navigateFromParent(row: any) {
    // Lógica de navegación personalizada aquí, por ejemplo:
    this.router.navigate(['bandeja-solicitudes-producto']);
  }

  clear() {

  }

  seach() {

  }

}
