import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasivosFinalizacionComponent } from './pasivos-finalizacion.component';

const routes: Routes = [
  {path: '', component: PasivosFinalizacionComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PasivosFinalizacionRoutingModule { }
