import { Component, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss']
})
export class DatosPersonalesComponent {
  componenteActivo: string = 'datosGenerales';

  mostrarComponente(componente: string) {
    this.componenteActivo = componente;
  }
}
