import { ChangeDetectorRef, Component, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { AppConstants } from 'app/shared/constants/app-constants';
import { ApplicationService } from 'app/services/application.service';
import { ParameterService } from 'app/services/parameter.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-otros-modal',
  templateUrl: './datos-otros-modal.component.html',
  styleUrls: ['./datos-otros-modal.component.scss']
})
export class DatosOtrosModalComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';

  application: any = {};

  // Variables formulario
  referencias: any[] = [];
  esMovil: boolean = false;
  tipoVia: string = '';
  nombreVia: string = '';
  numero: string = '';
  interior: string = '';
  urbanizacion: string = '';
  referencia: string = '';
  departamento: string = '';
  provincia: string = '';
  distrito: string = '';
  nivelEducacion: string = '';
  profesion: string = '';
  cargoOcupacion: string = '';
  nombreEmpresa: string = '';
  rucEmpresa: string = '';
  ingresosNetos: string = '';

  telefonoResidencia: string = '';
  telefonoTrabajo: string = '';
  celularAdicional: string = '';
  correoAdicional: string = '';

  // Validators
  isErrorTipoVia: boolean = false;
  isErrorNombreVia: boolean = false;
  isErrorNumero: boolean = false;
  isErrorInterior: boolean = false;
  isErrorUrbanizacion: boolean = false;
  isErrorReferencia: boolean = false;
  isErrorDepartamento: boolean = false;
  isErrorProvincia: boolean = false;
  isErrorDistrito: boolean = false;
  isErrorNivelEducacion: boolean = false;
  isErrorProfesion: boolean = false;
  isErrorCargoOcupacion: boolean = false;
  isErrorNombreEmpresa: boolean = false;
  isErrorRucEmpresa: boolean = false;
  isErrorIngresosNetos: boolean = false;

  isErrorTelefonoResidencia: boolean = false;
  isErrorTelefonoTrabajo: boolean = false;
  isErrorCelularAdicional: boolean = false;
  isErrorCorreoAdicional: boolean = false;


  opcionesTipoVia: any = [];
  opcionesDepartamento: any = [];
  opcionesProvincia: any = [];
  opcionesDistrito: any = [];
  opcionesNivelEducacion: any = [];
  opcionesProfesion: any = [];
  opcionesCargoOcupacion: any = [];

  loading: boolean = false;
  elementsReadOnly: any = [];
  arrayProvinces: any = [];
  arrayDistricts: any = [];
  typeInputNumber: string = 'tel';
  max: number = 8;
  maxLengthRUC: number = 11;
  maxLengthIngresos: number = 6;    

  nombreViaValMin: number = 1;
  nombreViaValMax: number = 30;
  numeroValMin: number = 1;
  numeroValMax: number = 5;
  interiorValMin: number = 0;
  interiorValMax: number = 5;
  urbanizacionValMin: number = 0;
  urbanizacionValMax: number = 35;
  referenciaValMin: number = 0;
  referenciaValMax: number = 100;
  nombreEmpresaValMin: number = 1;
  nombreEmpresaValMax: number = 45;
  rucEmpresaValMin: number = 11;
  rucEmpresaValMax: number = 11;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  constructor(
    private router: Router,  
    private cdr: ChangeDetectorRef,
    private parameterService: ParameterService,
    private alertService: AlertService,
    private applicationService: ApplicationService
  ) {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.VIA_TYPE,
      AppConstants.ParameterCode.DEPARTAMENT,
      AppConstants.ParameterCode.PROVINCE,
      AppConstants.ParameterCode.DISTRIC,
      AppConstants.ParameterCode.LEVEL_STUDY,
      AppConstants.ParameterCode.PROFESION,
      AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP,
    ]);
    await this.getApplication(this.applicationId);
  }  

  close() {
    this.onClose.emit(); 
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    
  }

  validarYCambiarComponente() {
    // Validaciones para cada campo
    this.isErrorTipoVia = !this.tipoVia;
    this.isErrorNombreVia = this.nombreVia == null ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);
    this.isErrorNumero = this.numero == null ? true : (this.numero.length >= this.numeroValMin ? false : true);
    //this.isErrorReferencia = !this.referencia;
    this.isErrorDepartamento = !this.departamento;
    this.isErrorProvincia = !this.provincia;
    this.isErrorDistrito = !this.distrito;
    this.isErrorNivelEducacion = !this.nivelEducacion;
    this.isErrorProfesion = !this.profesion;
    this.isErrorCargoOcupacion = !this.cargoOcupacion;
    this.isErrorNombreEmpresa = this.nombreEmpresa == null ? true : (this.nombreEmpresa.length >= this.nombreEmpresaValMin ? false : true);
    this.isErrorRucEmpresa = this.rucEmpresa == null ? true : (this.rucEmpresa.length >= this.rucEmpresaValMin ? false : true);
    this.isErrorIngresosNetos = !this.ingresosNetos;
    this.isErrorCorreoAdicional = this.correoAdicional === undefined || this.correoAdicional === null ? false : this.correoAdicional.length > 0 ? !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.correoAdicional) : false;

    const requiredElements = [
      this.isErrorTipoVia,
      this.isErrorNombreVia,
      this.isErrorNumero,
      this.isErrorDepartamento,
      this.isErrorProvincia,
      this.isErrorDistrito,
      this.isErrorNivelEducacion,
      this.isErrorProfesion,
      this.isErrorCargoOcupacion,
      this.isErrorNombreEmpresa,
      this.isErrorRucEmpresa,
      this.isErrorIngresosNetos
    ];

    const requiredElementsConditional = [
      this.isErrorCorreoAdicional
    ];

    if (requiredElements.every(element => !element)
      && (this.correoAdicional?.length > 0 ? requiredElementsConditional.every(element => !element) : true)) {

      let model = {
        workStreetType: this.tipoVia,
        workStreetName: this.nombreVia,
        workStreetNumber: this.numero,
        workInterior: this.interior,
        workUrbanization: this.urbanizacion,
        workReference: this.referencia,
        workDepartmentCode: this.departamento,
        workProvinceCode: this.provincia,
        workDistrictCode: this.distrito,
        residenceTelephone: this.telefonoResidencia,
        workPhone: this.telefonoTrabajo,
        secondCellphonne: this.celularAdicional,
        aditionalEmail: this.correoAdicional,
        educationLevel: this.nivelEducacion,
        profesion: this.profesion,
        workPosition: this.cargoOcupacion,
        companyName: this.nombreEmpresa,
        companyRuc: this.rucEmpresa,
        salary: this.ingresosNetos
      };

      this.updateApplication({ 
        applicationId: this.applicationId,
        workflowInstanceId : this.workflowId,
        taskId: AppConstants.WorkflowTasks.PRODUCT_EVALUATION,
        updatedBy: this.usuario,
        updatedByFullName: this.userName,
        aditionalInformation: model 
      });       
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }

  }

  resetErrorState(inputType: string) {
    if (inputType === 'nombreVia') {
      this.isErrorNombreVia = this.nombreVia === undefined || this.nombreVia === null  ? true : (this.nombreVia.length >= this.nombreViaValMin ? false : true);  
    }else   if (inputType === 'numero') {
      this.isErrorNumero = this.numero === undefined || this.numero === null  ? true : (this.numero.length >= this.numeroValMin ? false : true);
    }else   if (inputType === 'nombreEmpresa') {
      this.isErrorNombreEmpresa = this.nombreEmpresa === undefined || this.nombreEmpresa === null  ? true : (this.nombreEmpresa.length >= this.nombreEmpresaValMin ? false : true);
    }else   if (inputType === 'rucEmpresa') {
      this.isErrorRucEmpresa = this.rucEmpresa === undefined || this.rucEmpresa === null  ? true : (this.rucEmpresa.length >= this.rucEmpresaValMin ? false : true); 
    }else   if (inputType === 'ingresosNetos') {
      this.isErrorIngresosNetos = this.ingresosNetos === undefined || this.ingresosNetos === null  ? true : this.ingresosNetos.length === 0;  
    } 
  }

  addReferencia(): void {
    if (this.referencias.length < 2) {
      // Agregar una nueva referencia
      this.referencias.push({
        id: this.referencias.length + 1,
        nombre: '',
        telefono: ''
      });
    }
  }

  borrarReferencia(id: number): void {
    // Eliminar la referencia por id
    this.referencias = this.referencias.filter(ref => ref.id !== id);
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    this.opcionesTipoVia = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.VIA_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesDepartamento = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DEPARTAMENT && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesNivelEducacion = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.LEVEL_STUDY && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesProfesion = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PROFESION && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

    this.opcionesCargoOcupacion = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.EMPLOYMENT_RELATIONSHIP && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));      

    this.arrayProvinces = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.PROVINCE && f.state === AppConstants.StateFlag.Active);
    this.arrayDistricts = listParameters.filter((f: any) => f.parameterCode === AppConstants.ParameterCode.DISTRIC && f.state === AppConstants.StateFlag.Active);

  }

  getProvinces(event: any) {
    this.provincia = '';
    this.distrito = '';
    this.opcionesDistrito = [];
    this.opcionesProvincia = this.arrayProvinces
      .filter((f: any) => f.parameterDetCode006 === event.value)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  getDistricts(event: any) {
    this.distrito = '';
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === event.value && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));
  }

  updateApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.close();
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    this.tipoVia = model.workStreetType == null ? '' : model.workStreetType;
    this.nombreVia = model.workStreetName;
    this.numero = model.workStreetNumber;
    this.interior = model.workStreetNumber;
    this.urbanizacion = model.workUrbanization;
    this.referencia = model.workReference;
    this.departamento = model.workDepartmentCode == null ? '' : model.workDepartmentCode;
    this.provincia = model.workProvinceCode == null ? '' : model.workProvinceCode;
    this.distrito = model.workDistrictCode == null ? '' : model.workDistrictCode;
    this.nivelEducacion = model.educationLevel == null ? '' : model.educationLevel;
    this.profesion = model.profession == null ? '' : model.profession;
    this.cargoOcupacion = model.occupation == null ? '' : model.occupation;
    this.nombreEmpresa = model.companyName;
    this.rucEmpresa = model.companyCode;
    this.ingresosNetos = model.netIncome;

    this.telefonoResidencia = model.homePhoneNumber;
    this.telefonoTrabajo = model.workPhoneNumber;
    this.celularAdicional = model.aditionalPhoneNumber;
    this.correoAdicional = model.aditionalEmailAddress;

    if(this.departamento != null && this.departamento != '') {
      this.opcionesDistrito = [];
      this.opcionesProvincia = this.arrayProvinces
        .filter((f: any) => f.parameterDetCode006 ===this.departamento)
        .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
        .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 })); 
    }

    if(this.provincia != null && this.provincia != '')
    this.opcionesDistrito = this.arrayDistricts
      .filter((f: any) => f.parameterDetCode006 === this.provincia && f.parameterDetCode007 === this.departamento)
      .sort((a: any, b: any) => (a.parameterDetCode002 < b.parameterDetCode002 ? -1 : 1))
      .map((element: any) => ({ label: element.parameterDetCode002, value: element.parameterDetCode001 }));

  }

  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }

}
