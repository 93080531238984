import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BandejaSolicitudesProductoPasivosRoutingModule } from './bandeja-solicitudes-producto-pasivos-routing.module';
import { BandejaSolicitudesProductoPasivosComponent } from './bandeja-solicitudes-producto-pasivos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CustomTablePaginatorComponent, CustomButtonComponent, CustomButtonColorComponent, CustomInpuntSelectComponent, HeaderComponent, FooterComponent, CustomInputComponent, CustomTableSimulatorComponent, LineDividerComponent, StepperComponent, InfoModalComponent, ModalResumenComponent, CustomInputPasswordComponent, CustomDatepickerComponent, CustomButtonLinkComponent } from 'app/components';
import { CustomButtonBorderComponent } from 'app/components/custom-button-border';
import { CustomButtonCircleComponent } from 'app/components/custom-button-circle/custom-button-circle.component';
import { CustomTextAreaComponent } from 'app/components/custom-text-area';
import { SharedModule } from 'app/shared/modules/shared/shared.module';
import { DatosGeneralesModalPasivoComponent } from './components/datos-generales-modal-pasivo/datos-generales-modal-pasivo.component';
import { DatosDireccionModalPasivoComponent } from './components/datos-direccion-modal-pasivo/datos-direccion-modal-pasivo.component';
import { DatosTipoCuentaModalPasivoComponent } from './components/datos-tipo-cuenta-modal-pasivo/datos-tipo-cuenta-modal-pasivo.component';
import { DatosAdjuntosModalPasivoComponent } from './components/datos-adjuntos-modal-pasivo/datos-adjuntos-modal-pasivo.component';


@NgModule({
  declarations: [
    BandejaSolicitudesProductoPasivosComponent,
    DatosGeneralesModalPasivoComponent,
    DatosDireccionModalPasivoComponent,
    DatosTipoCuentaModalPasivoComponent,
    DatosAdjuntosModalPasivoComponent
  ],
  imports: [
    CommonModule,
    BandejaSolicitudesProductoPasivosRoutingModule,
    CustomTablePaginatorComponent,
    CustomButtonComponent,
    CustomButtonColorComponent,
    CustomInpuntSelectComponent,
    HeaderComponent,
    FooterComponent,
    CustomInputComponent,
    CustomTableSimulatorComponent,
    LineDividerComponent,
    StepperComponent,
    CustomButtonCircleComponent,
    InfoModalComponent,
    ModalResumenComponent,
    CustomTextAreaComponent,
    CustomInputPasswordComponent,
    CustomDatepickerComponent,
    CustomButtonLinkComponent,
    SharedModule,
    MatFormFieldModule,MatSelectModule, FormsModule, ReactiveFormsModule,
    CustomButtonBorderComponent
  ]
})
export class BandejaSolicitudesProductoPasivosModule { }
