import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pasivos-validacion-biometrica',
  templateUrl: './pasivos-validacion-biometrica.component.html',
  styleUrls: ['./pasivos-validacion-biometrica.component.scss']
})
export class PasivosValidacionBiometricaComponent {
  
  constructor(private router: Router) { }

  enviarAutenticacion(){
    this.router.navigate(['finalizacion']);
  }
}
