import { Component, Input, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { CustomerAccountService } from 'app/services/customer-account.service';
import { CustomerService } from 'app/services/customer.service';
import { ParameterService } from 'app/services/parameter.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { HelperService } from 'app/shared/services/helper.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-datos-desembolso-modal-bandeja',
  templateUrl: './datos-desembolso-modal-bandeja.component.html',
  styleUrls: ['./datos-desembolso-modal-bandeja.component.scss']
})
export class DatosDesembolsoModalBandejaComponent {

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  esMovil: boolean = false;

  application: any = {};

  filesList: any = [];
  sizeFile: any = [];
  fileTemp!: File;
  showModal = false;
  comment: string = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }

  loading: boolean = false;

  listSavingsAccountType: any = [];
  elementsReadOnly: any = [];
  currency: string = '';
  listFilterSavingsAccountType: any = [];
  typeSavingAccount: string = '';

  isErrorTypeSavingAccount: boolean = false;

  @Output() onClose = new EventEmitter<void>();
  // @Output() linkAction = new EventEmitter<void>();
  @Output() linkAction = new EventEmitter<{ updateSucess: boolean }>();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private customerAccountService: CustomerAccountService,
    private alertService: AlertService,
    private parameterService: ParameterService,
    private applicationService: ApplicationService,
    private customerService: CustomerService,
    private helperService: HelperService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    await this.getParameters([
      AppConstants.ParameterCode.SAVINGS_ACCOUNT_TYPE,
      AppConstants.ParameterCode.SIZE_FILES
    ]);
    await this.getApplication(this.applicationId);
  }

  close() {
    this.onClose.emit(); 
  }

  onLinkClick() {
    this.linkAction.emit();
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.application = response;
      this.setApplicationForm(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setApplicationForm(model: any) {
    if(model.currencyCode != null) {
      this.currency = model.currencyCode;
      this.changeDisbusemenmtMode(this.currency);
    }

    this.typeSavingAccount = model.productCode != null ? model.productCode : '';
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {
    this.listSavingsAccountType = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SAVINGS_ACCOUNT_TYPE && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1))
      .map((element: any) => ({ label: `${element.parameterDetCode001} - ${element.parameterDetCode002}`, value: element.parameterDetCode001, currency: element.parameterDetCode006, productName: element.parameterDetCode002 }));

    this.sizeFile = listParameters
      .filter((f: any) => f.parameterCode === AppConstants.ParameterCode.SIZE_FILES && f.state === AppConstants.StateFlag.Active)
      .sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
  } 

  clearSelectOption() {
    this.typeSavingAccount = '';
  }  
  
  changeDisbusemenmtMode(type: string) {
    this.currency = type;
    this.listFilterSavingsAccountType = [];
    this.listFilterSavingsAccountType = this.listSavingsAccountType.filter((f: any) => f.currency === type);    
  }

  validarYCambiarComponente(){
    this.validateDisbursementMode();
  }
  
  validateDisbursementMode() {
    if(this.currency === '') {
      this.alertService.showMessage("", AppConstants.MessageAlert.SAVING_ACCOUNT_CURRENCY_TYPE_ERROR_REQUIRED, MessageSeverity.error);
      return;
    }
    else {
      if(this.typeSavingAccount === '') {
        this.alertService.showMessage("", AppConstants.MessageAlert.SAVING_ACCOUNT_TYPE_ERROR_REQUIRED, MessageSeverity.error);
        return;
      }
    }

    let model = {
      applicationId: this.applicationId,
      currencyCode: this.currency,
      prodcutCode: this.typeSavingAccount,
      productName: this.listFilterSavingsAccountType.find((f: any) => f.value === this.typeSavingAccount).productName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName,
    };

    this.addSavingAccountType(model);

  }  

  addSavingAccountType(model: any) {
    this.loading = true;
    this.applicationService.postRegisterSavingAccountType(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SAVING_ACCOUNT_TYPE_TITLE, AppConstants.MessageAlert.SAVING_ACCOUNT_TYPE_SUCCESS, MessageSeverity.success);
        this.close();
        this.emitLinkAction(true);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SAVING_ACCOUNT_TYPE_TITLE, AppConstants.MessageAlert.SAVING_ACCOUNT_TYPE_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }
  
  emitLinkAction(status: boolean) {
    this.linkAction.emit({ updateSucess: status });
  }  

}
