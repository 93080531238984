import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'app/services/application.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-pasivos-validacion-facial',
  templateUrl: './pasivos-validacion-facial.component.html',
  styleUrls: ['./pasivos-validacion-facial.component.scss']
})
export class PasivosValidacionFacialComponent {

  @Input() cellphoneInput: string = '';

  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  application: any = {};

  cellphone: string = '';
  loading: boolean = false;

  @Output() validacionCompletada = new EventEmitter<void>();
  showValidacionCompletada = false;

  typeInputNumber: string = 'tel';
  max: number = 9;

  isErrorCellphone: boolean = false;
  validationIncomplete = true;
  validationInProgress = false;
  validationCompleted = false;

  siteKey = environment.reCaptchaKey;
  recaptchaLoaded: boolean = false;


  botonDeshabilitado: boolean = true;

  showModalResumen = false;
  tituloModal='Proceso de Alta';
  textoEnlaceModal='CERRAR';
  updateApplication: any = {};


  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private alertService: AlertService) {
  }

  async ngOnInit() {
    this.loadRecaptchaScript();
    await this.retrieveApplication(this.applicationId);
    await this.retriveBiometricNotification(this.applicationId);
  }

  resetErrorState() {
    this.isErrorCellphone = this.cellphone === null ? true : 
                            this.cellphone === undefined ? true : 
                            this.cellphone === '' ? true : 
                            this.cellphone.length === this.max ? false : true;
  }

  enviarValidacion() {
    this.botonDeshabilitado = false;
    this.showSections(false, false, true);
    this.validacionCompletada.emit();    
  }

  async sendAuthFace() {
    this.resetErrorState();

    const requiredElements = [
      this.isErrorCellphone
    ];

    if (requiredElements.every(element => !element)) {
      this.executeRecaptchaLogin();
    } else {
      this.alertService.showMessage("", AppConstants.MessageAlert.ERROR_FORM_REQUIRED, MessageSeverity.error);
    }    
  }

  async retrySend() {
    this.executeRecaptchaLogin();
  }

  changeNumber() {
    this.showSections(true, false, false);
  }

  showSections(incomplete : boolean, inprogress: boolean, completed: boolean) {
    this.validationIncomplete = incomplete;
    this.validationInProgress = inprogress;
    this.validationCompleted = completed;
  }

  async retriveBiometricNotification(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getBiometricsNotification(applicationId)).then(async (response: any) => {      
      this.loading = false;
      if(response.length > 0) {
        this.showSections(false, true, false);
        await this.checkStatusAuthentication();
      }else {
        this.showSections(true, false, false);
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async retrieveApplication(applicationId: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(applicationId)).then((response: any) => {      
      this.application  = response;
      this.cellphone = this.application.personalCellPhoneNumber;
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      // this.alertService.showMessage(AppConstants.TitleAlert.RETRIEVE_APPLICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async checkStatusAuthentication() {
    this.loading = true;

    let model = {
      applicationId: this.applicationId,
      documentType: this.application.documentType,
      documentNumber: this.application.documentNumber,
      userId: this.usuario
    };

    await lastValueFrom(this.applicationService.postCheckStatusAuthentication(model)).then(async (response: any) => {      
      this.loading = false;
      if(response.authenticatedCustomer === false) {
        this.alertService.showMessage(AppConstants.TitleAlert.PROCESS_AUTHENTICATION_TITLE, AppConstants.MessageAlert.CONSULT_AUTHENTICATION_TITLE, MessageSeverity.warn, 6000);
        this.showSections(false, true, false);
        // await this.retriveBiometricNotification(this.applicationId);
      } else {
        this.enviarValidacion();
      }
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.CONSULT_AUTHENTICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });  
  }

  async sendFaceNotification(token: string) {
    let model = {
      applicationId : this.applicationId,
      cellPhoneNumber: this.cellphone,
      secret: token
    };
    this.loading = true;
    await lastValueFrom(this.applicationService.postFaceAuthentication(model)).then((response: any) => {
      if(response.codigo === AppConstants.StatusZytrus.Success) {
        this.showSections(false, true, false);
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_SUCCES, MessageSeverity.success);
      }
      else {
        this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, response.error, MessageSeverity.error);  
      }
      this.loading = false;        
    }).catch((error) => {
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, error.error.message, MessageSeverity.error);
      console.error(error);
    });
  }

  loadRecaptchaScript(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.recaptchaLoaded = true;
    };
    script.onerror = (error) => {
      console.error('Error al cargar el script de reCAPTCHA Enterprise', error);
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE, MessageSeverity.error);
    };
    document.head.appendChild(script);
  }

  executeRecaptchaLogin(): void {
    if (this.recaptchaLoaded && window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.execute(this.siteKey, { action: AppConstants.RecaptchaEnterprise.Authface })
        .then((token: string) => {
          this.sendFaceNotification(token);
        });
    } else {
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE_OFF, MessageSeverity.error);
      console.error('reCAPTCHA Enterprise no está disponible.');
    }
  }  







  goAuth() {
    this.router.navigate(['pasivos-autenticacion']);
  }

  // goNext() {
  //   this.router.navigate(['pasivos-finalizacion']);
  // }

  // sendAuthFace() {
  //   this.showSendAuthFace = !this.showSendAuthFace;
  //   this.showWaitingAuth = !this.showWaitingAuth;
  //   this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_SUCCES, MessageSeverity.success);
  // }

  // changePhoneNumber() {
  //   this.showSendAuthFace = !this.showSendAuthFace;
  //   this.showWaitingAuth = !this.showWaitingAuth;
  // }

  // validateAuthFace() {
  //   this.showSendAuthFace = false;
  //   this.showWaitingAuth = false
  //   this.showSuccessAuth = !this.showSuccessAuth;
  //   this.botonDeshabilitado = !this.botonDeshabilitado;
  // }

  // sendAuth() {
  //   this.alertService.showMessage(AppConstants.TitleAlert.NOTIFICATION_TITLE, AppConstants.MessageAlert.NOTIFICATION_SUCCES, MessageSeverity.success);
  // }

  accionPersonalizada() {
    this.showModalResumen = false;
  }

  executeServices() {
    this.updateApplicationAltaModel();
    this.showModalResumen = true;
  }

  handleCloseAlta() {
    this.showModalResumen = false;
  }

  updateApplicationAltaModel() {
    this.updateApplication.applicationId = this.applicationId;
    this.updateApplication.workflowInstanceId = this.workflowId;
    this.updateApplication.status = AppConstants.ApplicationStates.Aprobado;
    this.updateApplication.processId = AppConstants.Process.APERTURA_CUENTA_AHORROS;
    this.updateApplication.taskId = AppConstants.WorkflowTasks.PASIVOS_CUSTOMER_APROBACION;
    this.updateApplication.channelId = AppConstants.Channels.DesktopPasivosPVA;
    this.updateApplication.productCode = AppConstants.Products.Savings;
    this.updateApplication.nextStep = "pasivos-finalizacion";
  }

}
