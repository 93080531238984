import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidenavService } from 'app/services/sidenav.service';
import { Router } from '@angular/router';
import { TokenModel } from 'app/models/tokenModel';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { jwtDecode } from "jwt-decode";
import { UserService } from 'app/services/user.service';
import { forkJoin, Subject, Subscription, takeUntil } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  
  isErrorUser: boolean = false;
  isErrorPass: boolean = false;
  userInput: string = '';
  passwordInput: string = '';
  loading: boolean = false;

  siteKey = environment.reCaptchaKey;
  recaptchaLoaded: boolean = false;
  
  // Agregar AuthService al constructor
  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.sidenavService.setShowSidenav(false);
    this.loadRecaptchaScript();
  }

  ngOnDestroy() {
    this.sidenavService.setShowSidenav(true);
  }

  login(token: string) {    
    this.loading = true;
    this.authService.getAuthTokenAsync(this.userInput, this.passwordInput, token).subscribe({
      next: async (response: TokenModel) => {
        const data = this.getDecodedAccessToken(response.access_token);
        sessionStorage.setItem(AppConstants.Session.ACCESS_TOKEN, response.access_token);
        // #region SessionValues
        sessionStorage.setItem(AppConstants.Session.CHANGEPASSWORD, data['changePassword']);
        sessionStorage.setItem(AppConstants.Session.EMAIL, data['email']);
        sessionStorage.setItem(AppConstants.Session.EXPIREDPASSWORD, data['expiredPassword']);
        sessionStorage.setItem(AppConstants.Session.TIMESECONDS, '50000');
        sessionStorage.setItem(AppConstants.Session.USERID, data['userId']);
        sessionStorage.setItem(AppConstants.Session.USERNAME, data['userName']);
        sessionStorage.setItem(AppConstants.Session.USERROLID, data['rolId']);
        sessionStorage.setItem(AppConstants.Session.USERROLNAME, data['rolName']);
        // #endregion
        forkJoin([
          this.userService.getUserDetails()
        ])
        .subscribe({
          next: (response: any) => {
            const agency = response[0];
            sessionStorage.setItem(AppConstants.Session.AGENCYCODE, agency['agencyCode']);
            sessionStorage.setItem(AppConstants.Session.AGENCYNAME, agency['agencyName']);
            sessionStorage.setItem(AppConstants.Session.SALESCHANNEL, agency['salesChannelId']);
            sessionStorage.setItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION, agency['salesChannelName']);
            sessionStorage.setItem(AppConstants.Session.SALESSUBCHANNEL, agency['salesSubChannelId']);
            sessionStorage.setItem(AppConstants.Session.SALES_SUB_CHANNEL_DESCRIPTION, agency['salesSubChannelName']);
            sessionStorage.setItem(AppConstants.Session.ENTERPRISE, agency['enterpriseId']);
            sessionStorage.setItem(AppConstants.Session.ENTERPRISE_DESCRIPTION, agency['enterpriseName']);

            //localStorage.setItem('isLoggedIn', 'true');
            this.router.navigate(['home']);
            this.loading = false;
          },
          error: (e) => {
            console.error(e)
            this.loading = false;
            this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.LOGIN_ERROR, MessageSeverity.error);
          }
        });          
      },
      error: (error) => {
        // this.appComponent.loading = false;
        // this.loading = false;
        this.loading = false;
        let message!: string;
        switch (error.error.error) {
          case '1':
              message = AppConstants.MessageAlert.USER_DOESNT_EXIST;
              break;
          case '2':
              message = AppConstants.MessageAlert.PASSWORD_DOESNT_CORRECT;
              break;
          case '3':
              message = AppConstants.MessageAlert.USER_IS_INACTIVE;
              break;
          case '5':
              message = AppConstants.MessageAlert.USER_IS_LOCKED;
              break;
          case '10':
              message = AppConstants.MessageAlert.SUSPICIOUS_ACTIVITY;
          break;
        }
        this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, message, MessageSeverity.error);
      }
    });    
  }

  resetErrorState(inputType: string) {
    if (inputType === 'userInput') {
      this.isErrorUser = this.userInput.length === 0;
    } else if (inputType === 'passwordInput') {
      this.isErrorPass = this.passwordInput.length === 0;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
        return jwtDecode(token);
    } catch (Error) {
        return null;
    }
  }

  loginHandler(): void {
    if(this.validateLoginForm()) {
      this.executeRecaptchaLogin();
    }
  }

  validateLoginForm(): boolean {
    this.isErrorUser = !this.userInput;
    this.isErrorPass = !this.passwordInput;

    return !this.isErrorUser && !this.isErrorPass;
  }

  loadRecaptchaScript(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.recaptchaLoaded = true;
    };
    script.onerror = (error) => {
      console.error('Error al cargar el script de reCAPTCHA Enterprise', error);
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE, MessageSeverity.error);
    };
    document.head.appendChild(script);
  }

  executeRecaptchaLogin(): void {
    if (this.recaptchaLoaded && window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.execute(this.siteKey, { action: AppConstants.RecaptchaEnterprise.Login_event })
        .then((token: string) => {
          this.login(token);
        });
    } else {
      this.alertService.showMessage(AppConstants.TitleAlert.LOGIN_TITLE, AppConstants.MessageAlert.ERROR_GOOGLE_RECAPTCHA_ENTERPRISE_OFF, MessageSeverity.error);
      console.error('reCAPTCHA Enterprise no está disponible.');
    }
  }

}
