import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomTableSimulatorComponent, DynamicModalComponent } from 'app/components';
import { Router,ActivatedRoute} from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-admin-usuarios-acciones',
  templateUrl: './admin-usuarios-acciones.component.html',
  styleUrls: ['./admin-usuarios-acciones.component.scss']
})
export class AdminUsuariosAccionesComponent {

  showModal = false;
  esMovil: boolean = false;
  isError: boolean = false;
  nroResultados: number = 12;
  textoCabecera: string="";

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;
  }
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const accion = params['parametro']; 
      console.log("VERRR",accion)
      if (accion === 'add') {
        this.textoCabecera = "Agregando Usuario";
      } else {
        this.textoCabecera = "Actualizar Usuario";
      }
    });
  }
  


}
