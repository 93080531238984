import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoanSimulationRequest } from '../models/loanSimulationRequest';
import { LoanSimulationResponse } from '../models/loanSimulationResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoansimualtionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "loan/simulation";
  private BASE_URL: string = environment.loanSimulationApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postGetSimulacion(model: LoanSimulationRequest): Observable<LoanSimulationResponse[]> {
    return this.httpClient.post<LoanSimulationResponse[]>(`${this.REQUEST_URL}/retrieve`, model);
  }

}
