import { ChangeDetectorRef, Component, ViewChild,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { CustomTableSimulatorComponent } from 'app/components';
import { LoanSimulationRequest } from 'app/models/loanSimulationRequest';
import { LoansimualtionService } from 'app/services/loansimualtion.service';
import { OptionalInsurancesService } from 'app/services/optional-insurances.service';
import { AppConstants } from 'app/shared/constants/app-constants';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { FormControl } from '@angular/forms';
import { ParameterService } from 'app/services/parameter.service';

@Component({
  selector: 'app-simulador-oferta',
  templateUrl: './simulador-oferta.component.html',
  styleUrls: ['./simulador-oferta.component.scss']
})
export class SimuladorOfertaComponent implements OnInit{
  @ViewChild('myTable', { static: true }) tableComponent!: CustomTableSimulatorComponent;
  simulationCount: number = 1;

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  offers: any[] = JSON.parse(sessionStorage.getItem(AppConstants.Session.OFFERS) ?? '[]');

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loansimualtionService: LoansimualtionService,
    private optionalInsurancesService: OptionalInsurancesService,
    private alertService: AlertService,
    private router: Router,
    private parameterService: ParameterService,
  )  {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    this.disburmentDate = `${year}-${month}-${day}`;
    const data = Array.from({ length: this.product.installmentsMax - this.product.installments + 1 }, (_, i) => (i + this.product.installments).toString());
    this.options = data;
  }

  async ngOnInit() {

    let model = {
      insuranceType: "OPT",
      insuranceTax: "U",
      loanProduct: "PTMN",
      loanPath: "PDRA"
    };

    await this.obtenerSegurosOptativos(model);
    await this.getParameters([
      AppConstants.ParameterCode.PAY_DAYS,
      AppConstants.ParameterCode.DESGRAVAMEN_TYPE,
      AppConstants.ParameterCode.NUMBER_INSTALLMENTS,
    ]);
  }

  ngAfterViewInit() {
    // Detecta los cambios después de la vista
    this.cdr.detectChanges();
  }
  
  private nextColumnToFill = 0;

  elementos: any = [];  
  diaPagoOptions: any = [];
  tipoDesgravameOptions: any = [];
  numeroCuotasOptions: any = [];
  arraySegurosOptativos: any = [];
  isSearchDisabled: boolean = true;
  
  loading: boolean = false;
  desgravamen: string = "005";
  disburmentDate!: string;
  firstExpiration!: string;
  typeInputNumber: string = 'tel';

  
  montoValue: string = this.product.amount+'';
  gracePerido: string = "0";
  paymentDay: string = "3";
  quotas: string = this.product.installments+'';
  clientMaritalStatus: string = "";
  clientAge: string = '0';
  amountMin = 1500;

  arraySegurosOptativosSelecionados: any = [];
  
  // searchText: string = '12';
  // options: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];
  // options: string[] = ['12', '13', '14', '15', '18'];
  options: string[] = [];

  onComponentReady() {
    this.fillNextColumn();
  }

  fillNextColumn(event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (this.tableComponent) {
      
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();

      let model = new LoanSimulationRequest();
          model.applicationAmmount = parseFloat(this.montoValue).toFixed(2);
          model.gracePeriod = +this.gracePerido;
          model.paymentDay = +this.paymentDay;
          model.applicationQuota = +this.quotas;
          model.desgravamenCode = this.desgravamen;
          model.disbursementDate = +this.disburmentDate.replaceAll("-","");
          model.currency = this.product.currency;
          model.interes = parseFloat((+this.product.tea).toString()).toFixed(6);
          model.applicationDate = +`${year}${month}${day}`;
          model.productCode = this.product.offerProductCode;
          model.subProductCode = this.product.offerSubProductCode;

          this.orquestacionSimulador(model);

    } else {
      console.error('Table component is not available yet.');
    }
  }

  simularPrestamo(model : LoanSimulationRequest, opcionalInsurances: any): void {
    this.loading = true;
    this.loansimualtionService.postGetSimulacion(model).subscribe({
      next: async (response: any) => {
        this.firstExpiration = response.firstExpiration;
        this.tableComponent.addSimulation(response,this.nextColumnToFill, opcionalInsurances);        
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, AppConstants.MessageAlert.SIMULATION_SUCCESS, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, AppConstants.MessageAlert.SIMULATION_ERROR, MessageSeverity.error);
        console.log(error);
      }
    });
  }

  async obtenerSegurosOptativosOld(model: any)  {
    this.arraySegurosOptativos = [];
    this.loading = true;
    await this.optionalInsurancesService.postObtenerSegurosOptativos(model).subscribe({
      next: (response: any) => {
        response.optionalInsurances.forEach((element: any) => {

          let arrayPlanes: any = [
            {
              label: 'SIN SEGURO.', 
              value: null, 
              type: element.insuranceCode, 
              endosable: null
            }
          ];
          element.plans.forEach((plan: any) => {
            arrayPlanes.push({ 
              label: plan.planName, 
              value: plan.planCode, 
              type: element.insuranceCode, 
              endosable: element.flagEndosable
            });
          });

          this.arraySegurosOptativos.push({
            label: element.insuranceName,
            code: element.insuranceCode,
            options: arrayPlanes,
            selectedValue: null
          });

        });
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_SUCCESS, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_ERROR, MessageSeverity.error);
      }
      
    });
  }

  async obtenerSegurosOptativos(model: any)  {
    this.arraySegurosOptativos = [];
    this.loading = true;
    await lastValueFrom(this.optionalInsurancesService.postObtenerSegurosOptativos(model)).then((response: any) => {
      response.optionalInsurances.forEach((element: any) => {

        let arrayPlanes: any = [
          {
            label: 'SIN SEGURO.', 
            value: null, 
            type: element.insuranceCode, 
            endosable: null
          }
        ];
        element.plans.forEach((plan: any) => {
          arrayPlanes.push({ 
            label: plan.planName, 
            value: plan.planCode, 
            type: element.insuranceCode, 
            endosable: element.flagEndosable
          });
        });

        this.arraySegurosOptativos.push({
          label: element.insuranceName,
          code: element.insuranceCode,
          options: arrayPlanes,
          selectedValue: null
        });

      });
      this.loading = false;
      this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_SUCCESS, MessageSeverity.success);
    },
    (error) => {
      this.loading = false;
      console.error(error);
      this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_RETRIEVE_ERROR, MessageSeverity.error);
    });
  }

  onElementoChange(element: any) {
    const index = this.arraySegurosOptativosSelecionados.findIndex((e: any) => e.code === element.code);
    this.clearSimulation();

    if(index != -1 || element.selectedValue === 'null'){
      this.arraySegurosOptativosSelecionados.splice(index, 1);      
    }

    if(element.selectedValue != 'null'){
      this.showNotificationOptionalInsurances(element);
      let plan = element.options.find((p:  any) => p.type === element.code && p.value === element.selectedValue);
      this.arraySegurosOptativosSelecionados.push(
        {
          code: element.code,
          description: element.label,
          plan: element.selectedValue,
          planName: plan.label,
          endosable: plan.endosable        
        }
      );
    }
  }

  calcularPrimaSeguroOptativo(model: any): void {
    this.loading = true;
    this.optionalInsurancesService.postCalcularaPrimaSeguroOptativo(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        // this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.SIMULATION_SUCCESS, MessageSeverity.success);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, AppConstants.MessageAlert.OPTIONAL_INSURANCE_CALCULATION, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  orquestacionSimulador(model: LoanSimulationRequest): void {

    /*validacion*/
    if(this.quotas.length == 0) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_NOT_QUOTA_ERROR}`, MessageSeverity.error, 5000);
      return;
    }
    
    if (!(+this.quotas >= this.product.installments && +this.quotas <= this.product.installmentsMax))  {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `Las cuotas permitidas son de ${this.product.installments} a ${this.product.installmentsMax} meses.`, MessageSeverity.error, 5000);
      return;
    }
    if(+this.montoValue < this.amountMin) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MIN_ERROR} ${this.product.currencySymbol} ${this.amountMin}`, MessageSeverity.warn, 5000);
      return;
    }
    if(+this.montoValue > this.product.amount) {
      this.alertService.showMessage(AppConstants.TitleAlert.SIMULATION_TITLE, `${AppConstants.MessageAlert.SIMULATION_VALIDATOR_AMOUNT_MAX_ERROR} ${this.product.currencySymbol} ${this.product.amount}`, MessageSeverity.warn, 5000);
      return;
    }
    /*dValidaciones*/

    this.clearSimulation();
    if(this.arraySegurosOptativosSelecionados.length > 0) {
      this.loading = true;
      const sources: any = [];
      this.arraySegurosOptativosSelecionados.forEach((element: any) => {

        let model = {
          clientAge: this.clientAge,
          maritalStatus: this.clientMaritalStatus,
          insuranceCode: element.code,
          planCode: element.plan,
          currency: this.product.currency,
          applicationAmmount: parseFloat(this.montoValue).toFixed(2),
          applicationQuota: +this.quotas
        };

        sources.push(this.optionalInsurancesService.postCalcularaPrimaSeguroOptativo(model));
      });

      forkJoin(sources)
      .subscribe({
        next: (response: any) => {
          for(let index = 0; index < response.length; index++) {
            const element = response[index];
            this.arraySegurosOptativosSelecionados.forEach((insurance: any) => {
              if(insurance.code === element.insuranceCode && insurance.plan === element.planCode) 
              {
                insurance.amountBruto = element.amountBruto;
                insurance.amountNeto = element.amountNeto;
                insurance.currency = element.currency;
              }
            });
            
          }      
          this.loading = false;
        },
        error: (e) => {
          console.error(e)
          this.loading = false;
          this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, e.error.message, MessageSeverity.error);
        },
        complete: () => {
          this.loading = false;
          var lifeInsuranceValue = 0;

          this.arraySegurosOptativosSelecionados.forEach((element: any) => {
            if(element.code === AppConstants.OptionalInsuracesCode.UNEMPLOYMENT)
            {
              model.unemploymentInsurance = element.amountBruto;
            }
            if(element.code === AppConstants.OptionalInsuracesCode.LIFE)
            {
              lifeInsuranceValue = element.amountBruto;
            }
          });
          model.lifeInsurance = lifeInsuranceValue;

          this.simularPrestamo(model, this.arraySegurosOptativosSelecionados);
        }
      });
      
    }
    else {
      model.unemploymentInsurance = 0;
      model.lifeInsurance = 0;
      this.simularPrestamo(model, this.arraySegurosOptativosSelecionados);
    }
  }

  returnSearchOffers() {
    this.router.navigate(['home']);
  }

  onValueChange(value: string) {
    this.quotas = value;
    this.clearSimulation();
    // this.cdr.markForCheck();
  }

  async getParameters(array: Array<number>) {
    this.loading = true;
    await lastValueFrom(this.parameterService.getParametersList(array)).then((response: any) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  setVariablesParameters(listParameters: any[]) {

    let paydays = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.PAY_DAYS && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        paydays.forEach((element: any) => {
          this.diaPagoOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

    let degravamen = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.DESGRAVAMEN_TYPE && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        degravamen.forEach((element: any) => {
          this.tipoDesgravameOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

    let numberInstallments = (listParameters.filter((f: any) => f.parameterCode == AppConstants.ParameterCode.NUMBER_INSTALLMENTS && f.state == AppConstants.StateFlag.Active)).sort((a: any, b: any) => (a.parameterDetCode004 < b.parameterDetCode004 ? -1 : 1));
        numberInstallments.forEach((element: any) => {
          this.numeroCuotasOptions.push({ label: element.parameterDetCode002, value: element.parameterDetCode001 });
        });

  }

  clearSimulation() {
    this.tableComponent.clearSimulation(this.nextColumnToFill);
  }

  showNotificationOptionalInsurances(data: any) {
    console.log(data);
    try {
      if(data.code === AppConstants.OptionalInsuracesCode.LIFE) {
        const ageageRange = data.selectedValue === 'DV01' ? ' 18 a 34 ' :
        data.selectedValue === 'DV02' ? '35 a 44' :
        data.selectedValue === 'DV03' ? '45 a 54' :
        data.selectedValue === 'DV04' ? '55 a 64' : '';
        this.alertService.showMessage(AppConstants.TitleAlert.OPTIONAL_INSURENCES_TITLE, `El plan seleccionado corresponde al rango de edad de ${ageageRange} años cumplidos.`, MessageSeverity.info);
      }
    } catch (error) {
      console.error(error);      
    }
  }

}
