<img *ngIf="!esMovil" src="assets/img/04.svg" class="stepper">
<img *ngIf="esMovil" src="assets/04.svg" class="stepper">
<div class="layout-container">
  <div class="container-datos-cliente">
    <app-datos-cliente (abrirModal)="openAttachmentModal()"></app-datos-cliente>
  </div>
  
    <div class="formulario-container">
      <label class="primer-parrafo">BANBIF declara que cualquier tratamiento de los datos personales, se ajusta a las normativas de protección de datos personales vigentes y aplicables dentro de territorio peruano.</label>
      <strong>INFORMACIÓN SOBRE EL TRATAMIENTO OBLIGATORIO DE DATOS PERSONALES:</strong>
    <label class="segundo-parrafo">
      Para la prestación de los servicios aceptados, se realizará el tratamiento de su nombre, apellidos, números de documento de identidad , datos biométricos, dirección, correo electrónico, teléfono.
    </label>
      <label class="tercer-parrafo">
        La información mencionada en el párrafo precedente es necesaria para el cumplimiento de las obligaciones acordadas. Entre los servicios y/o disposiciones mencionados se encuentran: i) el otorgamiento de productos financieros y/o de seguros, ii) la creación y gestión de cuenta de usuario, iii) la atención de solicitudes y reclamos. Adicionalmente, se custodiará la información correspondiente a la contratación de productos según la normativa aplicable a las empresas del sector financiero nacional.
      </label>
    <strong class="segundo-titulo">CONCENTIMIENTO PARA EL TRATAMIENTO PARA FINALIDADES ADICIONALES:</strong>

    <div class="radio-group">
        <input type="radio" [(ngModel)]="dataProtection" id="opcion-si" name="consentimiento"  value="1" class="radio-input" hidden>
        <label for="opcion-si" class="radio-custom"></label><label for="opcion-si" class="radio-option">SÍ</label>
        <label class="espacio"></label>
        <input type="radio" [(ngModel)]="dataProtection" id="opcion-no" name="consentimiento"  value="0" class="radio-input" hidden>
        <label for="opcion-no" class="radio-custom"></label><label for="opcion-no" class="radio-option">NO</label>
    </div>
    <br>
    <label>
      Con el presente concentimiento el cliente acepta que sus datos personales sean tratados para:
      </label>
      <label>
a. Enviar información comencial, promocional y/o publicidad con relación a los productos y/o servicios de BANBIF.
</label>
<label>
b. Almacenar y tratar los datos personales con fines estadístidos y/o históricos. 
    </label>
    </div>  
</div>


  <div class="container-buttons">
    <div class="footer-buttons">
      <a href="#" class="link-atras">ATRÁS</a>
      <app-custom-button
      text="siguiente" (action)="nextProcess()" ></app-custom-button>
  </div>
  </div>

  <app-adjunto-modal *ngIf="showModal" 
    (onClose)="handleClose()" 
    (onFileSelectedAction)="handleSelectFileAction($event)" 
    (onFileDownloadAction)="handleFileDownload($event)" 
    (onFileDeleteAction)="handleFileDelete($event)" 
    [applicationFiles]="filesList"
    [(value)]="comment"
    (onSave)="sendCommentHandler()">
  </app-adjunto-modal>

  <loading *ngIf="loading"></loading>   