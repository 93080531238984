import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OptionalInsurancesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "insurances";
  private BASE_URL: string = environment.optionalInsurancesApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postObtenerSegurosOptativos(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/optional/retrieve`, model);
  }

  postCalcularaPrimaSeguroOptativo(model: any): Observable<any> {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/optional-calculation/retrieve`, model);
  }
  
}
