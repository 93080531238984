import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ProfileGuard } from './guards/profile.guard';
import { AppConstants } from './shared/constants/app-constants';
import { AccessDeniedComponent } from './modules';

const routes: Routes = [
  {path: '',redirectTo:'login',pathMatch:'full'},
  {
    path:'login',
    loadChildren:() => import('./modules').then(m => m.LoginModule)
  },
  {
    path:'home',
    loadChildren:() => import('./modules').then(m => m.InicioModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM,
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.SUPERVISOR,
        AppConstants.Roles.ANALISTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER,
        AppConstants.Roles.SUPERVISOR_CALL,
        AppConstants.Roles.OPERADOR,
        AppConstants.Roles.REPORTES_BI,
        AppConstants.Roles.JEFATURA_RED
      ]
    }
  },
  {
    path:'products',
    loadChildren:() => import('./modules').then(m => m.HomeModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER
      ]
    }
  },
  {
    path:'simulador-oferta',
    loadChildren:() => import('./modules').then(m => m.SimuladorOfertaModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER
      ]
    }
  },
  {
    path:'autenticacion',
    loadChildren:() => import('./modules').then(m => m.AutentiacionModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER
      ]
    }
  },
  {
    path:'datos-personales',
    loadChildren:() => import('./modules').then(m => m.DatosPersonalesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER
      ]
    }
  },
  {
    path:'resumen',
    loadChildren:() => import('./modules').then(m => m.ResumenModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER
      ]
    }
  },
  {
    path:'finalizacion',
    loadChildren:() => import('./modules').then(m => m.FinalizacionModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER,
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'bandeja-solicitudes',
    loadChildren:() => import('./modules').then(m => m.BandejaSolicitudesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM,
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.SUPERVISOR,
        AppConstants.Roles.ANALISTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER,
        AppConstants.Roles.SUPERVISOR_CALL,
        AppConstants.Roles.JEFATURA_RED,
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'bandeja-solicitudes-producto',
    loadChildren:() => import('./modules').then(m => m.BandejaSolicitudesProductoModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM,
        AppConstants.Roles.ASESOR_VENTA,
        AppConstants.Roles.ANALISTA,
        AppConstants.Roles.ASESOR_VENTA_CALLCENTER,
        AppConstants.Roles.SUPERVISOR_CALL,
        AppConstants.Roles.JEFATURA_RED
      ]
    }
  },
  {
    path:'pasivos-validacion',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionModule),
    canActivate: [AuthGuard, ProfileGuard]
  },
  {
    path:'pasivos-validacion-correo',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionModule),
    canActivate: [AuthGuard, ProfileGuard]
  },
  {
    path:'pasivos-home',
    loadChildren:() => import('./modules').then(m => m.PasivosHomeModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'pasivos-datos-personales',
    loadChildren:() => import('./modules').then(m => m.PasivosDatosPersonalesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'pasivos-resumen',
    loadChildren:() => import('./modules').then(m => m.PasivosResumenModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'pasivos-validacion-biometrica',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionBiometricaModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'admin-usuarios',
    loadChildren:() => import('./modules').then(m => m.AdminUsuariosModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'admin-usuarios-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminUsuariosAccionesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'admin-empresas',
    loadChildren:() => import('./modules').then(m => m.AdminEmpresasModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'admin-empresas-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminEmpresasAccionesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'admin-parametros',
    loadChildren:() => import('./modules').then(m => m.AdminParametrosModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'admin-parametros-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminParametrosAccionesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM
      ]
    }
  },
  {
    path:'reportes',
    loadChildren:() => import('./modules').then(m => m.ReportesModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM,
        AppConstants.Roles.ANALISTA,
        AppConstants.Roles.REPORTES_BI,
        AppConstants.Roles.JEFATURA_RED
      ]
    }
  },
  {
    path:'access-denied',
    loadChildren:() => import('./modules').then(m => m.AccessDeniedModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pasivos-autenticacion',
    loadChildren:() => import('./modules').then(m => m.PasivosAutenticacionModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path: 'pasivos-finalizacion',
    loadChildren:() => import('./modules').then(m => m.PasivosFinalizacionModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [        
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  {
    path:'bandeja-solicitudes-producto-pasivo',
    loadChildren:() => import('./modules').then(m => m.BandejaSolicitudesProductoPasivosModule),
    canActivate: [AuthGuard, ProfileGuard],
    data: {
      profiles : [
        AppConstants.Roles.ADM,
        AppConstants.Roles.SUPERVISOR,
        AppConstants.Roles.OPERADOR
      ]
    }
  },
  { path: '**', component: AccessDeniedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
