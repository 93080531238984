import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import { CustomButtonComponent, CustomInputComponent, HeaderComponent,CustomInpuntSelectComponent,
  CustomTableSimulatorComponent,FooterComponent,ModalComponent,InfoModalComponent, CustomButtonColorComponent} from 'app/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { InputMaterialComponent } from 'app/components/input-material';
import { SharedModule } from 'app/shared/modules/shared/shared.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    MatToolbarModule,
    HeaderComponent,
    CustomButtonComponent,
    CustomInputComponent,
    CustomInpuntSelectComponent,
    CustomTableSimulatorComponent,
    CustomButtonColorComponent,
    InfoModalComponent,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    FooterComponent,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,MatSelectModule, FormsModule, ReactiveFormsModule,
    ModalComponent,InputMaterialComponent,
    SharedModule
  ]
})
export class HomeModule { }
