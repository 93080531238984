import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "application/user";
  private BASE_URL: string = environment.applicationApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getUserDetails() {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/getUserDetails`);
  }

  postValidatUser(model: any) {
    return this.httpClient.post<any>(`${this.REQUEST_URL}/validate`, model);
  }

}
