<app-header [headerText]="'Reportes'" headerImageSrc="assets/bandejaSolicitudes.svg"></app-header>

<div class="layout-container">
    <div class="contenedor-filtros" style="justify-content: left;">
        <label for="" class="label-report-name">Reporte Consolidado Solicitudes</label>
    </div>
    <div class="contenedor-filtros">
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Inicio</label>
                <app-custom-datepicker [(value)]="dateFromReport1"></app-custom-datepicker>
            </div>
        </div>
        
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Fin</label>
                <app-custom-datepicker [(value)]="dateUntilReport1"></app-custom-datepicker>
            </div>
        </div>
    
        <div class="filtro-botones">    
            <app-custom-button text="DESCARGAR" (action)="seachReportApplication()"></app-custom-button>
        </div>
    </div>    
</div>

<div class="layout-container">
    <div class="contenedor-filtros" style="justify-content: left;">
        <label for="" class="label-report-name">Reporte Trama Datalake</label>
    </div>
    <div class="contenedor-filtros">
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Inicio</label>
                <app-custom-datepicker [(value)]="dateFromReport2"></app-custom-datepicker>
            </div>
        </div>
        
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Fin</label>
                <app-custom-datepicker [(value)]="dateUntilReport2"></app-custom-datepicker>
            </div>
        </div>
    
        <div class="filtro-botones">    
            <app-custom-button text="DESCARGAR" (action)="seachReportTrama()"></app-custom-button>
        </div>
    </div>    
</div>

<div class="layout-container">
    <div class="contenedor-filtros" style="justify-content: left;">
        <label for="" class="label-report-name">Reporte Seguros Optativos</label>
    </div>
    <div class="contenedor-filtros">
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Inicio</label>
                <app-custom-datepicker [(value)]="dateFromReport3"></app-custom-datepicker>
            </div>
        </div>
        
        <div class="filtro-inputs">
            <div class="form-group filtro-3">
                <label class="titulo-filtro">Fecha Fin</label>
                <app-custom-datepicker [(value)]="dateUntilReport3"></app-custom-datepicker>
            </div>
        </div>
    
        <div class="filtro-botones">    
            <app-custom-button text="DESCARGAR" (action)="seachReportOptionalInsurance()"></app-custom-button>
        </div>
    </div>    
</div>

<app-footer></app-footer>

<loading *ngIf="loading"></loading>