
<app-header [headerText]="'1. Apertura de cuenta'" headerImageSrc="assets/busqueda.svg"></app-header>

<!-- Sección del Formulario -->
<div class="formulario-container">
  <!-- Contenedor Flex para los Elementos del Formulario -->
  <div class="form-flex-container">
      <!-- Texto de Instrucción -->
      <div class="titulo">
          <label class="form-instruction">Completa la información para realizar la búsqueda:</label>
      </div>

      <div class="container-busqueda">
        <div class="form-busqueda">
          <div class="form-group tipo-doc">
            <label>Tipo de Documento</label>
            <app-custom-inpunt-select 
                                      [options]="documents"
                                      [(selectedValue)]="selectedTypeDocument"
                                      (change)="typeOperation();">
            </app-custom-inpunt-select>
          </div>
      
          <div class="form-group nro-doc">
              <label>Nro. de Documento</label>
            <!-- <app-custom-input [(value)]="inputValue" [isError]="isError" [placeholderText]="miPlaceholder"></app-custom-input> -->

            <app-custom-input 
                  [(value)]="documentForm"
                  [validateNumber]="true"
                  [maxlength]="max"
                  [isError]="isError"
                  [type]="typeInputDocument"
                  [placeholderText]="miPlaceholder"
                  (input)="removeSpacesDocument();onlyNumber();searchDocument()"
                  (keyup.enter)="searchDocument()">
            </app-custom-input>

          </div>

         
        </div>

        <div class="form-group button-container">
          <app-custom-button iconUrl="assets/img/buscar.svg" 
                            text="Buscar" 
                            (action)="searchDocument()">
          </app-custom-button>
        </div>
      </div>


  </div>
</div>

<!-- <div *ngIf="mostrarInformacion" class="contenedor-informacion">
  <div class="enlace-atras">
    <a href="#" class="link-estilo">ATRÁS</a>
  </div>
</div> -->
<app-modal *ngIf="showModal" [width]="400" [height]="300" [imageUrl]="'assets/img/errorImg.svg'"
           [titleText]="tituloModal" [subtitleText]="subTituloModal"
           [paragraphText]="parrafo" [linkText]="textoEnlaceModal"
           [showCustomButtons]="false"
           (linkAction)="cerrarModal()">
</app-modal>
<app-footer></app-footer>
<loading *ngIf="loading"></loading>
<!-- <div class="content-container">
  <div class="form-container">
    <form class="search-form">
      <div class="form-field">
        <span>Completa la información  para <br> realizar la búsqueda:</span>
      </div>

      <div class="form-field">
        <label>Tipo de Documento</label>
        <app-custom-inpunt-select [options]="tipoDocumentoOptions"
        [(selectedValue)]="selectedTipoDocumento"
        placeholder="Selecciona el tipo de documento">
        </app-custom-inpunt-select>
      </div>

      <div class="form-field" >
        <label>Nro. de Documento</label>
       <app-custom-input></app-custom-input>
      </div>
    </form>
    
    <div class="button-container">
      <app-custom-button
      iconUrl="assets/img/buscar.svg" 
      text="Buscar" 
      [isDisabled]="isSearchDisabled"
      (action)="abrirModal()">
    </app-custom-button>
    <app-custom-button text="Click me" (action)="fillSecondColumn()"></app-custom-button>
    <app-custom-button  iconUrl="assets/img/buscar.svg" ></app-custom-button>

    </div>
<br>
    <app-custom-table-simulator #myTable></app-custom-table-simulator>
    <app-footer></app-footer>
  </div>
</div>
 -->

