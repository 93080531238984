
<div class="layout-container">

  <div *ngIf="!esMovil" class="formulario-container">
      <div class="form-row">
        <div class="form-group tipo-via">
          <label>Tipo de Vía</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesTipoVia" [isReadonly]="elementsReadOnly.includes('tipoVia')"
          [(selectedValue)]="tipoVia"  (selectedValueChange)="tipoVia=$event" [isError]="isErrorTipoVia">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group nombre-via">
          <label>Nombre de vía</label>
          <app-custom-input [(value)]="nombreVia" [isError]="isErrorNombreVia" [isReadonly]="elementsReadOnly.includes('nombreVia')"
          (keyup)="resetErrorState('nombreVia')" [minlength]="nombreViaValMin" [maxlength]="nombreViaValMax"></app-custom-input>
        </div>

        <div class="form-group numero-via">
          <label>Número</label>
          <app-custom-input [(value)]="numero" [type]="typeInputNumber" [maxlength]="max" [isError]="isErrorNumero" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('numero')"
          (keyup)="resetErrorState('numero')" [minlength]="numeroValMin" [maxlength]="numeroValMax"></app-custom-input>
        </div>

    
      </div>
      
      <div class="form-row">
        <div class="form-group interior">
          <label>Interior</label>
          <app-custom-input [(value)]="interior" [isError]="isErrorInterior" [isReadonly]="elementsReadOnly.includes('interior')"
          (keyup)="resetErrorState('interior')" [minlength]="interiorValMin" [maxlength]="interiorValMax"></app-custom-input>
        </div>
        <div class="form-group urbanizacion">
          <label>Urbanización</label>
          <app-custom-input [(value)]="urbanizacion" [isError]="isErrorUrbanizacion" [isReadonly]="elementsReadOnly.includes('urbanizacion')"
          (keyup)="resetErrorState('urbanizacion')" [minlength]="urbanizacionValMin" [maxlength]="urbanizacionValMax"></app-custom-input>
        </div>
      </div>

      <div class="form-row">
  
        <div class="form-group referencia">
          <label>Referencia</label>
          <app-custom-input [(value)]="referencia" [isError]="isErrorReferencia" [isReadonly]="elementsReadOnly.includes('referencia')"
          (keyup)="resetErrorState('referencia')" [minlength]="referenciaValMin" [maxlength]="referenciaValMax"></app-custom-input>

        </div>
        <div class="form-group departamento">
          <label>Departamento</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDepartamento" [isReadonly]="elementsReadOnly.includes('departamento')"
          [(selectedValue)]="departamento"  (selectedValueChange)="departamento=$event" [isError]="isErrorDepartamento" (change)="getProvinces($event.target)">
          </app-custom-inpunt-select>
        </div>

      </div>

      <div class="form-row">
  
        <div class="form-group provincia">
          <label>Provincia</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesProvincia" [isReadonly]="elementsReadOnly.includes('provincia')"
          [(selectedValue)]="provincia"  (selectedValueChange)="provincia=$event" [isError]="isErrorProvincia" (change)="getDistricts($event.target)">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group distrito">
          <label>Distrito</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDistrito" [isReadonly]="elementsReadOnly.includes('distrito')"
          [(selectedValue)]="distrito"  (selectedValueChange)="distrito=$event" [isError]="isErrorDistrito">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group interior">
          <label>Teléfono fijo</label>
          <app-custom-input [(value)]="telefono" [isError]="isErrorTelefono" [type]="typeInputNumber" [isReadonly]="elementsReadOnly.includes('telefono')"
          (keyup)="resetErrorState('telefono')" [minlength]="telefonoValMin" [maxlength]="telefonoValMax"></app-custom-input>
        </div>
          
      </div>

      <div class="form-row">
        <div class="form-group referencia">
          <label>Correo Electrónico</label>
          <app-custom-input [(value)]="email" [isError]="isErrorEmail" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('email')"
          (keyup)="resetErrorState('email')" [maxlength]="emailValMax"></app-custom-input>
        </div>
        <div class="form-group interior">
          <label>Número Celular</label>
          <app-custom-input [(value)]="cellphone" [isError]="isErrorCellphone" [type]="typeInputNumber" [maxlength]="max" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('cellphone')"
          (keyup)="resetErrorState('cellphone')">
          </app-custom-input>
        </div> 
      </div>

  </div>
  
  <div *ngIf="esMovil"  class="formulario-container">
    <div class="form-row">
      <div class="form-group tipo-via-movil">
        <label>Tipo de Vía</label>
        <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesTipoVia" [isReadonly]="elementsReadOnly.includes('tipoVia')"
        [(selectedValue)]="tipoVia"  (selectedValueChange)="tipoVia=$event" [isError]="isErrorTipoVia">
        </app-custom-inpunt-select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group nombre-via-movil">
        <label>Nombre de vía</label>
        <app-custom-input [(value)]="nombreVia" [isError]="isErrorNombreVia" [isReadonly]="elementsReadOnly.includes('nombreVia')"
        (keyup)="resetErrorState('nombreVia')" [minlength]="nombreViaValMin" [maxlength]="nombreViaValMax"></app-custom-input>
      </div>

      <div class="form-group numero-movil">
        <label>Número </label>
        <app-custom-input [(value)]="numero" [type]="typeInputNumber" [maxlength]="max" [isError]="isErrorNumero" [validatemaxlength]="false" [isReadonly]="elementsReadOnly.includes('numero')"
        (keyup)="resetErrorState('numero')" [minlength]="numeroValMin" [maxlength]="numeroValMax"></app-custom-input>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group interior-movil">
        <label>Interior</label>
        <app-custom-input [(value)]="interior" [isError]="isErrorInterior" [isReadonly]="elementsReadOnly.includes('interior')"
        (keyup)="resetErrorState('interior')" [minlength]="interiorValMin" [maxlength]="interiorValMax"></app-custom-input>
      </div>

      <div class="form-group urbanizacion-movil">
        <label>Urbanización</label>
        <app-custom-input [(value)]="urbanizacion" [isError]="isErrorUrbanizacion" [isReadonly]="elementsReadOnly.includes('urbanizacion')"
        (keyup)="resetErrorState('urbanizacion')" [minlength]="urbanizacionValMin" [maxlength]="urbanizacionValMax"></app-custom-input>
      </div> 
    </div>

    <div class="form-row">
        <div class="form-group referencia-movil">
          <label>Referencia</label>
          <app-custom-input [(value)]="referencia" [isError]="isErrorReferencia" [isReadonly]="elementsReadOnly.includes('referencia')"
          (keyup)="resetErrorState('referencia')" [minlength]="referenciaValMin" [maxlength]="referenciaValMax"></app-custom-input>

        </div>
        
    </div>
      
    <div class="form-row">
        <div class="form-group departamento-movil">
          <label>Departamento</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDepartamento" [isReadonly]="elementsReadOnly.includes('departamento')"
          [(selectedValue)]="departamento"  (selectedValueChange)="departamento=$event" [isError]="isErrorDepartamento" (change)="getProvinces($event.target)">
          </app-custom-inpunt-select>
        </div>

        <div class="form-group provincia-movil">
          <label>Provincia</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesProvincia" [isReadonly]="elementsReadOnly.includes('provincia')"
          [(selectedValue)]="provincia"  (selectedValueChange)="provincia=$event" [isError]="isErrorProvincia" (change)="getDistricts($event.target)">
          </app-custom-inpunt-select>
        </div>
        
    </div>

    <div class="form-row">
        <div class="form-group distrito-movil">
          <label>Distrito</label>
          <app-custom-inpunt-select  placeholder="Seleccionar" [width]="'190px'" [options]="opcionesDistrito" [isReadonly]="elementsReadOnly.includes('distrito')"
          [(selectedValue)]="distrito"  (selectedValueChange)="distrito=$event" [isError]="isErrorDistrito">
          </app-custom-inpunt-select>
        </div>
        <div class="form-group distrito-movil">
          <label>Teléfono fijo</label>
          <app-custom-input [(value)]="telefono" [isError]="isErrorTelefono" [type]="typeInputNumber" [isReadonly]="elementsReadOnly.includes('telefono')"
          (keyup)="resetErrorState('telefono')" [minlength]="telefonoValMin" [maxlength]="telefonoValMax"></app-custom-input>
        </div>        
    </div>

    <div class="form-row">

      <div class="form-group referencia-movil">
        <label>Correo Electrónico</label>
        <app-custom-input [(value)]="email" [isError]="isErrorEmail" [validateEmail]="true" [isReadonly]="elementsReadOnly.includes('email')"
        (keyup)="resetErrorState('email')" [maxlength]="emailValMax"></app-custom-input>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group distrito-movil">
        <label>Número Celular</label>
        <app-custom-input [(value)]="cellphone" [isError]="isErrorCellphone" [type]="typeInputNumber" [maxlength]="max" [validatemaxlength]="true" [isReadonly]="elementsReadOnly.includes('cellphone')"
        (keyup)="resetErrorState('cellphone')">
        </app-custom-input>
      </div>
    </div>

  </div>  

</div>

<div class="footer-buttons">
<app-custom-button-link text="CANCELAR" (action)="close()"></app-custom-button-link>
<app-custom-button text="ACEPTAR" (action)="validarYCambiarComponente()"></app-custom-button>
</div>
<loading *ngIf="loading"></loading>