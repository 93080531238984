import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ClienteSession, ProductoSession } from 'app/models/sessionModel';
import { AppConstants } from 'app/shared/constants/app-constants';
import { lastValueFrom } from 'rxjs';
import { ApplicationService } from 'app/services/application.service';
import { AlertService, MessageSeverity } from 'app/shared/services/alert.service';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent {

  client: ClienteSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.CLIENT) ?? '{}');
  product: ProductoSession = JSON.parse(sessionStorage.getItem(AppConstants.Session.PRODUCT) ?? '{}');
  applicationId: string = sessionStorage.getItem(AppConstants.Session.APPLICATION_ID) ?? '';
  workflowId: string = sessionStorage.getItem(AppConstants.Session.WORKFLOW_ID) ?? '';
  simulation: any = JSON.parse(sessionStorage.getItem(AppConstants.Session.SIMULACION) ?? '{}');
  rolName: string = sessionStorage.getItem(AppConstants.Session.USERROLNAME) ?? '';
  usuario: string = sessionStorage.getItem(AppConstants.Session.USERID) ?? '';
  userName: string = sessionStorage.getItem(AppConstants.Session.USERNAME) ?? '';
  agenciaName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME) ?? '';
  agenciaCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE) ?? '';

  esMovil: boolean = false;
  showModal = false;
  showModalResumen = false;
  showModalDireccion = false;
  showModalOtros = false;
  showModalDesembolso = false;
  showModalAdjuntos = false;
  modalWidth: number = 650; 
  modalWidthDireccion: number = 700; 
  modalHeightDireccion: number = 440;
  modalWidthAdjuntos: number = 450; 
  modalHeightAdjuntos: number = 300; 
  modalWidthDesembolso: number = 520; 
  modalHeightDesembolso: number = 370; 
  modalWidthOtros: number = 720; 
  modalHeightOtros: number = 850; 
  modalHeight: number = 510;  
  tituloModal='PROCESO DE ALTA';
  textoEnlaceModal='CERRAR';

  loading: boolean = false;
  customer: any;
  disbusementName: string = '';
  firstPaymentDateFormat: string = '';
  optionalInsurancesContracted: any = [];

  filesList: any = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.esMovil = window.innerWidth <= 768;

  }
  constructor(
    private router: Router, 
    private cdr: ChangeDetectorRef,
    private applicationService: ApplicationService,
    private alertService: AlertService) 
  {
    this.esMovil = window.innerWidth <= 768;
  }

  async ngOnInit () {
    await this.getApplication(this.applicationId);
    await this.getOptionalInsurances(this.applicationId);
    await this.getAttachmentFiles(this.applicationId);
  }



  handleClose() {
    this.showModal = false;
    this.showModalDireccion = false;
    this.showModalOtros = false;
    this.showModalDesembolso = false;
    this.showModalAdjuntos=false;
  }

  openModal() {
    this.showModal = true;
    if(this.esMovil) 
    {
      this.modalWidth = 350; 
      this.modalHeight = 600;
    } 
    else  this.modalWidth = 650;
  }

  openModalDireccion() {
    this.showModalDireccion = true;
    if(this.esMovil) 
    {
      this.modalWidthDireccion = 350;
      this.modalHeightDireccion=585;
    } 
    else  this.modalWidthDireccion = 700;
  }

  openModalOtros() {
    this.showModalOtros = true;
    if(this.esMovil) 
    {
      this.modalWidthOtros = 350;
      this.modalHeightOtros=600;
    } 
    else {
      this.modalWidthOtros = 720;
      this.modalHeightOtros=730;
    } 
  }

  openModalDesembolso() {
    this.showModalDesembolso = true;
    if(this.esMovil) 
    {
      this.modalWidthDesembolso = 350;
      this.modalHeightDesembolso = 400;
    } 
    else  this.modalWidthDesembolso = 550;
  }

  openModalAdjuntos() {
    this.showModalAdjuntos = true;

    if(this.esMovil) 
    {
      this.modalWidthAdjuntos = 350;
      this.modalHeightAdjuntos = 350;
    } else{
      this.modalWidthAdjuntos = 450;
    }
  }

  redireccionarFinalizacion(){
   this.router.navigate(['autenticacion']);
  // this.showModalResumen = true;
  }

  handleCloseResumen() {
    this.showModalResumen = false;
  }
  

  
  ngAfterViewInit() {
    this.cdr.detectChanges();
    // this.modalWidth = 350; 
    // this.modalHeight = 600;
    // this.modalWidthDireccion = 370;
    // this.modalHeightDireccion=540;
    // this.modalWidthOtros = 365;
    // this.modalHeightOtros=600;
    // this.modalWidthDesembolso = 365;
    // this.modalWidthAdjuntos = 350;
    // this.modalHeightAdjuntos = 350;
  }

  async getApplication(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getApplication(model)).then((response: any) => {
      this.firstPaymentDateFormat = response.firstaPaymentDate;
      this.customer = response;      
      switch(response.disbursementType) {
        case 0:
          this.disbusementName = AppConstants.DisbursementName.WithoutDisbursement;
          break;
        case 1:
          this.disbusementName = AppConstants.DisbursementName.NewAccount;
          break;
        case 2:
          this.disbusementName = AppConstants.DisbursementName.ExistingAccount;
          break;
        case 3:
          this.disbusementName = AppConstants.DisbursementName.ImmediateInterbankTransfer;
          break;
        default:
      }
      
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async getOptionalInsurances(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getOptionalInsurances(model)).then((response: any) => {
      this.optionalInsurancesContracted = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  nextStepApplication() {

    let model = {
      applicationId: this.applicationId,
      workflowInstanceId: this.workflowId,
      status: AppConstants.ApplicationStates.EnProcesoAutentificacion,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.PRODUCT_ACCEPTANCE,
      channelId: AppConstants.Channels.DesktopPVA,
      taskInstanceRole: this.rolName,
      updatedBy: this.usuario,
      updatedByFullName: this.userName,
      agencyCode: this.agenciaCode,
      agencyName: this.agenciaName
    };

    this.updateStautsApplication(model);

  }

  updateStautsApplication(model: any) {
    this.loading = true;
    this.applicationService.postUpdationStatusApplication(model).subscribe({
      next: async (response: any) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_SUCCESS, MessageSeverity.success);
        this.router.navigate(['autenticacion']);
      },
      error: (error) => {
        this.loading = false;
        this.alertService.showMessage(AppConstants.TitleAlert.APPLICATION_UPDATE_TITLE, AppConstants.MessageAlert.UPDATE_APPLICATION_ERROR, MessageSeverity.error);
        console.error(error);
      }
    });
  }

  handleLinkAction(data: { updateSucess: boolean }) {
    try {
      if(data.updateSucess) {
        this.getApplication(this.applicationId);
      }
    } catch (error) {
      console.error(error);
    }    
  }

  async getAttachmentFiles(model: string) {
    this.loading = true;
    await lastValueFrom(this.applicationService.getFilesApplication(model)).then((response: any) => {
      this.filesList = response;
      this.loading = false;
    },
    (error) => {
        this.loading = false;
        console.error(error);
    });
  }

  async handleLinkAttachmentAction(data: { updateSucess: boolean }) {
    try {
      if(data.updateSucess) {
        await this.getAttachmentFiles(this.applicationId);
      }
    } catch (error) {
      console.error(error);
    }    
  }

  handleSaveCancellationApplication() {

    let model = {
      applicationId: this.applicationId,
      workflowInstanceId: this.workflowId,
      processId: AppConstants.Process.PLD_DRACMA_APROBACION,
      taskId: AppConstants.WorkflowTasks.APPROVAL,
      channelId: AppConstants.Channels.DesktopPVA,
      TaskInstanceRole: this.rolName,
      UpdatedBy: this.usuario,
      UpdatedByFullName: this.userName,
      AgencyCode: this.agenciaCode,
      AgencyName: this.agenciaName,
    };

    this.cancellationApplication(model);
  }

  cancellationApplication(model : any): void {
    this.loading = true;
    this.applicationService.postCancellationApplication(model).subscribe({
      next: async (response: any) => {
        this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, AppConstants.MessageAlert.CANCELLATION_APPLICATION_SUCCES, MessageSeverity.success);
        this.router.navigate(['home']);
      },
      error: (error) => {
        this.alertService.showMessage(AppConstants.TitleAlert.CANCELLATION_APPLICATION, error.error.message, MessageSeverity.error);
        console.error(error);
        this.loading = false;
        this.router.navigate(['home']);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

}
